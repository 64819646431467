import React, { useEffect, useState } from "react";
import AppLayout from "../../utils/Layout/AppLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import API from "../../utils/getDataFromServer";
import utils from "../../utils/utils";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    TextField,
    Tooltip,
} from "@mui/material";

import { faHouseChimney } from "@fortawesome/free-solid-svg-icons";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import { useAuth } from "../../context/AuthContext";

import "./styles.scss";

const ProductDetail = () => {
    const { id } = useParams();
    const { auth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();

    const manUrl = location.pathname.includes("/hombre");
    const womanUrl = location.pathname.includes("/mujer");
    const [isBag, setIsBag] = useState(false);

    const [wspUrl, setWspUrl] = useState("");

    const [product, setProduct] = useState(null);
    const [menuItems, setMenuItems] = useState([]);
    const [menuTitle, setMenuTitle] = useState("");

    const [productColors, setProductColors] = useState([]);
    const [productNumerations, setProductNumerations] = useState([]);

    const [selectedColorNumerationStock, setSelectedColorNumerationStock] =
        useState("");
    const [selectedColor, setSelectedColor] = useState("");
    const [selectedNumeration, setSelectedNumeration] = useState("");

    const getColorsArray = (stocks) => {
        const colors = [];
        stocks.forEach((stock) => {
            const color = stock.color;

            // Verifica si el color ya existe en el nuevo array
            const colorExists = colors.some((c) => c.id === color.id);

            // Si no existe, agrégalo al nuevo array
            if (!colorExists) {
                colors.push(color);
            }
        });
        setProductColors(colors);
    };

    async function fetchData() {
        const prod = await API.getProductDetail(id);
        setProduct(prod);
        let menuIts = [];
        let getItems = null;
        if (manUrl) {
            const manCategories = await API.getManCategories();
            menuIts = [
                {
                    label: "Hombre",
                    icon: faHouseChimney,
                    url: "/hombre",
                },
            ];
            getItems = utils.getMenu(manCategories, auth);
            setWspUrl(`hombre/detalle/${id}`);
        } else if (womanUrl) {
            const subs = await API.getWomanSubcategoriesFromWomanCategory(
                prod.subcategory.mujerId
            );
            if (prod.subcategoryId === 2) {
                setIsBag(true);
            } else {
                setIsBag(false);
            }
            menuIts = [
                {
                    label: "Mujer",
                    icon: faHouseChimney,
                    url: "/mujer",
                },
            ];
            getItems = utils.getMenu(subs, auth);
            setMenuTitle(prod.subcategory.mujer.name);
            setWspUrl(`mujer/detalle/${id}`);
        }
        menuIts = [...menuIts, ...getItems];
        setMenuItems(menuIts);
        getColorsArray(prod.stocks);
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleClickMenuItem = (item) => {
        if (typeof item.url === "string") {
            navigate(item.url);
            return;
        }
        if (manUrl) {
            navigate(`/hombre/${item.url}`);
        }
        if (womanUrl) {
            navigate(`/mujer/categoria/${item.url}`);
        }
    };

    const handleChangeColor = (colorId, stocks) => {
        // const colorId = evt.target.value;
        setSelectedColor(colorId);
        setSelectedNumeration("");
        setSelectedColorNumerationStock("");
        if (!isBag) {
            const numerations = stocks.filter(
                (stock) => stock.color.id === colorId
            );
            setProductNumerations(numerations);
        } else {
            const stock = stocks.find((stock) => stock.colorId === colorId);
            setSelectedColorNumerationStock(stock);
        }
    };

    const handleChangeNumeration = (evt, stocks) => {
        const numerationId = parseInt(evt.target.value);

        setSelectedNumeration(numerationId);
        const colorNumeration = stocks.find(
            (stock) =>
                stock.color.id === selectedColor &&
                stock.numeration.id === numerationId
        );

        setSelectedColorNumerationStock(colorNumeration);
    };

    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    return (
        <AppLayout
            menuItems={menuItems}
            selected={product && product.subcategory.name}
            title={product && product.subcategory.name}
            menuTitle={menuTitle}
            handleClickMenuItem={handleClickMenuItem}
        >
            <Grid
                container
                style={{
                    backgroundColor: "rgba(255, 255, 255, 0.425)",
                    borderRadius: "5px",
                    paddingBottom: "20px",
                }}
            >
                <Grid item xs={12} md={6} padding={2}>
                    <div style={{ marginTop: "15px" }}>
                        <Swiper
                            modules={[Autoplay, Navigation]}
                            loop={true}
                            slidesPerView={1}
                            autoplay={{ delay: 2000, pauseOnMouseEnter: true }}
                            navigation
                        >
                            {product !== null &&
                                product.images.map((image) => (
                                    <SwiperSlide key={image.id}>
                                        <img
                                            key={image.id}
                                            alt={product.name}
                                            src={`${process.env.REACT_APP_AWS_IMAGES_ENDPOINT}/${image.path}`}
                                            style={{
                                                width: "80%",
                                                height: "550px",
                                                objectFit: "cover",
                                                borderRadius: "5px",
                                                cursor: "pointer",
                                            }}
                                        />
                                    </SwiperSlide>
                                ))}
                        </Swiper>
                    </div>
                </Grid>

                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    {product && (
                        <article className="product-detail">
                            <h2>{product.brand.name}</h2>
                            <p>Artículo: {product.article}</p>
                            <Grid
                                container
                                gap={1}
                                sx={{
                                    justifyContent: "center",
                                    textAlign: "left",
                                }}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <FormLabel sx={{ textAlign: "center" }}>
                                        Colores
                                    </FormLabel>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    gap={1}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    {productColors.map((color) => (
                                        <Tooltip
                                            title={capitalize(color.name)}
                                            key={color.id}
                                        >
                                            <img
                                                className={`colors ${
                                                    selectedColor === color.id
                                                        ? "active"
                                                        : ""
                                                }`}
                                                onClick={(evt) =>
                                                    handleChangeColor(
                                                        color.id,
                                                        product.stocks
                                                    )
                                                }
                                                src={`${process.env.REACT_APP_AWS_IMAGES_ENDPOINT}/img/${color.image_path}`}
                                                alt={color.name}
                                            />
                                        </Tooltip>
                                    ))}
                                </Grid>
                                {/* <Grid item xs={10}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="colors"
                                            name="colors"
                                            select
                                            label="Colores"
                                            value={selectedColor}
                                            onChange={(evt) =>
                                                handleChangeColor(
                                                    evt,
                                                    product.stocks
                                                )
                                            }
                                            margin="normal"
                                        >
                                            {productColors.map((color) => (
                                                <MenuItem
                                                    key={color.id}
                                                    value={color.id}
                                                >
                                                    {color.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </FormControl>
                                </Grid> */}
                                {!isBag && (
                                    <Grid item xs={4} md={3}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="numerations"
                                                name="numerations"
                                                select
                                                label="Numeración"
                                                value={selectedNumeration}
                                                onChange={(evt) =>
                                                    handleChangeNumeration(
                                                        evt,
                                                        product.stocks
                                                    )
                                                }
                                                margin="normal"
                                                // disabled={
                                                //     productNumerations.length ===
                                                //     0
                                                // }
                                            >
                                                {productNumerations.length ===
                                                    0 && (
                                                    <MenuItem disabled>
                                                        Seleccione un color
                                                    </MenuItem>
                                                )}
                                                {productNumerations.map(
                                                    (numeration) => (
                                                        <MenuItem
                                                            key={
                                                                numeration
                                                                    .numeration
                                                                    .id
                                                            }
                                                            value={
                                                                numeration
                                                                    .numeration
                                                                    .id
                                                            }
                                                        >
                                                            {
                                                                numeration
                                                                    .numeration
                                                                    .numeration
                                                            }
                                                        </MenuItem>
                                                    )
                                                )}
                                            </TextField>
                                        </FormControl>
                                    </Grid>
                                    // <Grid
                                    //     xs={10}
                                    //     sx={{
                                    //         display: "flex",
                                    //         justifyContent: "center",
                                    //         marginBottom: "2%",
                                    //     }}
                                    // >
                                    //     <FormControl>
                                    //         <FormLabel
                                    //             sx={{ textAlign: "center" }}
                                    //         >
                                    //             Numeración
                                    //         </FormLabel>
                                    //         <RadioGroup
                                    //             row
                                    //             aria-labelledby="demo-row-radio-buttons-group-label"
                                    //             name="row-radio-buttons-group"
                                    //             value={selectedNumeration}
                                    //         >
                                    //             {productNumerations.map(
                                    //                 (numeration) => (
                                    //                     <FormControlLabel
                                    //                         key={numeration.id}
                                    //                         value={
                                    //                             numeration
                                    //                                 .numeration
                                    //                                 .id
                                    //                         }
                                    //                         control={<Radio />}
                                    //                         label={
                                    //                             numeration
                                    //                                 .numeration
                                    //                                 .numeration
                                    //                         }
                                    //                         onChange={(evt) =>
                                    //                             handleChangeNumeration(
                                    //                                 evt,
                                    //                                 product.stocks
                                    //                             )
                                    //                         }
                                    //                     />
                                    //                 )
                                    //             )}
                                    //         </RadioGroup>
                                    //     </FormControl>
                                    // </Grid>
                                )}
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <p
                                    style={{
                                        fontSize: "14px",
                                        width: "80%",
                                    }}
                                >
                                    {product.description}{" "}
                                </p>
                            </Grid>

                            <h3
                                style={{ marginBottom: "3%", fontSize: "22px" }}
                            >
                                Precio: ${product.price.toLocaleString("it-IT")}
                            </h3>

                            {selectedColorNumerationStock.stock > 0 ? (
                                <h3 style={{ marginBottom: "3%" }}>EN STOCK</h3>
                            ) : selectedColorNumerationStock.stock === 0 ? (
                                <h3 style={{ marginBottom: "3%" }}>
                                    SIN STOCK
                                </h3>
                            ) : (
                                ""
                            )}

                            <a
                                id="wsp-btn"
                                href={`https://wa.me/542216395806?text=Hola, quería consultar por este producto: https://www.zapateriaavenida.com/${wspUrl}`}
                                target="_blank"
                                rel="noreferrer"
                                className="nav-link wsp"
                            >
                                <button
                                    style={{
                                        color: "white",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Consultar por este producto
                                </button>
                            </a>
                        </article>
                    )}
                </Grid>
            </Grid>
        </AppLayout>
    );
};

export default ProductDetail;
