import React, { useState, useEffect } from "react";
import { Button, Grid, Stack, Tooltip } from "@mui/material";

import { DataGrid, esES } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import DateInput from "./components/DateInput";

import axios from "axios";
import SaleDialog from "./components/SaleDialog";
import PasswordDialog from "./components/PasswordDialog";
import dayjs from "dayjs";
import CloseSalesDialog from "./components/CloseSalesDialog";
import API from "../../../../../../utils/getDataFromServer";

const History = () => {
    const theme = createTheme(esES);

    const [openDialog, setOpenDialog] = useState(false);
    const [passwordDialog, setPasswordDialog] = useState(true);
    const [closeSalesDialog, setCloseSalesDialog] = useState(false);
    const [saleToShow, setSaleToShow] = useState(null);

    const [password, setPassword] = useState("");

    const [pageSize, setPageSize] = useState(10);

    const [startDate, setStartDate] = useState(dayjs(new Date()));
    const [endDate, setEndDate] = useState(dayjs(new Date()));

    const [historySales, setHistorySales] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);

    const getTotalAmount = (sales) => {
        let total = 0;
        sales.forEach((sale) => {
            total += parseFloat(sale.final_amount);
        });
        setTotalAmount(total);
    };

    useEffect(() => {
        async function getData() {
            let res = [];
            if (startDate !== null && endDate !== null) {
                const dataToSend = {
                    startDate,
                    endDate,
                };
                res = await API.getSalesBetweenDates(dataToSend);
            } else {
                res = await API.getAllSales();
            }
            console.log(res);
            setHistorySales(res);
            getTotalAmount(res);
        }
        if (password === "1234") {
            setPasswordDialog(false);
            getData();
        }
    }, [startDate, endDate, password]);

    const columns = [
        {
            field: "id",
            headerName: "ID",
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "items",
            headerName: "Cantidad de productos",
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            valueGetter: (params) => params.row.items.length,
        },
        {
            field: "createdAt",
            headerName: "Fecha",
            flex: 1,
            sortable: true,
            disableColumnMenu: true,
            // valueGetter: (params) =>
            //     params.row.createdAt.split("-").reverse().join("/"),
            valueGetter: (params) => {
                const dateString = params.row.createdAt;
                const dateParts = dateString.split("-");
                const formattedDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;

                // Crea un objeto Date a partir de la fecha formateada
                const dateObject = new Date(
                    dateParts[0],
                    dateParts[1] - 1,
                    dateParts[2]
                );

                // Devuelve el valor formateado y el objeto Date
                return {
                    formatted: formattedDate,
                    date: dateObject,
                };
            },
            valueFormatter: (params) => params.value.formatted, // Utiliza la fecha formateada para mostrarla en la columna
            sortComparator: (v1, v2, cellParams1, cellParams2) => {
                // Compara los objetos Date para ordenar correctamente las fechas
                return (
                    cellParams1.value.date.getTime() -
                    cellParams2.value.date.getTime()
                );
            },
        },
        {
            field: "amount",
            headerName: "Total",
            flex: 1,
            disableColumnMenu: true,
            sortable: true,
            // valueGetter: (params) =>
            //     "$ " +
            //     params.row.amount
            //         .toString()
            //         .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            valueGetter: (params) => params.row.final_amount, // Devuelve el valor numérico sin formato
            valueFormatter: (params) =>
                `$ ${params.value
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, // Formatea el valor con el símbolo de moneda y el formato deseado
            sortComparator: (v1, v2, cellParams1, cellParams2) =>
                cellParams1.value - cellParams2.value, // Compara los valores numéricos para ordenarlos
        },
        {
            field: "paymentMethod",
            headerName: "Método de pago",
            flex: 1,
            sortable: true,
            disableColumnMenu: true,

            renderCell: (params) => {
                let name = "";
                if (params.row.payment_options.length === 2) {
                    name = `${params.row.payment_options[0].payment_option.name} -
                        ${params.row.payment_options[1]?.payment_option.name}`;
                } else {
                    name =
                        params.row.payment_options[0]?.payment_option.name ||
                        "Cambio";
                }
                return (
                    <Tooltip title={name}>
                        <p
                            style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}
                        >
                            {name}
                        </p>
                    </Tooltip>
                );
            },
        },
        {
            field: "actions",
            headerName: "Acciones",
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <Grid container spacing={2}>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={() => {
                                    setSaleToShow(params.row);
                                    setOpenDialog(true);
                                }}
                            >
                                Ver
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => {
                                    setSaleToShow(params.row);
                                    setOpenDialog(true);
                                }}
                            >
                                Anular
                            </Button>
                        </Grid>
                    </Grid>
                );
            },
        },
    ];

    return (
        <Grid
            container
            spacing={0}
            sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: "4%",
            }}
        >
            <Grid
                item
                xs={12}
                md={4}
                sx={{ display: "flex", justifyContent: "center" }}
            >
                <DateInput
                    label={"Desde"}
                    date={startDate}
                    setDate={setStartDate}
                    maxDate={endDate}
                ></DateInput>
            </Grid>
            <Grid
                item
                xs={12}
                md={4}
                sx={{ display: "flex", justifyContent: "center" }}
            >
                <DateInput
                    label={"Hasta"}
                    date={endDate}
                    setDate={setEndDate}
                    minDate={startDate}
                    disabled={startDate === null}
                ></DateInput>
            </Grid>
            {startDate !== null && endDate !== null ? (
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "2%",
                    }}
                >
                    <Button
                        variant="contained"
                        onClick={() => {
                            setStartDate(null);
                            setEndDate(null);
                        }}
                    >
                        Mostrar todo
                    </Button>
                </Grid>
            ) : (
                ""
            )}

            <Grid item xs={12} mt={5}>
                <ThemeProvider theme={theme}>
                    <DataGrid
                        sortingOrder={["desc", "asc"]}
                        autoHeight
                        rows={historySales}
                        columns={columns}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) =>
                            setPageSize(newPageSize)
                        }
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        disableSelectionOnClick
                        // onCellEditCommit={(evt) => handleEditStockOrPrice(evt)}
                        // onRowClick={(evt) => navigateToDetail(evt.id)}
                        components={{
                            NoRowsOverlay: () => (
                                <Stack
                                    height="100%"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    No hay resultados
                                </Stack>
                            ),
                        }}
                    />
                </ThemeProvider>
                <Grid item xs={12}>
                    <h3>TOTAL: $ {totalAmount.toLocaleString("en-US")}</h3>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => {
                            setCloseSalesDialog(true);
                        }}
                    >
                        Cerrar caja
                    </Button>
                </Grid>
            </Grid>
            <SaleDialog
                open={openDialog}
                setOpen={setOpenDialog}
                sale={saleToShow}
            ></SaleDialog>
            <PasswordDialog
                open={passwordDialog}
                setOpen={setPasswordDialog}
                password={password}
                setPassword={setPassword}
            ></PasswordDialog>
            <CloseSalesDialog
                open={closeSalesDialog}
                setOpen={setCloseSalesDialog}
            ></CloseSalesDialog>
        </Grid>
    );
};

export default History;
