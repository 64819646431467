import React, { useEffect, useState } from "react";
import DialogModal from "../../components/DialogModal/DialogModal";

import {
    Alert,
    Backdrop,
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import axios, { CanceledError } from "axios";

import CircularProgress from "@mui/material/CircularProgress";
import CheckIcon from "@mui/icons-material/Check";

import { useAuth } from "../../../../context/AuthContext";
import API from "../../../../utils/getDataFromServer";

const BrandsForm = () => {
    const { auth } = useAuth();
    const theme = createTheme(esES);

    const [pageSize, setPageSize] = useState(10);

    const [selectedCategory, setSelectedCategory] = useState(1);

    const [editMode, setEditMode] = useState(false);
    const [selectedBrandIDToEdit, setSelectedBrandIDToEdit] = useState("");

    const [name, setName] = useState("");
    const [nameError, setNameError] = useState(false);
    const [brands, setBrands] = useState([]);
    const [alertMessage, setAlertMessage] = useState("");

    // Loader
    const [showLoader, setShowLoader] = useState(false);
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [showAlertOk, setShowAlertOk] = useState(false);
    const [showAlertFail, setShowAlertFail] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    // Para eliminar un producto
    const [open, setOpen] = useState(false);
    const [selectedProductToDelete, setSelectedProductToDelete] = useState("");

    const fetchAllBrands = async () => {
        let brands = await API.getAllBrands();
        setBrands(brands);
    };

    useEffect(() => {
        fetchAllBrands();
    }, []);

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = (id) => {
        setOpen(true);
        setSelectedProductToDelete(id);
    };

    const handleDelete = async (id) => {
        setOpen(false);
        setShowBackdrop(true);
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/brands/delete`,
                { id },
                {
                    headers: {
                        Authorization: auth,
                    },
                }
            );

            if (res.status === 200) {
                setAlertMessage("eliminó");
                setShowAlertOk(true);
                setShowBackdrop(false);
                setTimeout(() => {
                    setShowAlertOk(false);
                    setAlertMessage("");
                }, 3000);

                await fetchAllBrands();
            }
        } catch (error) {
            setErrorMessage("Ocurrió un error, intente nuevamente");
            setShowBackdrop(false);
            setShowAlertFail(true);
            setTimeout(() => {
                setShowAlertFail(false);
                setErrorMessage("");
            }, 3000);
        }
    };

    const handleClickEdit = (brand) => {
        setEditMode(true);
        setSelectedBrandIDToEdit(brand.id);
        setName(brand.name);
    };

    const columns = [
        // { field: "id", headerName: "ID", width: 70, disableColumnMenu: true },
        {
            field: "name",
            headerName: "Nombre",
            flex: 1,
            disableColumnMenu: true,
            valueGetter: (params) =>
                params.row.name.charAt(0).toUpperCase() +
                params.row.name.slice(1).toLowerCase(),
        },

        {
            field: "products",
            headerName: "Productos asociados",
            flex: 1,
            disableColumnMenu: true,
        },
        {
            field: "actions",
            headerName: "Acciones",
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <div>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={() => handleClickEdit(params.row)}
                        >
                            Editar
                        </Button>

                        {/* <Button
                            sx={{ marginLeft: "15px" }}
                            variant="contained"
                            color="error"
                            onClick={() => handleClickOpen(params.id)}
                        >
                            Eliminar
                        </Button> */}
                    </div>
                );
            },
        },
    ];

    const saveData = async () => {
        try {
            if (!name.trim()) {
                setNameError(true);
                // setShowAlertFail(true);
                return;
            }
            let action = "create";
            let data = { name: name, selectedCategory: selectedCategory };
            if (editMode === true) {
                action = "update";
                data = { ...data, id: selectedBrandIDToEdit };
            }

            setShowLoader(true);
            const res = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/brands/${action}`,
                data,
                {
                    headers: {
                        Authorization: localStorage.getItem("jwt-token"),
                    },
                }
            );
            setShowLoader(false);

            if (res.data.error === false) {
                setAlertMessage(editMode === false ? "creó" : "actualizó");
                setShowAlertOk(true);
                if (editMode) {
                    cancelEditionMode();
                }
                setTimeout(function () {
                    setShowAlertOk(false);
                    setAlertMessage("");
                }, 3000);
                await fetchAllBrands();
                handleChangeCategory(selectedCategory);
                setName("");
            } else {
                setShowAlertFail(true);
                setErrorMessage(res.data.message);
                setTimeout(function () {
                    setShowAlertFail(false);
                }, 3000);
            }
        } catch (error) {
            setShowLoader(false);
            setErrorMessage("Ocurrió un error, intente nuevamente!");
            setShowAlertFail(true);
            setTimeout(function () {
                setShowAlertFail(false);
            }, 3000);
        }
    };

    const cancelEditionMode = () => {
        setEditMode(false);
        setSelectedBrandIDToEdit("");
        setName("");
    };

    const handleChangeCategory = async (category) => {
        setSelectedCategory(category);
        cancelEditionMode();
        let brands = [];
        if (category === 1) {
            brands = await API.getAllBrands();
        } else if (category === 2) {
            brands = await API.getCarterasBrands();
        } else if (category === 3) {
            brands = await API.getAccesoriosBrands();
        }

        setBrands(brands);
    };

    return (
        <Grid
            container
            spacing={0}
            sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
            }}
        >
            <Grid item xs={12} className="title">
                <Typography
                    variant="h5"
                    sx={{
                        textAlign: "center",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                    }}
                >
                    {editMode === false ? "Crear" : "Actualizar"} marca
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                // sx={{
                //     width: { xs: "100%", md: "60%" },
                //     maxWidth: "100%",
                // }}
            >
                <FormControl fullWidth margin="normal">
                    <InputLabel id="subcategory">Seleccione</InputLabel>
                    <Select
                        label="Seleccione"
                        value={selectedCategory}
                        onChange={(evt) => {
                            handleChangeCategory(evt.target.value);
                        }}
                        disabled={showLoader}
                    >
                        <MenuItem value={1}>
                            <ListItemText primary={"Zapatos"} />
                        </MenuItem>
                        <MenuItem value={2}>
                            <ListItemText primary={"Carteras"} />
                        </MenuItem>
                        <MenuItem value={3}>
                            <ListItemText primary={"Accesorios"} />
                        </MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <TextField
                        error={nameError}
                        value={name}
                        fullWidth
                        label="Nombre"
                        name="name"
                        id="name"
                        margin="normal"
                        onChange={(e) => {
                            setName(e.target.value);
                            setNameError(false);
                        }}
                        disabled={showLoader}
                    />
                </FormControl>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button
                        // sx={{ marginTop: "25px" }}
                        onClick={saveData}
                        variant="contained"
                        className="pink-btn"
                        disabled={showLoader}
                        type="submit"
                    >
                        Guardar
                    </Button>
                    {editMode === true ? (
                        <Button
                            sx={{ marginLeft: "10px" }}
                            onClick={() => {
                                cancelEditionMode();
                            }}
                            variant="contained"
                            className="pink-btn"
                            disabled={showLoader}
                            color="error"
                        >
                            Cancelar
                        </Button>
                    ) : (
                        ""
                    )}
                </Box>
            </Grid>
            <Grid item xs={12} md={10} xl={7}>
                <div
                    style={{
                        minHeight: 480,
                        height: "100%",
                        width: "100%",
                        marginTop: "25px",
                    }}
                >
                    <ThemeProvider theme={theme}>
                        <DataGrid
                            rows={brands}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: pageSize },
                                },
                            }}
                            pageSizeOptions={[10, 25, 50]}
                            onPageSizeChange={(newPageSize) =>
                                setPageSize(newPageSize)
                            }
                            autoHeight
                            // checkboxSelection
                            disableSelectionOnClick
                            components={{
                                NoRowsOverlay: () => (
                                    <Stack
                                        height="100%"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        No hay resultados
                                    </Stack>
                                ),
                            }}
                        />
                    </ThemeProvider>
                </div>
                <div
                    style={{
                        height: "30px",
                    }}
                ></div>
            </Grid>
            {showAlertOk === true ? (
                <Alert
                    icon={<CheckIcon fontSize="inherit" />}
                    severity="success"
                    sx={{ position: "fixed", top: "50%" }}
                >
                    La marca se {alertMessage} con éxito!
                </Alert>
            ) : null}
            {showAlertFail === true ? (
                <Alert
                    icon={<CheckIcon fontSize="inherit" />}
                    severity="error"
                    sx={{ position: "fixed", top: "50%" }}
                >
                    {errorMessage}
                </Alert>
            ) : null}
            {showLoader === true ? (
                <Box sx={{ position: "fixed", top: "50%" }}>
                    <CircularProgress />
                </Box>
            ) : null}

            <DialogModal
                title="Está seguro que desea eliminar esta marca?"
                description="Si lo elimina se borrarán los stocks de productos asociados a ella"
                open={open}
                handleCloseNo={handleClose}
                handleCloseYes={() => handleDelete(selectedProductToDelete)}
            ></DialogModal>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={showBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Grid>
    );
};

export default BrandsForm;
