import React, { useState } from "react";
import {
    Container,
    FormControl,
    TextField,
    Typography,
    Grid,
    Button,
    Backdrop,
    CircularProgress,
    Alert,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import axios from "axios";
import { useAuth } from "../../context/AuthContext";

const Login = () => {
    const { setAuth } = useAuth();

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);

    const [openLoader, setOpenLoader] = React.useState(false);
    const [showError, setShowError] = useState(false);

    const navigate = useNavigate();

    const checkFormData = (evt) => {
        evt.preventDefault();
        setShowError(false);
        if (!email.trim()) {
            setEmailError(true);
            return;
        } else {
            setEmailError(false);
        }
        if (!password.trim()) {
            setPasswordError(true);
            return;
        } else {
            setPasswordError(false);
        }
        setOpenLoader(true);

        loginUser();
    };

    const loginUser = async () => {
        try {
            const user = {
                email: email,
                password: password,
            };

            const res = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/users/login`,
                user
            );

            if (res.data.auth === false) {
                setShowError(true);
            } else {
                localStorage.setItem("jwt-token", res.data.token);
                setAuth(res.data.token);
                navigate("/admin");
            }
        } catch (error) {
            setShowError(true);
            console.log(error);
        }
        setOpenLoader(false);
    };

    return (
        <Container>
            <div style={{ height: "80px" }}></div>
            <Typography
                variant="h3"
                sx={{ textAlign: "center", marginTop: "4%" }}
            >
                INICIAR SESIÓN{" "}
            </Typography>

            <form onSubmit={checkFormData}>
                <Grid container sx={{ justifyContent: "center" }}>
                    <Grid
                        item
                        xs={12}
                        md={7}
                        sx={{ display: "flex", justifyContent: "center" }}
                    >
                        <FormControl sx={{ width: "55ch" }}>
                            <TextField
                                error={emailError}
                                type="email"
                                label="Email"
                                name="email"
                                id="email"
                                margin="normal"
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    setEmailError(false);
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                        sx={{ display: "flex", justifyContent: "center" }}
                    >
                        <FormControl sx={{ width: "55ch" }}>
                            <TextField
                                error={passwordError}
                                id="outlined-password-input"
                                label="Contraseña"
                                type="password"
                                autoComplete="current-password"
                                margin="normal"
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    setPasswordError(false);
                                }}
                            />
                        </FormControl>
                    </Grid>
                    {showError === true ? (
                        <Grid
                            item
                            xs={12}
                            md={7}
                            sx={{ display: "flex", justifyContent: "center" }}
                        >
                            <Alert severity="error">
                                El email o la contraseñas son incorrectos,
                                intente nuevamente!
                            </Alert>
                        </Grid>
                    ) : null}
                </Grid>

                <Grid
                    item
                    xs={12}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "2%",
                    }}
                >
                    <Button
                        className="pink-btn"
                        type="submit"
                        variant="contained"
                    >
                        Iniciar sesión
                    </Button>
                </Grid>
            </form>

            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={openLoader}
                // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
};

export default Login;
