import React, { useEffect, useRef, useState } from "react";
import DialogModal from "../../components/DialogModal/DialogModal";

import {
    Alert,
    Backdrop,
    Box,
    Button,
    FormControl,
    Grid,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import axios from "axios";

import CircularProgress from "@mui/material/CircularProgress";
import CheckIcon from "@mui/icons-material/Check";

import { useAuth } from "../../../../context/AuthContext";
import API from "../../../../utils/getDataFromServer";

const ColorsForm = () => {
    const { auth } = useAuth();
    const theme = createTheme(esES);
    const inputFileRef = useRef(null);

    const [pageSize, setPageSize] = useState(10);

    const [editMode, setEditMode] = useState(false);
    const [selectedColorToEdit, setSelectedColorToEdit] = useState("");

    const [name, setName] = useState("");
    const [nameError, setNameError] = useState(false);
    const [colors, setColors] = useState([]);
    const [alertMessage, setAlertMessage] = useState("");

    // Loader
    const [showLoader, setShowLoader] = useState(false);
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [showAlertOk, setShowAlertOk] = useState(false);
    const [showAlertFail, setShowAlertFail] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    // Para eliminar un producto
    const [open, setOpen] = useState(false);
    const [selectedProductToDelete, setSelectedProductToDelete] = useState("");

    const [imageIsSelected, setImageIsSelected] = useState(false);
    const [imagePreview, setImagePreview] = useState("");

    const fetchAllColors = async () => {
        let colors = await API.getColors();
        setColors(colors);
    };

    useEffect(() => {
        fetchAllColors();
    }, []);

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = (id) => {
        setOpen(true);
        setSelectedProductToDelete(id);
    };

    const handleDelete = async (id) => {
        setOpen(false);
        setShowBackdrop(true);
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/colors/delete`,
                { id },
                {
                    headers: {
                        Authorization: auth,
                    },
                }
            );

            if (res.status === 200) {
                setAlertMessage("eliminó");
                setShowAlertOk(true);
                setShowBackdrop(false);
                setTimeout(() => {
                    setShowAlertOk(false);
                    setAlertMessage("");
                }, 3000);

                await fetchAllColors();
            }
        } catch (error) {
            setErrorMessage("Ocurrió un error, intente nuevamente");
            setShowBackdrop(false);
            setShowAlertFail(true);
            setTimeout(() => {
                setShowAlertFail(false);
                setErrorMessage("");
            }, 3000);
        }
    };

    const handleClickEdit = (color) => {
        setEditMode(true);
        setSelectedColorToEdit(color);
        setName(
            color.name.charAt(0).toUpperCase() +
                color.name.slice(1).toLowerCase()
        );
    };

    const columns = [
        { field: "id", headerName: "ID", width: 70, disableColumnMenu: true },
        {
            field: "name",
            headerName: "Nombre",
            flex: 1,
            disableColumnMenu: true,
            valueGetter: (params) =>
                params.row.name.charAt(0).toUpperCase() +
                params.row.name.slice(1).toLowerCase(),
        },

        {
            field: "actions",
            headerName: "Acciones",
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <div>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={() => handleClickEdit(params.row)}
                        >
                            Editar
                        </Button>
                        {/* <Button
                            sx={{ marginLeft: "15px" }}
                            variant="contained"
                            color="error"
                            onClick={() => handleClickOpen(params.id)}
                        >
                            Eliminar
                        </Button>`` */}
                    </div>
                );
            },
        },
    ];

    const checkFormData = (evt) => {
        evt.preventDefault();
        if (!name.trim()) {
            setNameError(true);
            // setShowAlertFail(true);
            return;
        }

        saveData(evt.currentTarget);
    };

    const saveData = async (formData) => {
        try {
            // let data = { name };
            const data = new FormData(formData);
            let action = "create";
            if (editMode === true) {
                action = "update";
                // data = { ...data, id: selectedColorToEdit.id };
                data.append("id", selectedColorToEdit.id);
                data.append("oldImage", selectedColorToEdit.image_path);
            }

            setShowLoader(true);
            const res = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/colors/${action}`,
                data,
                {
                    headers: {
                        Authorization: auth,
                    },
                }
            );
            setShowLoader(false);

            if (res.data.error === false) {
                setAlertMessage(editMode === false ? "creó" : "actualizó");
                setShowAlertOk(true);
                if (editMode) {
                    setEditMode(false);
                }
                setTimeout(function () {
                    setShowAlertOk(false);
                    setAlertMessage("");
                }, 3000);
                await fetchAllColors();
                setEditMode(false);
                setSelectedColorToEdit("");
                setName("");
                setImagePreview("");
                resetFileInput();
            } else {
                setShowAlertFail(true);
                setErrorMessage(res.data.message);
                setTimeout(function () {
                    setShowAlertFail(false);
                }, 3000);
            }
        } catch (error) {
            setShowLoader(false);
            setErrorMessage("Ocurrió un error, intente nuevamente!");
            setShowAlertFail(true);
            setTimeout(function () {
                setShowAlertFail(false);
            }, 3000);
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        inputFileRef.current = e.target;

        if (file) {
            // Crear una URL de objeto (blob) para la imagen seleccionada
            const imageUrl = URL.createObjectURL(file);
            setImagePreview(imageUrl);
            setImageIsSelected(true);
        } else {
            setImagePreview(null);
        }
    };

    const resetFileInput = () => {
        if (inputFileRef.current) {
            inputFileRef.current.value = null;
        }
        setImageIsSelected(false);
    };

    return (
        <Grid
            container
            spacing={0}
            sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
            }}
        >
            <Grid item xs={12} className="title">
                <Typography
                    variant="h5"
                    sx={{
                        textAlign: "center",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                    }}
                >
                    {editMode === false ? "Crear" : "Actualizar"} color
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                // sx={{
                //     width: { xs: "100%", md: "60%" },
                //     maxWidth: "100%",
                // }}
            >
                <form onSubmit={checkFormData} encType="multipart/form-data">
                    <FormControl fullWidth>
                        <TextField
                            error={nameError}
                            value={name}
                            fullWidth
                            label="Nombre"
                            name="name"
                            id="name"
                            margin="normal"
                            onChange={(e) => {
                                setName(e.target.value);
                                setNameError(false);
                            }}
                            disabled={showLoader}
                        />
                    </FormControl>
                    <Button
                        variant="contained"
                        component="label"
                        className="pink-btn"
                        disabled={showLoader}
                    >
                        Subir imágen
                        <input
                            type="file"
                            name="imagen"
                            hidden
                            // ref={(ref) => setImageUpload(ref)}
                            ref={inputFileRef}
                            onChange={(evt) => handleImageChange(evt)}
                        />
                    </Button>

                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Button
                            // sx={{ marginTop: "25px" }}

                            variant="contained"
                            className="pink-btn"
                            disabled={showLoader}
                            type="submit"
                        >
                            Guardar
                        </Button>
                        {editMode === true ||
                        imageIsSelected === true ||
                        name !== "" ? (
                            <Button
                                sx={{ marginLeft: "10px" }}
                                onClick={() => {
                                    setEditMode(false);
                                    setSelectedColorToEdit("");
                                    setName("");
                                    setImagePreview("");
                                    resetFileInput();
                                }}
                                variant="contained"
                                className="pink-btn"
                                disabled={showLoader}
                                color="error"
                            >
                                Cancelar
                            </Button>
                        ) : (
                            ""
                        )}
                    </Box>
                </form>
                <Grid container sx={{ display: "flex", flexDirection: "row" }}>
                    {selectedColorToEdit !== "" ? (
                        <Grid item xs={12} sm={4} md={4} padding={"3px"}>
                            <h4>IMAGEN ACTUAL:</h4>
                            <img
                                src={`${process.env.REACT_APP_AWS_IMAGES_ENDPOINT}/img/${selectedColorToEdit.image_path}`}
                                alt="Vista previa de la imagen"
                                style={{
                                    width: "100%",
                                }}
                            />
                        </Grid>
                    ) : (
                        ""
                    )}
                    {imagePreview !== "" ? (
                        <Grid item xs={12} sm={4} md={4} padding={"3px"}>
                            <h4>IMAGEN NUEVA:</h4>
                            <img
                                src={imagePreview}
                                alt="Vista previa de la imagen"
                                style={{
                                    width: "100%",
                                }}
                            />
                        </Grid>
                    ) : (
                        ""
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12} md={10} xl={7}>
                <div
                    style={{
                        minHeight: 480,
                        height: "100%",
                        width: "100%",
                        marginTop: "25px",
                    }}
                >
                    <ThemeProvider theme={theme}>
                        <DataGrid
                            rows={colors}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: pageSize },
                                },
                            }}
                            pageSizeOptions={[10, 25, 50]}
                            onPageSizeChange={(newPageSize) =>
                                setPageSize(newPageSize)
                            }
                            autoHeight
                            // checkboxSelection
                            disableSelectionOnClick
                            components={{
                                NoRowsOverlay: () => (
                                    <Stack
                                        height="100%"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        No hay resultados
                                    </Stack>
                                ),
                            }}
                        />
                    </ThemeProvider>
                </div>
                <div
                    style={{
                        height: "30px",
                    }}
                ></div>
            </Grid>
            {showAlertOk === true ? (
                <Alert
                    icon={<CheckIcon fontSize="inherit" />}
                    severity="success"
                    sx={{ position: "fixed", top: "50%" }}
                >
                    El color se {alertMessage} con éxito!
                </Alert>
            ) : null}
            {showAlertFail === true ? (
                <Alert
                    icon={<CheckIcon fontSize="inherit" />}
                    severity="error"
                    sx={{ position: "fixed", top: "50%" }}
                >
                    {errorMessage}
                </Alert>
            ) : null}
            {showLoader === true ? (
                <Box sx={{ position: "fixed", top: "50%" }}>
                    <CircularProgress />
                </Box>
            ) : null}

            <DialogModal
                title="Está seguro que desea eliminar este color?"
                // description="Si lo elimina se borrarán los stocks de productos asociados a el"
                open={open}
                handleCloseNo={handleClose}
                handleCloseYes={() => handleDelete(selectedProductToDelete)}
            ></DialogModal>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={showBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Grid>
    );
};

export default ColorsForm;
