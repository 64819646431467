import React, { useState } from "react";

import {
    DataGrid,
    esES,
    GridToolbar,
    GridToolbarContainer,
    GridToolbarExport,
} from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Stack } from "@mui/material";

const theme = createTheme(esES);

const GenericTable = ({ rows, columns }) => {
    const [pageSize, setPageSize] = useState(10);

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }
    return (
        <ThemeProvider theme={theme}>
            <DataGrid
                sortingOrder={["desc", "asc"]}
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: pageSize },
                    },
                }}
                pageSizeOptions={[10, 25, 50]}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                disableSelectionOnClick
                // onCellEditCommit={(evt) => handleEditStockOrPrice(evt)}
                // onRowClick={(evt) => navigateToDetail(evt.id)}
                components={{
                    NoRowsOverlay: () => (
                        <Stack
                            height="100%"
                            alignItems="center"
                            justifyContent="center"
                        >
                            No hay resultados
                        </Stack>
                    ),
                    Toolbar: CustomToolbar,
                }}
            />
        </ThemeProvider>
    );
};

export default GenericTable;
