import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import GenericTable from "../../../../../components/GenericTable/GenericTable";

import "./style.scss";
import { Tooltip } from "@mui/material";
export default function SaleDialog({ open, setOpen, sale }) {
    const handleClose = () => {
        setOpen(false);
    };

    const calculateDiscount = (data) => {
        const totalWithoutDiscount = data.price * data.quantity;
        if (data.discount_percentage !== 0) {
            const discount =
                (data.discount_percentage * totalWithoutDiscount) / 100;

            return totalWithoutDiscount - discount.toFixed(2);
        }
        return totalWithoutDiscount;
    };

    const columns = [
        {
            field: "article",
            headerName: "Artículo",
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            // valueGetter: (params) => params.row.product.name,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.row.product.article}>
                        <p
                            style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}
                        >
                            {params.row.product.article}
                        </p>
                    </Tooltip>
                );
            },
        },
        {
            field: "color",
            headerName: "Color",
            flex: 1,
            sortable: true,
            disableColumnMenu: true,
            // valueGetter: (params) =>
            //     params.row.colorNumerationStock.color.name
            //         .charAt(0)
            //         .toUpperCase(),
            renderCell: (params) => {
                const color = params.row.colorNumerationStock.color.name;
                return color.charAt(0).toUpperCase() + color.slice(1);
            },
        },
        {
            field: "numeration",
            headerName: "Numeración",
            flex: 1,
            sortable: true,
            disableColumnMenu: true,
            valueGetter: (params) =>
                params.row.colorNumerationStock.numeration?.numeration,
        },
        {
            field: "quantity",
            headerName: "Cantidad",
            flex: 1,
            sortable: true,
            disableColumnMenu: true,
            valueGetter: (params) => params.row.quantity,
        },
        {
            field: "price",
            headerName: "Precio unitario",
            flex: 1,
            disableColumnMenu: true,
            // valueGetter: (params) =>
            //     "$ " +
            //     params.row.product.price
            //         .toString()
            //         .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            valueGetter: (params) => params.row.price, // Devuelve el valor numérico sin formato
            valueFormatter: (params) =>
                `$ ${params.value
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, // Formatea el valor con el símbolo de moneda y el formato deseado
            sortComparator: (v1, v2, cellParams1, cellParams2) =>
                cellParams1.value - cellParams2.value, // Compara los valores numéricos para ordenarlos
        },
        {
            field: "amount",
            headerName: "Total parcial",
            flex: 1,
            disableColumnMenu: true,
            // valueGetter: (params) => "$" + params.row.amount,
            valueGetter: (params) => params.row.price * params.row.quantity, // Devuelve el valor numérico sin formato
            valueFormatter: (params) =>
                `$ ${params.value
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, // Formatea el valor con el símbolo de moneda y el formato deseado
            sortComparator: (v1, v2, cellParams1, cellParams2) =>
                cellParams1.value - cellParams2.value, // Compara los valores numéricos para ordenarlos
        },
        {
            field: "discount_percentage",
            headerName: "Descuento (%)",
            flex: 1,
            disableColumnMenu: true,
            sortable: false,
        },
        {
            field: "totalWithDiscount",
            headerName: "Total final",
            flex: 1,
            disableColumnMenu: true,
            sortable: false,
            valueGetter: (params) => calculateDiscount(params.row),
            valueFormatter: (params) =>
                `$ ${params.value
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, // Formatea el valor con el símbolo de moneda y el formato deseado
            sortComparator: (v1, v2, cellParams1, cellParams2) =>
                cellParams1.value - cellParams2.value, // Compara los valores numéricos para ordenarlos
        },
    ];

    const getFormatedDate = () => {
        let date = sale.createdAt;
        date = date.split("-").reverse().join("/");

        return date;
    };

    return (
        <div>
            {sale !== null ? (
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle id="alert-dialog-title">
                        Fecha: {getFormatedDate()}
                    </DialogTitle>
                    <DialogContent>
                        <GenericTable
                            columns={columns}
                            rows={sale.items}
                        ></GenericTable>

                        <p style={{ textAlign: "right" }}>
                            Total parcial:
                            <strong>
                                {" $"}
                                {sale.amount
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </strong>
                        </p>
                        <p style={{ textAlign: "right" }}>
                            Total final: (Descuento:{" "}
                            <strong>{sale.discount_percentage}</strong>
                            %)
                            <strong>
                                {" $"}

                                {sale.final_amount
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </strong>
                        </p>

                        <p>
                            <strong>Información de comprador:</strong>
                        </p>
                        <ul>
                            <li>
                                <strong>Nombre:</strong> {sale.buyer.name}{" "}
                                {sale.buyer.surname}{" "}
                            </li>
                            <li>
                                <strong>DNI:</strong> {sale.buyer.dni}
                            </li>
                            <li>
                                <strong>Teléfono:</strong> {sale.buyer.phone}
                            </li>
                            <li>
                                <strong>Email:</strong> {sale.buyer.email}
                            </li>
                        </ul>
                    </DialogContent>
                    <DialogActions>
                        {/* <Button onClick={handleClose}>Imprimir ticket</Button> */}
                        <Button onClick={handleClose} autoFocus>
                            Cerrar
                        </Button>
                    </DialogActions>
                </Dialog>
            ) : (
                ""
            )}
        </div>
    );
}
