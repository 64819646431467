import React, { useEffect, useState } from "react";

import {
    Autocomplete,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
// import { getAllProducts } from "../../../../../utils/getDataFromServer";
import API from "../../../../../../../utils/getDataFromServer";

const FormInputs = ({
    barcode,
    handleChangeBarcode,
    setBarcode,
    handleAddProductByArticle,
    buyerInput = false,
    productSales = [],
    selectedBuyer,
    handleChangeBuyer,
}) => {
    const [products, setProducts] = useState([]);
    const [productSelected, setProductSelected] = useState(null);

    useEffect(() => {
        async function getProducts() {
            const prods = await API.getAllProducts();
            // const orderedProducts = prods.sort((a, b) => {
            //     if (a.name.toLowerCase() > b.name.toLowerCase()) {
            //         return 1;
            //     } else {
            //         return -1;
            //     }
            // });
            setProducts(prods);
        }

        getProducts();
    }, []);

    return (
        <>
            <Grid
                container
                spacing={2}
                // justifyContent={"center"}
            >
                {/* <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                        <TextField
                            value={barcode}
                            fullWidth
                            label="Código de barras"
                            name="barcode"
                            id="barcode"
                            margin="normal"
                            onChange={handleChangeBarcode}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <IconButton
                                        sx={{
                                            visibility: barcode
                                                ? "visible"
                                                : "hidden",
                                        }}
                                        onClick={() => {
                                            setBarcode("");
                                            // setProductsToShow(products);
                                        }}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                ),
                            }}
                            // disabled={showLoader}
                        />
                    </FormControl>
                </Grid> */}
                <Grid item xs={12} md={4} sx={{}}>
                    {/* <FormControl fullWidth>
                        <TextField
                            id="product"
                            name="product"
                            select
                            label="Productos"
                            value={productSelected}
                            // defaultValue={""}
                            onChange={(evt) => {
                                handleAddProductByArticle(evt.target.value);
                                setProductSelected("");
                            }}
                            margin="normal"
                        >
                            {products.map((product) => (
                                <MenuItem key={product.id} value={product}>
                                    {product.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl> */}
                    <Autocomplete
                        sx={{ marginTop: "16px" }}
                        id="autocomplete"
                        options={products}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} key={option.id}>
                                    {option.article}
                                </li>
                            );
                        }}
                        getOptionLabel={(option) => option.article}
                        renderInput={(params) => (
                            <TextField {...params} label="Artículo" />
                        )}
                        value={productSelected}
                        onChange={(evt, newValue) => {
                            if (newValue !== null) {
                                handleAddProductByArticle(newValue);
                                setProductSelected(null);
                                console.log(newValue);
                            }
                        }}
                    />
                </Grid>
                {buyerInput && (
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="subcategory">Comprador</InputLabel>
                            <Select
                                label="Comprador"
                                name="buyer"
                                value={selectedBuyer}
                                onChange={(evt) =>
                                    handleChangeBuyer(evt.target.value)
                                }
                            >
                                {productSales.length === 0 ? (
                                    <MenuItem disabled>
                                        No hay compradores para mostrar
                                    </MenuItem>
                                ) : (
                                    productSales.map((sale) => (
                                        <MenuItem key={sale.id} value={sale}>
                                            {sale.buyer.name}{" "}
                                            {sale.buyer.surname} -{" "}
                                            {sale.createdAt
                                                .split("-")
                                                .reverse()
                                                .join("/")}
                                        </MenuItem>
                                    ))
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                )}
            </Grid>
            <Grid item xs={12}></Grid>
            {/* <Grid container spacing={2} justifyContent={"center"}>
                <Grid item xs={12} md={2}>
                    <FormControl fullWidth>
                        <TextField
                            value={ghostProduct.name}
                            fullWidth
                            label="Nombre producto"
                            name="name"
                            id="name"
                            margin="normal"
                            onChange={(evt) => {
                                setGhostProduct({
                                    ...ghostProduct,
                                    name: evt.target.value,
                                });
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={2}>
                    <FormControl fullWidth>
                        <TextField
                            value={ghostProduct.price}
                            fullWidth
                            label="Precio"
                            name="price"
                            id="price"
                            margin="normal"
                            onChange={(evt) => {
                                setGhostProduct({
                                    ...ghostProduct,
                                    price: evt.target.value.replace(/,/g, "."),
                                });
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={2}>
                    <FormControl fullWidth>
                        <TextField
                            value={ghostProduct.quantity}
                            fullWidth
                            label="Cantidad"
                            type="number"
                            name="quantity"
                            id="quantity"
                            margin="normal"
                            onChange={(evt) => {
                                setGhostProduct({
                                    ...ghostProduct,
                                    quantity: evt.target.value,
                                });
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={2}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}
                >
                    <Button variant="contained" onClick={handleAddGhostProduct}>
                        Agregar nuevo
                    </Button>
                </Grid>
            </Grid> */}
        </>
    );
};

export default FormInputs;
