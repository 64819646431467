import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";

import {
    faFacebook,
    faInstagram,
    faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

import { Grid } from "@mui/material";

import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NavLinkButton from "../NavLinkButton/NavLinkButton";
// import "../../index.scss";
import "./styles.scss";

const drawerWidth = 350;

function SideBar({
    window,
    menuItems,
    menuTitle = "",
    selectedSection,
    handleClickItem,
    isHome = false,
    children,
}) {
    // const { window, children } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const navigate = useNavigate();
    const location = useLocation();

    const handleNavigate = (url) => {
        navigate(url);
        if (isHome) {
            const section = url.replace("#", "");
            const element = document.getElementById(section);
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    };

    const drawer = (
        <div id="sidebar">
            <Grid item xs={12}>
                {/* <Toolbar /> */}
                <Grid
                    container
                    spacing={3}
                    sx={{ padding: "10% 5%", cursor: "pointer" }}
                    onClick={() => navigate("/")}
                >
                    <Grid item xs={3}>
                        <img
                            src={`${process.env.REACT_APP_AWS_IMAGES_ENDPOINT}/avatar.jpg`}
                            alt=""
                        />
                    </Grid>
                    <Grid item xs={9} className="sideBar-title">
                        <h3>Zapatería Avenida</h3>
                        <p>Calzados para pie delicado</p>
                    </Grid>
                </Grid>

                {/* <Divider /> */}

                <List>
                    <h1
                        style={{
                            padding: "0",
                            textAlign: "center",
                            fontSize: "1.5em",
                        }}
                    >
                        {menuTitle}
                    </h1>
                    {menuItems.map((item, index) => (
                        <ListItem
                            key={item.label}
                            onClick={() => {
                                handleClickItem(item);
                                handleDrawerToggle();
                            }}
                        >
                            <ListItemButton
                                selected={
                                    isHome === false
                                        ? item.label === selectedSection
                                        : item.url === location.hash
                                }
                            >
                                <ListItemIcon sx={{ fontSize: "20px" }}>
                                    <FontAwesomeIcon icon={item.icon} />
                                </ListItemIcon>
                                <ListItemText
                                    sx={{
                                        textAlign: "right",
                                        fontFamily: "Font Awesome 5 Free",
                                    }}
                                    primary={item.label}
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Grid>
            <Grid item xs={12}>
                <div className="bottom">
                    <ul className="icons">
                        <li>
                            <a
                                href="https://www.facebook.com/Zapateria-Avenida-472304296169928"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="fb"
                            >
                                <FontAwesomeIcon icon={faFacebook} />
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.instagram.com/zapateriaavenida/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="ig"
                            >
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://web.whatsapp.com/send?phone=542216395806"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="wsp"
                            >
                                <FontAwesomeIcon icon={faWhatsapp} />
                            </a>
                        </li>
                    </ul>
                </div>
            </Grid>
        </div>
    );

    const container =
        window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <IconButton
                color="red"
                aria-label="open drawer"
                // edge="start"
                onClick={handleDrawerToggle}
                sx={{
                    display: { sm: "none" },
                    position: "fixed",
                    zIndex: "9",
                    backgroundColor: "rgba(128, 136, 144, 0.5)",
                    cursor: "pointer",
                    width: "50px",
                    borderRadius: "5px",
                    top: "10px",
                    left: "10px",
                    height: "35px",
                }}
            >
                <MenuIcon />
            </IconButton>
            {/* <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <Typography variant="h6" noWrap component="div">
                        Responsive drawer
                    </Typography>
                </Toolbar>
            </AppBar> */}
            <Box
                component="nav"
                sx={{
                    width: { sm: drawerWidth },
                    flexShrink: { sm: 0 },
                }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                        disableScrollLock: true,
                    }}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: "none", sm: "block" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    // p: 3,
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                }}
            >
                {/* <Toolbar /> */}
                {children}
            </Box>
        </Box>
    );
}

SideBar.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default SideBar;
