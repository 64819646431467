import { Box, Button, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import { QRCodeCanvas } from "qrcode.react";

import GenericDialog from "../../../../components/GenericDialog/GenericDialog";

const QRForm = ({ product, open, setOpen }) => {
    const downloadQR = () => {
        const canvas = document.getElementById("qr");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");

        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `${product.article}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return (
        <GenericDialog open={open} setOpen={setOpen}>
            <DialogTitle>
                {product.subcategory?.name} - Articulo: {product.article}
            </DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        width: "50%",
                        margin: "auto",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}
                >
                    <QRCodeCanvas
                        size={256}
                        id="qr"
                        style={{
                            height: "auto",
                            maxWidth: "100%",
                            width: "100%",
                        }}
                        // value={`${process.env.REACT_APP_FRONTEND_URL}/productos/detalle/${product.id}`}
                        value={`${process.env.REACT_APP_FRONTEND_URL}/productos/detalle/${product.id}`}
                        viewBox={`0 0 256 256`}
                    />
                    <Box sx={{ margin: "auto" }}>
                        <Button
                            sx={{ marginTop: "25px" }}
                            onClick={downloadQR}
                            variant="contained"
                            className="pink-btn"
                            type="submit"
                        >
                            Descargar
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </GenericDialog>
    );
};

export default QRForm;
