import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Typography,
    Button,
    Backdrop,
    CircularProgress,
    Alert,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import API from "../../../../utils/getDataFromServer";

const PortadasForm = () => {
    const inputFileRef = useRef(null);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [imageIsSelected, setImageIsSelected] = useState(false);
    const [imagePreview, setImagePreview] = useState("");

    const [showLoader, setShowLoader] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [severity, setSeverity] = useState("");

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        inputFileRef.current = e.target;

        if (file) {
            // Crear una URL de objeto (blob) para la imagen seleccionada
            const imageUrl = URL.createObjectURL(file);
            setImagePreview(imageUrl);
            setImageIsSelected(true);
        } else {
            setImagePreview(null);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            let categs = await API.getCategoriesOnly();
            setCategories(categs);
            const categoriesToShow = [];
            categs.forEach((cat) => {
                const data = {
                    label: cat.name,
                    image: cat.image,
                    id: cat.id,
                };
                categoriesToShow.push(data);
            });
            setCategories(categoriesToShow);
        };

        fetchData();
    }, []);

    const handleChangeCategory = (evt) => {
        setSelectedCategory(evt.target.value);
    };
    const resetFileInput = () => {
        if (inputFileRef.current) {
            inputFileRef.current.value = null;
        }
        setImageIsSelected(false);
        setSelectedCategory("");
        setImagePreview("");
    };

    const saveData = async (evt) => {
        evt.preventDefault();
        setShowLoader(true);
        const formData = evt.currentTarget;
        const form = new FormData(formData);
        form.append("categoryId", selectedCategory.id);
        form.append("previousImage", selectedCategory.image);
        try {
            const res = await API.updateCategoryImage(form);
            setShowLoader(false);
            if (res.status === 200) {
                setShowAlert(true);
                setAlertMessage(res.message);
                setSeverity("success");
                resetFileInput();
                setTimeout(() => {
                    setShowAlert(false);
                    setAlertMessage("");
                }, 2000);
            } else {
                setShowAlert(true);
                setAlertMessage("Ocurrió un error, intente nuevamente!");
                setSeverity("error");
                setTimeout(() => {
                    setShowAlert(false);
                    setAlertMessage("");
                }, 2000);
            }
        } catch (error) {
            setShowAlert(true);
            setAlertMessage("Ocurrió un error, intente nuevamente!");
            setTimeout(() => {
                setShowAlert(false);
                setAlertMessage("");
            }, 2000);
        }
    };
    return (
        <Grid
            container
            spacing={0}
            sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
            }}
        >
            <Grid item xs={12} md={6}>
                <Box className="title">
                    <Typography
                        variant="h5"
                        sx={{
                            textAlign: "center",
                            textTransform: "uppercase",
                            fontWeight: "bold",
                        }}
                    >
                        Editar portadas
                    </Typography>
                </Box>

                <FormControl fullWidth margin="normal">
                    <InputLabel id="subcategory">Seleccione un sexo</InputLabel>
                    <Select
                        labelId="sex"
                        name="sex"
                        label="Seleccione un sexo"
                        value={selectedCategory}
                        onChange={(evt) => {
                            handleChangeCategory(evt);
                        }}
                    >
                        {categories.map((category) => (
                            <MenuItem
                                key={category.id}
                                value={category}
                                name="sex"
                            >
                                <ListItemText primary={category.label} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            {selectedCategory && (
                <Grid item xs={12}>
                    {selectedCategory && (
                        <Grid
                            item
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "3%",
                                gap: 2,
                            }}
                        >
                            <img
                                src={`${process.env.REACT_APP_AWS_IMAGES_ENDPOINT}/img/${selectedCategory.image}`}
                                alt="Hombre"
                                style={{ width: "30%" }}
                            />
                            {imagePreview !== "" ? (
                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    md={4}
                                    padding={"3px"}
                                    mt={2}
                                >
                                    <h4>NUEVA PORTADA:</h4>
                                    <img
                                        src={imagePreview}
                                        alt="Vista previa de la imagen"
                                        style={{
                                            width: "100%",
                                        }}
                                    />
                                </Grid>
                            ) : (
                                ""
                            )}
                        </Grid>
                    )}
                    <form onSubmit={saveData} encType="multipart/form-data">
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"center"}
                            mt={3}
                        >
                            <Button
                                variant="contained"
                                component="label"
                                className="pink-btn"
                                // disabled={showLoader}
                            >
                                Subir portada
                                <input
                                    type="file"
                                    name="portada"
                                    hidden
                                    // ref={(ref) => setImageUpload(ref)}
                                    ref={inputFileRef}
                                    onChange={(evt) => handleImageChange(evt)}
                                ></input>
                            </Button>
                        </Grid>
                        {imageIsSelected && (
                            <Grid
                                item
                                xs={12}
                                mt={2}
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <Button variant="contained" type="submit">
                                    Guardar
                                </Button>
                            </Grid>
                        )}
                    </form>
                </Grid>
            )}
            {showAlert && (
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "3%",
                    }}
                >
                    <Alert severity={severity} sx={{ width: "50%" }}>
                        {alertMessage}
                    </Alert>
                </Grid>
            )}

            {showLoader && (
                <Backdrop
                    sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={showLoader}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
        </Grid>
    );
};

export default PortadasForm;
