import AppsIcon from "@mui/icons-material/Apps";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import AddBoxIcon from "@mui/icons-material/AddBox";
import HorizontalSplitIcon from "@mui/icons-material/HorizontalSplit";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import PaletteIcon from "@mui/icons-material/Palette";
import ListIcon from "@mui/icons-material/List";
import LogoutIcon from "@mui/icons-material/Logout";
import ProductsList from "../ProductsList/ProductsList";
import CategoriesForm from "../CategoriesForm/CategoriesForm";
import ProductForm from "../ProductForm/ProductForm";
import ColorsForm from "../ColorsForm/ColorsForm";
import BarcodeForm from "../BarcodeForm/BarcodeForm";
import QRForm from "../QRForm/QRForm";
import BrandsForm from "../BrandsForm/BrandsForm";
import Sales from "../Sales/Sales";
import ImageIcon from "@mui/icons-material/Image";
import PortadasForm from "../PortadasForm/PortadasForm";

const getMenuItems = (handleChangeToEdit) => {
    return [
        {
            id: 1,
            name: "Listado",
            icon: <ListIcon></ListIcon>,
            component: (
                <ProductsList
                    handleChangeToEdit={handleChangeToEdit}
                    title={"Hola"}
                ></ProductsList>
            ),
        },
        {
            id: 2,
            name: "Nuevo",
            icon: <AddBoxIcon></AddBoxIcon>,
            component: <ProductForm></ProductForm>,
        },
        {
            id: 3,
            name: "Portadas",
            icon: <ImageIcon></ImageIcon>,
            component: <PortadasForm></PortadasForm>,
        },
        {
            id: 4,
            name: "Código de barras",
            icon: <HorizontalSplitIcon></HorizontalSplitIcon>,
            component: <BarcodeForm></BarcodeForm>,
        },
        {
            id: 5,
            name: "Categorías",
            icon: <AppsIcon></AppsIcon>,
            component: <CategoriesForm></CategoriesForm>,
        },
        {
            id: 6,
            name: "Colores",
            icon: <PaletteIcon></PaletteIcon>,
            component: <ColorsForm></ColorsForm>,
        },
        {
            id: 7,
            name: "Marcas",
            icon: <MenuBookIcon></MenuBookIcon>,
            component: <BrandsForm></BrandsForm>,
        },
        {
            id: 8,
            name: "Ventas",
            icon: <AddShoppingCartIcon></AddShoppingCartIcon>,
            component: <Sales></Sales>,
        },
        {
            id: 9,
            name: "Cerrar sesión",
            icon: <LogoutIcon></LogoutIcon>,
            component: <ProductsList></ProductsList>,
        },
    ];
};

const utils = {
    getMenuItems,
};

export default utils;
