import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import "./styles.scss";
import { Tooltip } from "@mui/material";

const ProductCard = ({
    handleClickCard,
    url,
    src,
    alt,
    title,
    price,
    stocks = [],
}) => {
    const [colors, setColors] = useState([]);
    const [isOutOfStock, setIsOutOfStock] = useState(false);

    const checkIfNoStock = () => {
        setIsOutOfStock(stocks.every((stock) => stock.stock === 0));
    };

    useEffect(() => {
        // Creamos un conjunto (Set) para almacenar nombres únicos
        const nombresUnicos = new Set();
        // Filtramos el array para eliminar los duplicados
        const arraySinDuplicados = stocks.filter((elemento) => {
            // Verificamos si el nombre ya está en el conjunto
            if (nombresUnicos.has(elemento.color.name)) {
                // Si ya está, retornamos false para eliminar este elemento del array
                return false;
            } else {
                // Si no está, lo añadimos al conjunto y retornamos true para mantenerlo en el array
                nombresUnicos.add(elemento.color.name);
                return true;
            }
        });
        setColors(arraySinDuplicados);
        checkIfNoStock();
    }, []);

    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    return (
        <article
            className="item hvr-float-shadow"
            style={{ cursor: "pointer", width: "100%" }}
        >
            <div onClick={() => handleClickCard(url)} className="image fit">
                <img
                    src={`${process.env.REACT_APP_AWS_IMAGES_ENDPOINT}/${src}`}
                    alt={alt}
                />
            </div>
            <header>
                <h3>{title}</h3>
                {!isOutOfStock ? (
                    <>
                        <p>${price.toLocaleString("it-IT")}</p>
                        <Grid
                            container
                            spacing={1}
                            sx={{ display: "flex", justifyContent: "center" }}
                        >
                            {colors.length > 0 &&
                                colors.map((color) => (
                                    <Grid item key={color.id}>
                                        <Tooltip
                                            title={capitalize(color.color.name)}
                                        >
                                            <img
                                                className="colorsImages"
                                                src={`${process.env.REACT_APP_AWS_IMAGES_ENDPOINT}/img/${color.color.image_path}`}
                                                alt={color.color.name}
                                            />
                                        </Tooltip>
                                    </Grid>
                                ))}
                        </Grid>
                    </>
                ) : (
                    <Grid mt={5}>
                        <h3>Sin Stock</h3>
                    </Grid>
                )}
            </header>
        </article>
    );
};

export default ProductCard;
