import { React, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";

import axios from "axios";

import "./style.scss";
import {
    Backdrop,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
} from "@mui/material";
import GenericTable from "../../../../../components/GenericTable/GenericTable";
import monthsList from "../utils/utils";
import API from "../../../../../../../utils/getDataFromServer";
export default function CloseSalesDialog({ open, setOpen }) {
    const [selectedOption, setSelectedOption] = useState(1);
    const [startDate, setStartDate] = useState(new dayjs());
    const [endDate, setEndDate] = useState(new dayjs());
    const [tableRows, setTableRows] = useState([]);
    const [monthSelected, setMonthSelected] = useState("");
    const [openLoader, setOpenLoader] = useState(false);

    useEffect(() => {
        async function getData() {
            const dataToSend = {
                startDate,
                endDate,
            };
            const res = await API.getPaymentOptionsSales(dataToSend);

            setTableRows(res);
            setOpenLoader(false);
        }

        getData();
    }, [startDate, endDate]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangeOption = (opt) => {
        setSelectedOption(opt.id);
        setTableRows([]);
        if (opt.id === 1) {
            setOpenLoader(true);
            setStartDate(new dayjs());
            setEndDate(new dayjs());
            setMonthSelected("");
        }
    };

    const handleChangeMonth = (evt) => {
        setOpenLoader(true);
        setMonthSelected(evt.target.value);

        const anioActual = dayjs().year();
        const fechaInicial = dayjs()
            .set("year", anioActual)
            .set("month", evt.target.value - 1)
            .startOf("month");
        const fechaFinal = dayjs()
            .set("year", anioActual)
            .set("month", evt.target.value - 1)
            .endOf("month");

        setStartDate(fechaInicial);
        setEndDate(fechaFinal);
    };

    const options = [
        {
            id: 1,
            label: `Fecha actual: ${new dayjs().format("DD/MM/YYYY")}`,
        },
        {
            id: 2,
            label: "Mes",
        },
        // {
        //     id: 3,
        //     label: "Rango de fechas",
        // },
    ];

    const columns = [
        {
            field: "name",
            headerName: "Tipo de pago",
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "sales",
            headerName: "Cantidad de ventas",
            flex: 1,
            sortable: true,
            disableColumnMenu: true,
            valueGetter: (params) => params.row.sale_payment_option.length,
        },
        {
            field: "amount",
            headerName: "Total",
            flex: 1,
            disableColumnMenu: true,
            valueGetter: (params) => params.row.amount, // Devuelve el valor numérico sin formato
            valueFormatter: (params) =>
                `$ ${params.value
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, // Formatea el valor con el símbolo de moneda y el formato deseado
            sortComparator: (v1, v2, cellParams1, cellParams2) =>
                cellParams1.value - cellParams2.value, // Compara los valores numéricos para ordenarlos
        },
    ];

    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle id="alert-dialog-title">
                    CIERRE DE CAJA
                </DialogTitle>
                <DialogContent>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                    >
                        {options.map((opt) => (
                            <FormControlLabel
                                checked={
                                    selectedOption === opt.id ? true : false
                                }
                                key={opt.id}
                                onChange={() => {
                                    handleChangeOption(opt);
                                }}
                                value={opt.id}
                                control={<Radio />}
                                label={opt.label}
                            />
                        ))}
                    </RadioGroup>
                    <Grid container>
                        <Grid item xs={4} sx={{ margin: "15px 0px 20px" }}>
                            {selectedOption === 2 && (
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                        Seleccione un mes
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={monthSelected}
                                        label="Seleccione un mes"
                                        onChange={handleChangeMonth}
                                        // input={<OutlinedInput label="Meses" />}
                                    >
                                        {monthsList.map((month) => (
                                            <MenuItem
                                                key={month.id}
                                                value={month.id}
                                            >
                                                <ListItemText
                                                    primary={month.name}
                                                />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </Grid>
                    </Grid>
                    <GenericTable
                        columns={columns}
                        rows={tableRows}
                    ></GenericTable>
                    <h3>
                        Total:{" $"}
                        {tableRows
                            .reduce((a, b) => a + parseFloat(b.amount), 0)
                            .toLocaleString()}
                    </h3>
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={handleClose}>Imprimir ticket</Button> */}
                    <Button onClick={handleClose} autoFocus>
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: 9999,
                }}
                open={openLoader}
                // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}
