import { Grid } from "@mui/material";
import React from "react";
import ProductImage from "../../pages/Admin/components/ProductImage/ProductImage";

const ImagesPreview = ({
    images,
    dragImage,
    draggedOverImage,
    handleSort,
    handleDeleteImage,
}) => {
    return (
        <Grid container>
            {images.map((image, index) => (
                <Grid
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    padding={"3px"}
                    key={image.id}
                    draggable
                    onDragStart={() => (dragImage.current = index)}
                    onDragEnter={() => (draggedOverImage.current = index)}
                    onDragEnd={handleSort}
                    onDragOver={(e) => e.preventDefault()}
                >
                    <ProductImage
                        image={image}
                        handleDeleteImage={handleDeleteImage}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default ImagesPreview;
