import React from "react";
import { Navigate, Outlet } from "react-router";

const ProtectedRoute = ({ isAllowed, children }) => {
    if (!isAllowed) {
        return <Navigate to="/login" replace></Navigate>;
    }
    return children ? children : <Outlet />;
};

export default ProtectedRoute;
