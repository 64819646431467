import React from "react";

import {
    FormControl,
    Grid,
    TextField,
    MenuItem,
    InputAdornment,
    FormControlLabel,
    Switch,
    IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

const FilterBar = ({
    categorySelected,
    handleChangeCategory,
    categories,
    subcategorySelected,
    handleChangeSubcategory,
    subcategories,
    article,
    handleChangeArticle,
    setArticle,
    setProductsToShow,
    products,
    handleChangeSwitch,
}) => {
    return (
        <>
            <Grid item xs={12} md={3}>
                <FormControl fullWidth sx={{ padding: "5px" }}>
                    <TextField
                        id="category"
                        name="category"
                        select
                        label="Sexo"
                        value={categorySelected}
                        onChange={handleChangeCategory}
                        // helperText="Por favor seleccione una categoría"
                        margin="normal"
                        // disabled={showLoader}
                    >
                        {categories.map((category) => (
                            <MenuItem key={category.id} value={category}>
                                {category.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
                <FormControl fullWidth sx={{ padding: "5px" }}>
                    <TextField
                        id="subcategory"
                        name="subcategory"
                        select
                        label="Categoría"
                        value={subcategorySelected}
                        onChange={handleChangeSubcategory}
                        margin="normal"
                        nooptionstext={"No hay categorías para mostrar"}
                    >
                        {subcategories.length === 0 ? (
                            <MenuItem disabled>
                                No hay categorías para mostrar
                            </MenuItem>
                        ) : (
                            subcategories.map((subcategory) => (
                                <MenuItem
                                    key={subcategory.id}
                                    value={subcategory}
                                >
                                    {subcategory.name}
                                </MenuItem>
                            ))
                        )}
                    </TextField>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
                <FormControl fullWidth sx={{ padding: "5px" }}>
                    <TextField
                        value={article}
                        label="Artículo"
                        name="article"
                        id="article"
                        margin="normal"
                        onChange={handleChangeArticle}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <IconButton
                                    sx={{
                                        visibility: article
                                            ? "visible"
                                            : "hidden",
                                    }}
                                    onClick={() => {
                                        setArticle("");
                                        setProductsToShow(products);
                                    }}
                                >
                                    <ClearIcon />
                                </IconButton>
                            ),
                        }}
                    />
                </FormControl>
            </Grid>
            {/* <Grid item xs={12} md={3}>
                <FormControl fullWidth sx={{ padding: "5px" }}>
                    <TextField
                        value={productName}
                        label="Nombre de producto"
                        name="productName"
                        id="productName"
                        margin="normal"
                        onChange={handleChangeProductName}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <IconButton
                                    sx={{
                                        visibility: productName
                                            ? "visible"
                                            : "hidden",
                                    }}
                                    onClick={() => {
                                        setProductName("");
                                        setProductsToShow(products);
                                    }}
                                >
                                    <ClearIcon />
                                </IconButton>
                            ),
                        }}
                    />
                </FormControl>
            </Grid> */}
            {/* <Grid item xs={12} sx={{ paddingLeft: "5px" }}>
                <FormControlLabel
                    onChange={handleChangeSwitch}
                    control={<Switch />}
                    label="Productos eliminados"
                />
            </Grid> */}
        </>
    );
};

export default FilterBar;
