import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import API from "../../utils/getDataFromServer";

import AppLayout from "../../utils/Layout/AppLayout";
import CategoryCard from "../../components/CategoryCard/CategoryCard";
import ProductCard from "../../components/ProductCard/ProductCard";

import { Grid } from "@mui/material";
import { faHouseChimney } from "@fortawesome/free-solid-svg-icons";

import utils from "../../utils/utils";
import { useAuth } from "../../context/AuthContext";

const Man = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { auth } = useAuth();

    const [showCategories, setShowCategories] = useState(true);
    const [categories, setCategories] = useState([]);
    const [menuItems, setMenuItems] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedMenuItem, setSelectedMenuItem] = useState("Hombre");
    const [title, setTitle] = useState("");

    async function fetchData() {
        const manCategories = await API.getManCategories();
        setCategories(manCategories);
        const menuIts = utils.getCategoriesMenu(auth);
        setMenuItems(menuIts);
        setShowCategories(true);
        setTitle("Catálogo Masculino");
        if (id !== undefined) {
            const products = await API.getAllProductsFromSubcategory(id, false);
            setShowCategories(false);
            setProducts(products);
            let menuIts = [
                {
                    label: "Hombre",
                    icon: faHouseChimney,
                    url: "/hombre",
                },
            ];
            const getItems = utils.getMenu(manCategories, auth);
            setSelectedMenuItem(products[0].subcategory.name);
            setTitle(products[0].subcategory.name);

            menuIts = [...menuIts, ...getItems];
            setMenuItems(menuIts);
        }
    }
    useEffect(() => {
        fetchData();
    }, [id]);

    const handleClickCategoryCard = async (url, title) => {
        navigate(`/hombre/${url}`);
    };

    const handleClickProductCard = async (item) => {
        navigate(`/hombre/detalle/${item.id}`);
    };

    const handleClickMenuItem = (item) => {
        if (typeof item.url === "string") {
            // fetchData();
            navigate(item.url);
            setSelectedMenuItem(item.label);
        } else {
            handleClickCategoryCard(item.url, item.label);
        }
    };

    const categoriesList = categories.map((category) => (
        <Grid item xs={12} md={6} lg={4} key={category.id}>
            <CategoryCard
                handleClickCard={handleClickCategoryCard}
                url={`${category.id}`}
                alt={category.name}
                src={`${category.image}`}
                title={category.name}
            ></CategoryCard>
        </Grid>
    ));

    const productsList = products.map((product) => (
        <Grid item xs={12} md={6} lg={4} key={product.id}>
            <ProductCard
                handleClickCard={() => handleClickProductCard(product)}
                url={`${product.id}`}
                alt={product.name}
                src={product.imageppal}
                title={`${product.brand.name} - ${product.article}`}
                price={product.price}
                stocks={product.stocks}
            ></ProductCard>
        </Grid>
    ));

    const dataToShow = showCategories === true ? categoriesList : productsList;

    return (
        <AppLayout
            menuItems={menuItems}
            selected={selectedMenuItem}
            title={title}
            handleClickMenuItem={handleClickMenuItem}
        >
            <Grid container spacing={3} justifyContent={"center"}>
                {dataToShow}
            </Grid>
        </AppLayout>
    );
};

export default Man;
