import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useTheme,
    useMediaQuery,
} from "@mui/material";
import React from "react";

const DialogModal = ({
    title,
    description,
    open,
    handleCloseNo,
    handleCloseYes,
}) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleCloseNo}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{description}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCloseNo}>
                    No
                </Button>
                <Button onClick={handleCloseYes} autoFocus>
                    Si
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogModal;
