import React from "react";

import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes,
} from "react-router-dom";
import ProtectedRoute from "./utils/Layout/ProtectedRoute";

import Home from "./pages/Home/Home";
import Woman from "./pages/Woman/Woman";
import Man from "./pages/Man/Man";
import ProductDetail from "./pages/ProductDetail/ProductDetail";
import AdminLayout from "./pages/Admin/pages/AdminLayout/AdminLayout";
import Login from "./pages/Auth/Login";
import { useAuth } from "./context/AuthContext";

function App() {
    const { auth } = useAuth();

    return (
        <Router>
            {/* <div id="headerToggle">
                <span className="toggle" onClick={toggleHeader}></span>
            </div> */}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/mujer" element={<Woman />} />
                <Route path="/hombre" element={<Man />} />

                <Route path="/mujer/:id" element={<Woman />} />
                <Route path="/mujer/categoria/:id" element={<Woman />} />
                <Route path="/hombre/:id" element={<Man />} />

                <Route path="/mujer/detalle/:id" element={<ProductDetail />} />
                <Route path="/hombre/detalle/:id" element={<ProductDetail />} />

                <Route path="/login" element={<Login />} />
                {/* ENVOLVIENDO LAS RUTAS DENTRO DE ESTE ROUTE PUEDO PROTEGERLAS POR LA CONDICION QUE YO QUIERA */}
                <Route element={<ProtectedRoute isAllowed={auth !== false} />}>
                    <Route path="/admin" element={<AdminLayout />} />
                </Route>
                <Route path="*" element={<Navigate to="/"></Navigate>}></Route>
            </Routes>
        </Router>
    );
}

export default App;
