import axios from "axios";
export async function verifyToken() {
    if (!localStorage.getItem("jwt-token")) {
        return false;
    } else {
        const data = {
            data: localStorage.getItem("jwt-token"),
        };
        const res = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/users/verify-token`,
            data,
            {
                headers: {
                    Authorization: localStorage.getItem("jwt-token"),
                },
            }
        );

        if (res.data.auth === false) {
            return false;
        }

        return true;
    }
}
