import { Box, Skeleton } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";

const SkeletonGenerator = ({ quantity, height }) => {
    const [repeat, setRepeat] = useState([]);
    useEffect(() => {
        function create() {
            const r = [];
            for (let i = 0; i < quantity; i++) {
                r.push(i);
            }

            setRepeat(r);
        }

        create();
    }, [quantity]);

    return (
        <Box>
            {repeat.map((r) => (
                <Skeleton key={r} height={height}></Skeleton>
            ))}
        </Box>
    );
};

export default SkeletonGenerator;
