import React from "react";

import { Alert, Backdrop, CircularProgress } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import DialogModal from "../DialogModal/DialogModal";

const Alerts = ({
    showAlertOk,
    alertMessage,
    showAlertFail,
    errorMessage,
    dialogTitle,
    dialogDescription,
    open,
    handleClose,
    handleDelete,
    selectedProductToDeleteOrRestore,
    showBackdrop,
}) => {
    return (
        <>
            {showAlertOk === true ? (
                <Alert
                    icon={<CheckIcon fontSize="inherit" />}
                    severity="success"
                    sx={{ position: "fixed", top: "50%", left: "50%" }}
                >
                    El producto se {alertMessage} con éxito!
                </Alert>
            ) : null}
            {showAlertFail === true ? (
                <Alert
                    icon={<CheckIcon fontSize="inherit" />}
                    severity="error"
                    sx={{ position: "fixed", top: "50%", left: "50%" }}
                >
                    {errorMessage}
                </Alert>
            ) : null}

            <DialogModal
                title={dialogTitle}
                description={dialogDescription}
                open={open}
                handleCloseNo={handleClose}
                handleCloseYes={() =>
                    handleDelete(selectedProductToDeleteOrRestore)
                }
            ></DialogModal>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={showBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};

export default Alerts;
