import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./css/index.scss";
import "./css/mine.scss";
import "./css/hover-min.css";
import { AuthProvider } from "./context/AuthContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <AuthProvider>
            <App />
        </AuthProvider>
    </React.StrictMode>
);
