import * as React from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { esES } from "@mui/x-date-pickers/locales";

export default function DateInput({
    label,
    setDate,
    date,
    minDate = null,
    maxDate = null,
    disabled = false,
}) {
    return (
        <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={
                esES.components.MuiLocalizationProvider.defaultProps.localeText
            }
        >
            <DatePicker
                sx={{ width: "300px" }}
                value={date}
                label={label}
                variant="standard"
                format="DD/MM/YYYY"
                onChange={(newValue) => setDate(newValue)}
                minDate={minDate !== null ? minDate : undefined}
                maxDate={maxDate !== null ? maxDate : undefined}
                disabled={disabled}
            ></DatePicker>
        </LocalizationProvider>
    );
}
