import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import API from "../../utils/getDataFromServer";

import AppLayout from "../../utils/Layout/AppLayout";
import CategoryCard from "../../components/CategoryCard/CategoryCard";
import ProductCard from "../../components/ProductCard/ProductCard";

import { Grid } from "@mui/material";
import { faHouseChimney, faUser } from "@fortawesome/free-solid-svg-icons";

import utils from "../../utils/utils";
import { useAuth } from "../../context/AuthContext";

const Woman = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { auth } = useAuth();

    const location = useLocation();

    const containsCategoria = location.pathname.includes("/categoria");

    const [showCategories, setShowCategories] = useState(false);
    const [showSubcategories, setShowSubcategories] = useState(false);
    const [showProducts, setShowProducts] = useState(false);
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [menuItems, setMenuItems] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedMenuItem, setSelectedMenuItem] = useState("Mujer");
    const [title, setTitle] = useState("");
    const [menuTitle, setMenuTitle] = useState("");
    const [selectedWomanCategory, setSelectedWomanCategory] = useState(
        localStorage.getItem("selectedWomanCategory") || ""
    );
    const [selectedSubcategory, setSelectedSubcategory] = useState("");

    async function fetchData() {
        const womanCategories = await API.getWomanCategories();
        setCategories(womanCategories);
        const menuIts = utils.getCategoriesMenu(auth);
        setMenuItems(menuIts);

        setShowSubcategories(false);
        setShowProducts(false);
        setTitle("Catálogo Femenino");
        if (id !== undefined) {
            if (!containsCategoria) {
                const subs = await API.getWomanSubcategoriesFromWomanCategory(
                    id
                );
                setShowCategories(false);
                setSubcategories(subs);
                setShowSubcategories(true);
                setTitle(subs[0]?.mujer?.name);
                // setSelectedCategory(category.name);
            } else if (containsCategoria) {
                const products = await API.getAllProductsFromSubcategory(
                    id,
                    false
                );

                const subs = await API.getWomanSubcategoriesFromWomanCategory(
                    selectedWomanCategory
                );

                setShowSubcategories(false);
                setProducts(products);
                setShowCategories(false);
                setShowSubcategories(false);
                setShowProducts(true);
                setMenuTitle(subs[0].mujer.name);
                let menuIts = [
                    {
                        label: "Mujer",
                        icon: faHouseChimney,
                        url: "/mujer",
                    },
                ];
                const getItems = utils.getMenu(subs, auth);

                setSelectedMenuItem(selectedSubcategory || subs[0].mujer.name);
                setTitle(selectedSubcategory);

                menuIts = [...menuIts, ...getItems];
                setMenuItems(menuIts);
            }
        } else {
            setShowCategories(true);
        }
    }
    useEffect(() => {
        fetchData();
    }, [id, location]);

    const handleClickCategoryCard = async (category) => {
        if (category.subcategory_id) {
            navigate(`/mujer/categoria/${category.subcategory_id}`);
        } else {
            navigate(`/mujer/${category.id}`);
        }
        // switch (category.id) {
        //     case 2:
        //         navigate("/mujer/categoria/2");
        //         break;
        //     case 4:
        //         navigate("/mujer/categoria/14");
        //         break;
        //     case 6:
        //         navigate("/mujer/categoria/20");
        //         break;
        //     case 7:
        //         navigate("/mujer/categoria/17");
        //         break;
        //     default:
        //         navigate(`/mujer/${category.id}`);
        //         break;
        // }
        setSelectedWomanCategory(category.id);
        localStorage.setItem("selectedWomanCategory", category.id);
        setSelectedSubcategory(category.name);
    };

    const handleClickSubcategoryCard = async (url, title) => {
        setSelectedSubcategory(title);
        navigate(`/mujer/categoria/${url}`);
    };

    const handleClickMenuItem = (item) => {
        if (typeof item.url === "string") {
            navigate(item.url);
            setSelectedMenuItem(item.label);
            setSelectedSubcategory(item.label);
            setMenuTitle("");
        } else {
            handleClickSubcategoryCard(item.url, item.label);
        }
    };

    const handleClickProductCard = async (item) => {
        navigate(`/mujer/detalle/${item.id}`);
    };

    const categoriesList = categories.map((category) => (
        <Grid item xs={12} md={6} lg={4} key={category.id}>
            <CategoryCard
                handleClickCard={() => handleClickCategoryCard(category)}
                url={`${category.id}`}
                alt={category.name}
                src={category.image}
                title={category.name}
            ></CategoryCard>
        </Grid>
    ));

    const subcategoriesList = subcategories.map((subcategory) => (
        <Grid item xs={12} md={6} lg={4} key={subcategory.id}>
            <CategoryCard
                handleClickCard={handleClickSubcategoryCard}
                url={`${subcategory.id}`}
                alt={subcategory.name}
                src={`${subcategory.image}`}
                title={subcategory.name}
            ></CategoryCard>
        </Grid>
    ));

    const productsList = products.map((product) => (
        <Grid item xs={12} md={6} lg={4} key={product.id}>
            <ProductCard
                handleClickCard={() => handleClickProductCard(product)}
                url={`${product.id}`}
                alt={product.name}
                src={product.imageppal}
                title={`${product.brand.name} - ${product.article}`}
                price={product.price}
                stocks={product.stocks}
            ></ProductCard>
        </Grid>
    ));

    const dataToShow =
        showCategories === true ? (
            categoriesList
        ) : showSubcategories === true ? (
            subcategoriesList
        ) : showProducts === true ? (
            productsList.length > 0 ? (
                productsList
            ) : (
                <h1 style={{ fontSize: "35px", marginTop: "200px" }}>
                    No hay productos para mostrar
                </h1>
            )
        ) : (
            []
        );

    return (
        <AppLayout
            menuItems={menuItems}
            menuTitle={menuTitle}
            selected={selectedMenuItem}
            title={title}
            handleClickMenuItem={handleClickMenuItem}
        >
            <Grid container spacing={3} justifyContent={"center"}>
                {dataToShow}
            </Grid>
        </AppLayout>
    );
};

export default Woman;
