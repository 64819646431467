import React from "react";
// import { Link } from "react-router-dom";
import "./styles.scss";

const CategoryCard = ({ handleClickCard, url, src, alt, title }) => {
    return (
        <article
            className="item hvr-float-shadow"
            style={{ cursor: "pointer" }}
            onClick={() => handleClickCard(url, title)}
        >
            <div className="product-image fit">
                <img
                    src={`${process.env.REACT_APP_AWS_IMAGES_ENDPOINT}/img/${src}`}
                    alt={alt}
                />
            </div>
            <header className="card-title">
                <h3>{title}</h3>
            </header>
        </article>
    );
};

export default CategoryCard;
