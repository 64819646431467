import React, { Fragment, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function ClientData({
    openDialog,
    setOpenDialog,
    handleChangeBuyerData,
    buyerData,
    handleFinishSale,
}) {
    const handleClose = () => {
        setOpenDialog(false);
    };

    const inputs = [
        { id: "dni", label: "DNI", type: "number" },
        { id: "name", label: "Nombre", type: "text" },
        { id: "surname", label: "Apellido", type: "text" },
        { id: "email", label: "Email", type: "email" },
        { id: "phone", label: "Teléfono", type: "number" },
    ];

    return (
        <Dialog open={openDialog} onClose={handleClose}>
            <DialogTitle>Datos del comprador</DialogTitle>
            <DialogContent>
                {/* <DialogContentText>
                    To subscribe to this website, please enter your email
                    address here. We will send updates occasionally.
                </DialogContentText> */}
                {inputs.map((input) => (
                    <TextField
                        key={input.id}
                        value={buyerData[input.id] || ""}
                        autoFocus
                        margin="dense"
                        id={input.id}
                        label={input.label}
                        type={input.type}
                        fullWidth
                        variant="outlined"
                        onChange={handleChangeBuyerData}
                    />
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleFinishSale}>Guardar</Button>
            </DialogActions>
        </Dialog>
    );
}
