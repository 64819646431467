import React, { useEffect, useState } from "react";

import {
    Alert,
    Backdrop,
    Button,
    CircularProgress,
    Grid,
    MenuItem,
    Stack,
    TextField,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import { uid } from "uid";

import { createTheme, ThemeProvider } from "@mui/material/styles";

// import { getProductFromBarcode } from "../../../../utils/getDataFromServer";
import API from "../../../../../../utils/getDataFromServer";
import { DataGrid, esES } from "@mui/x-data-grid";
import axios from "axios";
import FormInputs from "./components/FormInputs";
import PaymentOptions from "./components/PaymentOptions";
import { useAuth } from "../../../../../../context/AuthContext";
import ClientData from "./components/ClientData";

const Sign = () => {
    const { auth } = useAuth();
    const theme = createTheme(esES);
    const [openLoader, setOpenLoader] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const [pageSize, setPageSize] = useState(10);

    const [numerations, setNumerations] = useState("");

    const [barcode, setBarcode] = useState("");
    const [productsToShow, setProductsToShow] = useState([]);

    const [showError, setShowError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const [showAlertOk, setShowAlertOk] = useState(false);
    const [showAlertError, setShowAlertError] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    const [totalAmount, setTotalAmount] = useState(0);
    const [totalAmountWithoutDiscount, setTotalAmountWithoutDiscount] =
        useState(0);
    const [finalAmount, setFinalAmount] = useState(0);

    const [selectedPaymentOptions, setSelectedPaymentOptions] = useState([]);

    const [globalDiscount, setGlobalDiscount] = useState(0);
    const [interest, setInterest] = useState(0);
    const [creditAmount, setCreditAmount] = useState(0);

    const [buyerData, setBuyerData] = useState({
        dni: "",
        name: "",
        surname: "",
        email: "",
        phone: "",
    });

    const [sign, setSign] = useState(0);
    const [showSigns, setShowSigns] = useState(false);

    const getColorsArray = (stocks) => {
        const colors = [];
        stocks.forEach((stock) => {
            const color = stock.color;

            // Verifica si el color ya existe en el nuevo array
            const colorExists = colors.some((c) => c.id === color.id);

            // Si no existe, agrégalo al nuevo array
            if (!colorExists) {
                colors.push(color);
            }
        });
        return colors;
    };

    const handleChangeColor = async (product, color) => {
        const productIndex = productsToShow.findIndex(
            (element) => element.id === product.id
        );

        const updatedProduct = { ...productsToShow[productIndex] };
        updatedProduct.selectedColor = color;
        const selectedNumeration = updatedProduct.selectedNumeration;

        if (selectedNumeration !== undefined) {
            const stock = product.stocks.find(
                (stock) =>
                    stock.colorId === color.id &&
                    stock.numerationId === selectedNumeration.id
            );
            updatedProduct.stock = stock !== undefined ? stock.stock : 0;
        }
        setProductsToShow((prevProducts) => {
            const newProducts = [...prevProducts];
            newProducts[productIndex] = updatedProduct;
            return newProducts;
        });
    };

    const handleChangeNumeration = async (product, numeration) => {
        const productIndex = productsToShow.findIndex(
            (element) => element.id === product.id
        );

        const updatedProduct = { ...productsToShow[productIndex] };
        updatedProduct.selectedNumeration = numeration;
        const selectedColor = updatedProduct.selectedColor;

        if (selectedColor !== undefined) {
            console.log("selectedColor ", selectedColor);
            const stock = product.stocks.find(
                (stock) =>
                    stock.numerationId === numeration.id &&
                    stock.colorId === selectedColor.id
            );
            updatedProduct.stock = stock !== undefined ? stock.stock : 0;
        }
        setProductsToShow((prevProducts) => {
            const newProducts = [...prevProducts];
            newProducts[productIndex] = updatedProduct;
            return newProducts;
        });
    };

    const checkProductExists = () => {
        const exists = productsToShow.find((prod) => prod.barcode === barcode);
        if (exists) {
            return false;
        }
        return true;
    };
    useEffect(() => {
        const getProduct = setTimeout(async () => {
            setOpenLoader(true);
            if (barcode !== "") {
                try {
                    if (!checkProductExists()) {
                        setOpenLoader(false);
                        setErrorMsg("El producto ya se encuentra cargado");
                        setShowError(true);
                        setTimeout(() => {
                            setShowError(false);
                        }, 3000);
                        setBarcode("");
                        return;
                    }
                    let prod = await API.getProductFromBarcode(barcode);

                    prod = { ...prod, quantity: 1, discount: "0" };
                    setProductsToShow([...productsToShow, prod]);
                    setTotalAmount(totalAmount + prod.price * prod.quantity);
                    setTotalAmountWithoutDiscount(
                        totalAmountWithoutDiscount + prod.price * prod.quantity
                    );
                    if (prod.stock <= 0) {
                        setErrorMsg(
                            "Producto sin stock, al finalizar la venta, éste quedará con stock negativo"
                        );
                        setShowError(true);
                        setTimeout(() => {
                            setShowError(false);
                        }, 3500);
                    }
                    setBarcode("");
                } catch (error) {
                    setErrorMsg(
                        "El código de barras no corresponde a un producto existente"
                    );
                    setShowError(true);
                    setTimeout(() => {
                        setShowError(false);
                    }, 3000);
                }
            }

            setOpenLoader(false);
        }, 3000);

        return () => clearTimeout(getProduct);
    }, [barcode]);

    useEffect(() => {
        async function fetchNumerations() {
            const nums = await API.getNumerations();
            setNumerations(nums);
        }

        fetchNumerations();
    }, []);

    const handleChangeBarcode = async (evt) => {
        setBarcode(evt.target.value);

        // if (prod !== undefined) {
        //     setProductsToShow([prod]);
        // } else {
        //     setProductsToShow([]);
        // }

        // if (evt.target.value === "") {
        //     setProductsToShow(products);
        // }
        // // const prods = await getProductByBarcode(evt.target.value)
        // // setProductsToShow([prod]);
    };

    const calculateDiscount = (data) => {
        const totalWithoutDiscount = data.price * data.quantity;
        if (data.discount !== 0) {
            const discount = (data.discount * totalWithoutDiscount) / 100;

            return totalWithoutDiscount - discount;
        }
        return totalWithoutDiscount;
    };

    const handleDiscountChange = (id, discount) => {
        // Buscar el producto por su id y actualizar el discount
        const productosActualizados = productsToShow.map((producto) => {
            if (producto.id === id) {
                return { ...producto, discount };
            }
            return producto;
        });

        setProductsToShow(productosActualizados);
    };

    const handleChangeGlobalDiscount = (evt) => {
        setGlobalDiscount(evt.target.value);
        const discount = (evt.target.value * totalAmount) / 100;

        const total = totalAmount - discount;
        setFinalAmount(total);
    };

    const calculateTotalAmount = (withInterest = false) => {
        let totalWithDiscount = 0;
        let totalWithoutDiscount = 0;
        productsToShow.forEach((element) => {
            totalWithDiscount += calculateDiscount(element);
            totalWithoutDiscount += element.price * element.quantity;
        });
        setTotalAmountWithoutDiscount(totalWithoutDiscount);
        setTotalAmount(totalWithDiscount);
        // if (globalDiscount !== 0) {
        const discount = (globalDiscount * totalWithDiscount) / 100;
        const discountedAmount = totalWithDiscount - discount;
        if (withInterest) {
            const amountWithInterest =
                discountedAmount + (discountedAmount * interest) / 100;
            setFinalAmount(amountWithInterest);
            return;
        }
        setFinalAmount(discountedAmount);
        // }
    };

    const calculateTotalAmountWithMultiplesPaymentOptions = () => {
        let totalWithDiscount = 0;
        let totalWithoutDiscount = 0;
        productsToShow.forEach((element) => {
            totalWithDiscount += calculateDiscount(element);
            totalWithoutDiscount += element.price * element.quantity;
        });
        setTotalAmountWithoutDiscount(totalWithoutDiscount);
        setTotalAmount(totalWithDiscount);
        // console.log(totalWithDiscount);
        const otherHalf = totalWithDiscount - parseInt(creditAmount);

        const amountWithInterest =
            parseInt(creditAmount) +
            (parseInt(creditAmount) * parseInt(interest)) / 100;

        const amountWithCreditInterest =
            parseInt(otherHalf) + parseInt(amountWithInterest);

        const discount = (globalDiscount * amountWithCreditInterest) / 100;
        const discountedAmount = amountWithCreditInterest - discount;
        setFinalAmount(discountedAmount);
    };

    const calculateFinalAmount = () => {
        if (interest == 0) {
            console.log("Calculo sin interes");
            calculateTotalAmount();
        } else {
            if (selectedPaymentOptions.length === 1) {
                const withInterest = true;
                console.log("Calculo finalAmount calculando interes");
                calculateTotalAmount(withInterest);
            } else {
                calculateTotalAmountWithMultiplesPaymentOptions();
                console.log(
                    "Calculo interes de la parte de credito unicamente"
                );
            }
        }
    };

    useEffect(() => {
        calculateFinalAmount();
    }, [
        productsToShow,
        selectedPaymentOptions.length,
        interest,
        globalDiscount,
    ]);

    const handleRemoveItem = (id) => {
        const newArray = productsToShow.filter((prod) => prod.id !== id);
        setProductsToShow(newArray);
    };

    const handleChangeStock = (id, selectedStock) => {
        const productIndex = productsToShow.findIndex(
            (element) => element.id === id
        );
        productsToShow[productIndex].quantity = selectedStock;
        calculateFinalAmount();
    };

    useEffect(() => {
        const timeOut = setTimeout(async () => {
            const userData = await API.getBuyerData(buyerData.dni || 0);
            console.log("me ejecuto");
            if (userData) {
                setBuyerData({
                    dni: userData.dni,
                    name: userData.name,
                    surname: userData.surname,
                    email: userData.email,
                    phone: userData.phone,
                });
            }
        }, 1000);
        return () => clearTimeout(timeOut);
    }, [buyerData.dni]);

    const handleChangeBuyerData = (evt) => {
        const { id, value } = evt.target;

        setBuyerData((prevData) => ({
            ...prevData,
            [id]: value,
        }));

        if (id === "dni" && value === "") {
            setBuyerData({
                dni: "",
                name: "",
                surname: "",
                email: "",
                phone: "",
            });
        }
    };

    const validateData = () => {
        if (productsToShow.length === 0) {
            setShowAlertError(true);
            setAlertMessage("Debe agregar al menos un producto");
            setTimeout(() => {
                setShowAlertError(false);
                setAlertMessage("");
            }, 3000);
            return false;
        }
        if (finalAmount === 0) {
            setShowAlertError(true);
            setAlertMessage("Debe seleccionar al menos 1 de stock");
            setTimeout(() => {
                setShowAlertError(false);
                setAlertMessage("");
            }, 3000);
            return false;
        }
        if (selectedPaymentOptions.length === 0) {
            setShowAlertError(true);
            setAlertMessage("Debe seleccionar un método de pago");
            setTimeout(() => {
                setShowAlertError(false);
                setAlertMessage("");
            }, 3000);
            return false;
        }
        setOpenDialog(true);
        return true;
    };
    const handleFinishSale = async () => {
        try {
            if (!validateData()) {
                return;
            }
            setOpenDialog(false);
            // setOpenLoader(true);

            const res = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/sales/new`,
                {
                    products: productsToShow,
                    total: totalAmountWithoutDiscount,
                    paymentOptions: selectedPaymentOptions,
                    discountPercentage: globalDiscount,
                    finalAmount: finalAmount.toFixed(2),
                    buyerData: buyerData,
                },
                {
                    headers: {
                        Authorization: auth,
                    },
                }
            );

            if (res.data.auth === true) {
                setProductsToShow([]);
                setTotalAmount(0);
                setShowAlertOk(true);
                setTimeout(() => {
                    setShowAlertOk(false);
                }, 3000);
                setBuyerData({
                    dni: "",
                    name: "",
                    surname: "",
                    email: "",
                    phone: "",
                });
                setSelectedPaymentOptions([]);
                setInterest(0);
            } else {
                console.log(res);
                setShowAlertError(true);
                setAlertMessage("Ocurrió un error, intente nuevamente");
                setTimeout(() => {
                    setShowAlertError(false);
                    setAlertMessage("");
                }, 3000);
            }
            setOpenLoader(false);
        } catch (error) {
            setShowAlertError(true);
            setAlertMessage("Ocurrió un error, intente nuevamente");
            setTimeout(() => {
                setShowAlertError(false);
                setAlertMessage("");
            }, 3000);
        }
    };

    const columns = [
        { field: "article", headerName: "Artículo", flex: 1 },
        // {
        //     field: "price",
        //     headerName: "Precio",
        //     flex: 1,
        //     disableColumnMenu: true,
        //     sortable: false,
        // },
        {
            field: "price",
            headerName: "Precio unitario",
            flex: 1,
            disableColumnMenu: true,
            sortable: true,
            valueGetter: (params) => params.row.price, // Devuelve el valor numérico sin formato
            valueFormatter: (params) =>
                `$ ${params.value
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, // Formatea el valor con el símbolo de moneda y el formato deseado
            sortComparator: (v1, v2, cellParams1, cellParams2) =>
                cellParams1.value - cellParams2.value, // Compara los valores numéricos para ordenarlos
        },
        {
            field: "color",
            headerName: "Color",
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
                const colors = getColorsArray(params.row.stocks);
                let selected =
                    params.row.selectedColor !== undefined
                        ? params.row.selectedColor
                        : "";
                return (
                    <TextField
                        fullWidth
                        id="color"
                        name="color"
                        select
                        label="Color"
                        defaultValue={""}
                        value={selected}
                        onChange={(evt) => {
                            handleChangeColor(params.row, evt.target.value);
                        }}
                        margin="normal"
                    >
                        {colors.map((color) => (
                            <MenuItem key={color.id} value={color}>
                                {color.name}
                            </MenuItem>
                        ))}
                    </TextField>
                );
            },
        },
        {
            field: "numeration",
            headerName: "Numeración",
            flex: 1,
            disableColumnMenu: true,

            // valueGetter: (params) => params.row.numeration,
            renderCell: (params) => {
                let selected =
                    params.row.selectedNumeration !== undefined
                        ? params.row.selectedNumeration
                        : "";
                return (
                    <TextField
                        // disabled={subcategorySelected.id === 2}
                        className="numerationSelect"
                        fullWidth
                        id="numeration"
                        name="numeration"
                        select
                        label="Numeración"
                        defaultValue={""}
                        value={selected}
                        onChange={(evt) => {
                            handleChangeNumeration(
                                params.row,
                                evt.target.value
                            );
                            // selected = evt.target.value;
                        }}
                        margin="normal"
                    >
                        {numerations.map((numeration) => (
                            <MenuItem key={numeration.id} value={numeration}>
                                {numeration.numeration}
                            </MenuItem>
                        ))}
                    </TextField>
                );
            },
        },
        {
            field: "stock",
            headerName: "Cantidad",
            flex: 1,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => {
                const maxStock = [];
                const stock = params.row.stock;
                if (stock > 0) {
                    for (let i = 1; i <= stock; i++) {
                        maxStock.push(i.toString());
                    }
                } else {
                    maxStock.push(0);
                }

                return (
                    <TextField
                        fullWidth
                        // sx={{ height: "20px !important" }}
                        id="stock"
                        name="stock"
                        select
                        label="Stock disponible"
                        defaultValue="1"
                        value={params.row.quantity}
                        onChange={(evt) =>
                            handleChangeStock(params.row.id, evt.target.value)
                        }
                        margin="normal"
                    >
                        {maxStock.map((stock) => (
                            <MenuItem key={stock} value={stock}>
                                {stock}
                            </MenuItem>
                        ))}
                    </TextField>
                );
            },
        },
        {
            field: "totalWithoutDiscount",
            headerName: "Total parcial",
            flex: 1,
            disableColumnMenu: true,
            sortable: false,
            valueGetter: (params) =>
                (params.row.price * params.row.quantity).toFixed(2),
            valueFormatter: (params) =>
                `$ ${params.value
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, // Formatea el valor con el símbolo de moneda y el formato deseado
            sortComparator: (v1, v2, cellParams1, cellParams2) =>
                cellParams1.value - cellParams2.value, // Compara los valores numéricos para ordenarlos
        },
        {
            field: "discount",
            headerName: "Descuento (%)",
            flex: 1,
            disableColumnMenu: true,
            sortable: false,
            // editable: true,
            renderCell: (params) => {
                const { id, value } = params;
                return (
                    <TextField
                        // fullWidth
                        type="number"
                        value={value}
                        onChange={(e) => {
                            handleDiscountChange(id, e.target.value);
                        }}
                    />
                );
            },
        },
        {
            field: "totalWithDiscount",
            headerName: "Total final",
            flex: 1,
            disableColumnMenu: true,
            sortable: false,
            valueGetter: (params) => calculateDiscount(params.row).toFixed(2),
            valueFormatter: (params) =>
                `$ ${params.value
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, // Formatea el valor con el símbolo de moneda y el formato deseado
            sortComparator: (v1, v2, cellParams1, cellParams2) =>
                cellParams1.value - cellParams2.value, // Compara los valores numéricos para ordenarlos
        },
        {
            field: "actions",
            headerName: "Acciones",
            disableColumnMenu: true,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div>
                        <Button
                            sx={{ marginLeft: "15px" }}
                            variant="contained"
                            color="error"
                            onClick={() => handleRemoveItem(params.id)}
                        >
                            Eliminar
                        </Button>
                    </div>
                );
            },
        },
    ];

    const handleAddProductByArticle = (newProduct) => {
        const checkIfProductIsAdded = productsToShow.filter(
            (prod) => prod.id === newProduct.id
        );
        if (checkIfProductIsAdded.length > 0) {
            setErrorMsg("El producto que intenta agregar ya está cargado");
            setShowError(true);
            setTimeout(() => {
                setShowError(false);
            }, 3000);
            return;
        }

        newProduct = { ...newProduct, quantity: 0, discount: "0" };
        setProductsToShow([...productsToShow, newProduct]);
        setTotalAmount(totalAmount + newProduct.price * newProduct.quantity);

        // ACA TENGO QUE HACER UN FIND DENTRO DE SUS STOCKS Y VER SI TIENE ALGUN STOCK DISPONIBLE
        if (newProduct.stock <= 0) {
            setErrorMsg(
                "Producto sin stock, al finalizar la venta, éste quedará con stock negativo"
            );
            setShowError(true);
            setTimeout(() => {
                setShowError(false);
            }, 3500);
        }
    };

    return (
        <Grid
            container
            spacing={0}
            sx={{
                display: "flex",
                flexDirection: "row",
                // justifyContent: "center",
            }}
        >
            {showSigns === false ? (
                <Button
                    variant="contained"
                    sx={{ margin: "15px 0px" }}
                    onClick={() => setShowSigns(true)}
                >
                    Finalizar venta
                </Button>
            ) : (
                <Button
                    variant="contained"
                    sx={{ margin: "15px 0px" }}
                    onClick={() => setShowSigns(false)}
                >
                    Nueva seña
                </Button>
            )}
            {!showSigns && (
                <>
                    <FormInputs
                        barcode={barcode}
                        setBarcode={setBarcode}
                        handleChangeBarcode={handleChangeBarcode}
                        handleAddProductByArticle={handleAddProductByArticle}
                    ></FormInputs>
                    {showError === true ? (
                        <Grid
                            item
                            xs={12}
                            md={7}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "15px",
                            }}
                        >
                            <Alert severity="error">{errorMsg}</Alert>
                        </Grid>
                    ) : null}
                    <Grid item md={12} mt={2}>
                        <ThemeProvider theme={theme}>
                            <DataGrid
                                getRowHeight={() => "auto"}
                                rows={productsToShow}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: { pageSize: pageSize },
                                    },
                                }}
                                pageSizeOptions={[10, 25, 50]}
                                onPageSizeChange={(newPageSize) =>
                                    setPageSize(newPageSize)
                                }
                                autoHeight
                                // checkboxSelection
                                // onCellEditCommit={(evt) => handleEditDiscount(evt)}
                                disableSelectionOnClick
                                components={{
                                    NoRowsOverlay: () => (
                                        <Stack
                                            height="100%"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            No hay resultados
                                        </Stack>
                                    ),
                                }}
                            />
                        </ThemeProvider>
                        <div style={{ margin: "40px 0px" }}>
                            <PaymentOptions
                                selectedPaymentOptions={selectedPaymentOptions}
                                setSelectedPaymentOptions={
                                    setSelectedPaymentOptions
                                }
                                interest={interest}
                                setInterest={setInterest}
                                finalAmount={finalAmount}
                                paymentMethodsAvailable={finalAmount > 0}
                                setCreditAmount={setCreditAmount}
                            ></PaymentOptions>
                        </div>
                        <Grid
                            item
                            xs={12}
                            md={2}
                            display={"flex"}
                            flexDirection={"column"}
                            gap={2}
                        >
                            <TextField
                                sx={{ marginTop: "20px" }}
                                label="Descuento total (%)"
                                type="number"
                                value={globalDiscount}
                                onChange={handleChangeGlobalDiscount}
                            />
                            <TextField
                                sx={{ marginTop: "20px" }}
                                label="Seña"
                                type="number"
                                value={sign}
                                onChange={(evt) => setSign(evt.target.value)}
                            />
                            <h3>TOTAL </h3>
                            <ul>
                                <li
                                    style={{
                                        width: "190px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    PARCIAL:{" "}
                                    <strong>
                                        $
                                        {totalAmountWithoutDiscount.toLocaleString(
                                            "en-US",
                                            {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            }
                                        )}
                                    </strong>
                                </li>
                                <li
                                    style={{
                                        width: "190px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    FINAL:{" "}
                                    <strong>
                                        $
                                        {finalAmount.toLocaleString("en-US", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}
                                    </strong>
                                </li>
                                <li
                                    style={{
                                        width: "190px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    RESTA:{" "}
                                    <strong>
                                        $
                                        {(finalAmount - sign).toLocaleString(
                                            "en-US",
                                            {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            }
                                        )}
                                    </strong>
                                </li>
                                {/* <li>
                            <strong>
                                $
                                {finalAmount.toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                })}
                            </strong>
                        </li> */}
                            </ul>

                            <Button variant="contained" onClick={validateData}>
                                Guardar seña
                            </Button>
                        </Grid>
                    </Grid>
                </>
            )}
            {showSigns && <h1>Listado de señas anteriores</h1>}
            {showAlertOk === true ? (
                <Alert
                    icon={<CheckIcon fontSize="inherit" />}
                    severity="success"
                    sx={{
                        width: "30%",
                        margin: "20%",
                        position: "fixed",
                        zIndex: 99,
                    }}
                >
                    Venta realizada con éxito!
                </Alert>
            ) : null}
            {showAlertError === true ? (
                <Alert
                    icon={<CheckIcon fontSize="inherit" />}
                    severity="error"
                    sx={{
                        width: "40%",
                        margin: "20%",
                        position: "fixed",
                        zIndex: 99,
                    }}
                >
                    {alertMessage}
                </Alert>
            ) : null}
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={openLoader}
                // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <ClientData
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                handleChangeBuyerData={handleChangeBuyerData}
                buyerData={buyerData}
                handleFinishSale={handleFinishSale}
            />
        </Grid>
    );
};

export default Sign;
