import React, { useEffect, useRef, useState } from "react";
import DialogModal from "../../components/DialogModal/DialogModal";

import {
    Alert,
    Autocomplete,
    Backdrop,
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import axios from "axios";

import CircularProgress from "@mui/material/CircularProgress";
import CheckIcon from "@mui/icons-material/Check";

import { useAuth } from "../../../../context/AuthContext";
import API from "../../../../utils/getDataFromServer";
import SkeletonGenerator from "../../components/SkeletonGenerator/SkeletonGenerator";
import ProductImage from "../../components/ProductImage/ProductImage";

const CategoriesForm = () => {
    const { auth } = useAuth();
    const theme = createTheme(esES);
    const inputFileRef = useRef(null);

    const [showSkeleton, setShowSkeleton] = useState(false);

    const [showWomanSubcategories, setShowWomanSubcategories] = useState(false);
    const [womanSubcategories, setWomanSubcategories] = useState([]);
    const [selectedSubcategory, setSelectedSucategory] = useState("");

    const [editMode, setEditMode] = useState(false);
    const [selectedSubcategoryToEdit, setSelectedSubcategoryToEdit] =
        useState("");

    const [imageIsSelected, setImageIsSelected] = useState(false);
    const [imagePreview, setImagePreview] = useState("");

    const [name, setName] = useState("");
    const [nameError, setNameError] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedWomanCategory, setSelectedWomanCategory] = useState("");

    const [selectedCategoryError, setSelectedCategoryError] = useState(false);
    const [subcategories, setSucategories] = useState([]);
    const [alertMessage, setAlertMessage] = useState("");

    // Loader
    const [showLoader, setShowLoader] = useState(false);
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [showAlertOk, setShowAlertOk] = useState(false);
    const [showAlertFail, setShowAlertFail] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [pageSize, setPageSize] = useState(10);

    const [showAddInput, setShowAddInput] = useState(false);

    // Para eliminar un producto
    const [open, setOpen] = useState(false);
    // const [selectedSubcategoryToDelete, setSelectedSubcategoryToDelete] =
    //     useState("");

    const fetchSubcategories = async (id) => {
        let subs = [];
        if (id === 1) {
            subs = await API.getWomanCategories();

            let womanSubs = subs.filter(
                (sub) =>
                    sub.name.includes("Invierno") || sub.name.includes("Verano")
            );
            womanSubs = [{ id: 0, name: "Todo" }, ...womanSubs];
            setWomanSubcategories(womanSubs);

            setSelectedSucategory(womanSubs[0]);
            setSelectedWomanCategory(womanSubs[0]);
        } else {
            subs = await API.getManCategories();
            setShowAddInput(true);
        }
        setSucategories(subs);
    };

    useEffect(() => {
        const fetchAllCategories = async () => {
            setShowSkeleton(true);
            let categs = await API.getCategoriesOnly();
            setCategories(categs);
            const categoriesToShow = [];
            categs.forEach((cat) => {
                const data = {
                    label: cat.name,
                    id: cat.id,
                };
                categoriesToShow.push(data);
            });
            setCategories(categoriesToShow);
            setShowSkeleton(false);
        };
        fetchAllCategories();
    }, []);

    const handleClose = () => {
        setOpen(false);
    };

    // const handleClickOpen = (id) => {
    //     setOpen(true);
    //     setSelectedSubcategoryToDelete(id);
    // };

    const handleDelete = async (id) => {
        setOpen(false);
        setShowBackdrop(true);
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/subcategories/delete`,
                { id },
                {
                    headers: {
                        Authorization: auth,
                    },
                }
            );

            if (res.status === 200) {
                setAlertMessage("eliminó");
                setShowAlertOk(true);
                setShowBackdrop(false);
                setTimeout(() => {
                    setShowAlertOk(false);
                    setAlertMessage("");
                }, 3000);

                await fetchSubcategories(selectedCategory.id);
            }
        } catch (error) {
            setErrorMessage("Ocurrió un error, intente nuevamente");
            setShowBackdrop(false);
            setShowAlertFail(true);
            setTimeout(() => {
                setShowAlertFail(false);
                setErrorMessage("");
            }, 3000);
        }
    };

    const handleClickEdit = (subcategory) => {
        setEditMode(true);
        setShowAddInput(true);
        setSelectedSubcategoryToEdit(subcategory);
        setName(subcategory.name);
        setImagePreview("");
        resetFileInput();
    };

    const columns = [
        { field: "id", headerName: "ID", width: 70 },
        { field: "name", headerName: "Nombre", flex: 1 },
        {
            field: "products",
            headerName: "Productos asociados",
            flex: 1,
            sortable: false,
            valueGetter: (params) => params.row.products,
        },
        {
            field: "actions",
            headerName: "Acciones",
            flex: 1,
            renderCell: (params) => {
                return (
                    <div>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={() => handleClickEdit(params.row)}
                        >
                            Editar
                        </Button>

                        {/* <Button
                            sx={{ marginLeft: "15px" }}
                            variant="contained"
                            color="error"
                            onClick={() => handleClickOpen(params.id)}
                        >
                            Eliminar
                        </Button> */}
                    </div>
                );
            },
        },
    ];

    const saveData = async (formData) => {
        try {
            setShowBackdrop(true);
            const cat = new FormData(formData);
            cat.append("categoryId", selectedCategory.id);
            if (editMode) {
                cat.append("oldImage", selectedSubcategoryToEdit.image);
                if (
                    selectedCategory.id === 1 &&
                    selectedWomanCategory.id === 0
                ) {
                    cat.append("womanCategory", selectedSubcategoryToEdit.id);
                } else {
                    cat.append("subcategoryId", selectedSubcategoryToEdit.id);
                }
            } else {
                cat.append("mujerId", selectedSubcategory.id);
            }

            let operation = editMode === true ? "update" : "create";
            const res = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/subcategories/${operation}`,
                cat,
                {
                    headers: {
                        Authorization: auth,
                    },
                }
            );
            setShowBackdrop(false);

            if (res.data.error === false) {
                setAlertMessage("actualizó");
                setEditMode(false);
                setImagePreview("");
                setShowAlertOk(true);
                resetFileInput();
                setName("");
                setSelectedSubcategoryToEdit("");
                setTimeout(function () {
                    setShowAlertOk(false);
                    setAlertMessage("");
                }, 3000);
                if (selectedCategory.id === 1) {
                    handleChangeWomanCategory(selectedWomanCategory);
                } else {
                    await fetchSubcategories(selectedCategory.id);
                }
            } else {
                setShowAlertFail(true);
                setErrorMessage(res.data.message);
                setTimeout(function () {
                    setShowAlertFail(false);
                }, 3000);
            }
        } catch (error) {
            setShowLoader(false);
            setErrorMessage("Ocurrió un error, intente nuevamente!");
            setShowAlertFail(true);
            setTimeout(function () {
                setShowAlertFail(false);
            }, 3000);
        }
    };

    const handleChangeCategory = async (value) => {
        setSelectedCategoryError(false);
        setSelectedSubcategoryToEdit("");
        if (editMode) {
            setEditMode(false);
            setName("");
        }
        if (value !== null) {
            setSelectedCategory(value);
            if (value.id === 1) {
                setShowWomanSubcategories(true);
                setShowAddInput(false);
            } else {
                setShowWomanSubcategories(false);
            }
            await fetchSubcategories(value.id);
        } else {
            setSelectedCategory(null);
            setSucategories([]);
        }
    };

    const handleChangeWomanCategory = async (value) => {
        setSelectedWomanCategory(value);
        setSelectedSucategory(value);
        setSelectedSubcategoryToEdit("");
        setName("");

        if (value.id === 0) {
            await fetchSubcategories(1);
            setShowAddInput(false);
        } else {
            const subs = await API.getWomanSubcategoriesFromWomanCategory(
                value.id
            );
            setSucategories(subs);
            setShowAddInput(true);
        }
    };

    const checkFormData = (evt) => {
        evt.preventDefault();
        if (!name.trim()) {
            setNameError(true);
            return;
        }
        if (selectedCategory === "") {
            setSelectedCategoryError(true);
            return;
        }

        saveData(evt.currentTarget);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        inputFileRef.current = e.target;

        if (file) {
            // Crear una URL de objeto (blob) para la imagen seleccionada
            const imageUrl = URL.createObjectURL(file);
            setImagePreview(imageUrl);
        } else {
            setImagePreview(null);
        }
    };

    const resetFileInput = () => {
        if (inputFileRef.current) {
            inputFileRef.current.value = null;
        }
        setImageIsSelected(false);
    };

    return (
        <Grid
            container
            spacing={0}
            sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
            }}
        >
            <Grid item xs={12} md={6}>
                <Box className="title">
                    <Typography
                        variant="h5"
                        sx={{
                            textAlign: "center",
                            textTransform: "uppercase",
                            fontWeight: "bold",
                        }}
                    >
                        Editar categorías
                    </Typography>
                </Box>

                <FormControl fullWidth margin="normal">
                    <InputLabel id="subcategory">Seleccione un sexo</InputLabel>
                    <Select
                        labelId="sex"
                        name="sex"
                        label="Seleccione un sexo"
                        value={selectedCategory}
                        onChange={(evt) => {
                            handleChangeCategory(evt.target.value);
                        }}
                        disabled={showLoader}
                    >
                        {categories.map((category) => (
                            <MenuItem
                                key={category.id}
                                value={category}
                                name="sex"
                            >
                                <ListItemText primary={category.label} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {showWomanSubcategories ? (
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="womanCategories">
                            Seleccione una categoria
                        </InputLabel>
                        <Select
                            labelId="womanCategories"
                            name="womanCategory"
                            label="Seleccione una categoria"
                            value={selectedSubcategory}
                            onChange={(evt) => {
                                handleChangeWomanCategory(evt.target.value);
                            }}
                            disabled={showLoader}
                        >
                            {womanSubcategories.map((subcategory) => (
                                <MenuItem
                                    name="womanCategory"
                                    key={subcategory.id}
                                    value={subcategory}
                                >
                                    <ListItemText primary={subcategory.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ) : (
                    ""
                )}
                {showAddInput === true ? (
                    <form
                        onSubmit={checkFormData}
                        encType="multipart/form-data"
                    >
                        <FormControl fullWidth>
                            <TextField
                                error={nameError}
                                value={name}
                                fullWidth
                                label="Nombre"
                                name="name"
                                id="name"
                                margin="normal"
                                onChange={(e) => {
                                    setName(e.target.value);
                                    setNameError(false);
                                }}
                                disabled={showLoader}
                            />
                        </FormControl>
                        <Button
                            variant="contained"
                            component="label"
                            className="pink-btn"
                            disabled={showLoader}
                        >
                            Subir imágen
                            <input
                                type="file"
                                name="portada"
                                hidden
                                // ref={(ref) => setImageUpload(ref)}
                                ref={inputFileRef}
                                onChange={(evt) => handleImageChange(evt)}
                            />
                        </Button>
                        <small style={{ marginLeft: "10px" }}>
                            {imageIsSelected === true
                                ? "Portada seleccionada"
                                : "Seleccione una portada"}
                        </small>

                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "10px",
                            }}
                        >
                            <Button
                                // sx={{ marginTop: "25px" }}
                                // onClick={saveData}
                                variant="contained"
                                className="pink-btn"
                                disabled={showLoader}
                                type="submit"
                            >
                                Guardar
                            </Button>

                            <Button
                                sx={{ marginLeft: "10px" }}
                                onClick={() => {
                                    setEditMode(false);
                                    setShowAddInput(false);
                                    setSelectedSubcategoryToEdit("");
                                    setImagePreview("");
                                    setName("");
                                    resetFileInput();
                                }}
                                variant="contained"
                                className="pink-btn"
                                disabled={showLoader}
                                color="error"
                            >
                                Cancelar
                            </Button>
                        </Box>
                    </form>
                ) : (
                    ""
                )}

                <Grid container sx={{ display: "flex", flexDirection: "row" }}>
                    {selectedSubcategoryToEdit !== "" ? (
                        <Grid item xs={12} sm={4} md={4} padding={"3px"}>
                            <h4>PORTADA ACTUAL:</h4>
                            <img
                                src={`${process.env.REACT_APP_AWS_IMAGES_ENDPOINT}/img/${selectedSubcategoryToEdit.image}`}
                                alt="Vista previa de la imagen"
                                style={{
                                    width: "100%",
                                }}
                            />
                        </Grid>
                    ) : (
                        ""
                    )}
                    {imagePreview !== "" ? (
                        <Grid item xs={12} sm={4} md={4} padding={"3px"}>
                            <h4>PORTADA NUEVA:</h4>
                            <img
                                src={imagePreview}
                                alt="Vista previa de la imagen"
                                style={{
                                    width: "100%",
                                }}
                            />
                        </Grid>
                    ) : (
                        ""
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12} md={10} xl={7}>
                {showSkeleton === false ? (
                    subcategories.length !== 0 ? (
                        <div
                            style={{
                                minHeight: 480,
                                height: "100%",
                                width: "100%",
                                marginTop: "25px",
                            }}
                        >
                            <ThemeProvider theme={theme}>
                                <DataGrid
                                    rows={subcategories}
                                    columns={columns}
                                    pageSize={pageSize}
                                    autoHeight
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: pageSize,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[10, 25, 50]}
                                    onPageSizeChange={(newPageSize) =>
                                        setPageSize(newPageSize)
                                    }
                                    disableSelectionOnClick
                                    components={{
                                        NoRowsOverlay: () => (
                                            <Stack
                                                height="100%"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                No hay resultados
                                            </Stack>
                                        ),
                                    }}
                                />
                            </ThemeProvider>
                        </div>
                    ) : (
                        ""
                    )
                ) : (
                    <Box>
                        <SkeletonGenerator
                            quantity={7}
                            height={60}
                        ></SkeletonGenerator>
                    </Box>
                )}
                <div
                    style={{
                        height: "30px",
                    }}
                ></div>
            </Grid>

            {showAlertOk === true ? (
                <Alert
                    icon={<CheckIcon fontSize="inherit" />}
                    severity="success"
                    sx={{ position: "fixed", top: "50%" }}
                >
                    La categoría se {alertMessage} con éxito!
                </Alert>
            ) : null}
            {showAlertFail === true ? (
                <Alert
                    icon={<CheckIcon fontSize="inherit" />}
                    severity="error"
                    sx={{ position: "fixed", top: "50%" }}
                >
                    {errorMessage}
                </Alert>
            ) : null}
            {showLoader === true ? (
                <Box sx={{ position: "fixed", top: "50%" }}>
                    <CircularProgress />
                </Box>
            ) : null}

            {/* <DialogModal
                title="Está seguro que desea eliminar esta subcategoría?"
                description="Si la elimina se borrarán los productos asociados a ella y no podrá recuperarlos"
                open={open}
                handleCloseNo={handleClose}
                handleCloseYes={() => handleDelete(selectedSubcategoryToDelete)}
            ></DialogModal> */}
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={showBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Grid>
    );
};

export default CategoriesForm;
