import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";

import utils from "../../utils/utils";
import SideBar from "../../components/SideBar/SideBar";
import ProductCard from "../../components/CategoryCard/CategoryCard";
import Footer from "../../components/Footer/Footer";
import { useAuth } from "../../context/AuthContext";
import API from "../../utils/getDataFromServer";

const Home = () => {
    const navigate = useNavigate();
    const { auth } = useAuth();

    const [categories, setCategories] = useState();

    const handleClickCard = (url) => {
        navigate(url);
        // console.log(url);
    };

    const handleNavigate = (item) => {
        navigate(item.url);

        const section = item.url.replace("#", "");
        const element = document.getElementById(section);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        const fetchCategories = async () => {
            const categs = await API.getCategoriesOnly();
            setCategories(categs);
        };
        fetchCategories();
    }, []);

    return (
        <div>
            {/* <Header menuItems={utils.homeMenu} /> */}
            <SideBar
                isHome={true}
                menuItems={utils.getHomeMenu(auth)}
                selectedSection={"Hombre"}
                handleClickItem={handleNavigate}
            >
                <div id="main">
                    <section id="inicio" className="one dark cover">
                        <h1 style={{ display: "none" }}>Zapatería Avenida</h1>
                        <div className="container"></div>
                    </section>

                    <section id="catalogo" className="two">
                        <div className="container">
                            <header>
                                <h2>Catálogo</h2>
                            </header>

                            <p>
                                Calzados 100% cómodos, confortables, de cuero,
                                para un andar diario en donde tus pies no
                                duelan. Trabajamos calzado de hombre, de mujer,
                                y carteras.
                            </p>

                            <div className="row">
                                {categories && (
                                    <div
                                        className="row productos"
                                        style={{ margin: "auto" }}
                                    >
                                        {categories.map((category) => (
                                            <ProductCard
                                                key={category.id}
                                                handleClickCard={
                                                    handleClickCard
                                                }
                                                url={category.name.toLowerCase()}
                                                src={category.image}
                                                alt={`Categoría ${category.name}`}
                                                title={category.name}
                                            ></ProductCard>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </section>

                    <section id="quienes-somos" className="three">
                        <div className="container">
                            <header>
                                <h2>¿Quiénes Somos?</h2>
                            </header>

                            <img
                                className="image featured"
                                src={`${process.env.REACT_APP_AWS_IMAGES_ENDPOINT}/img/pic08.jpg`}
                                alt=""
                            />

                            <p>
                                Somos Zapatería Avenida, una empresa de gran
                                reconocimiento en la localidad de La Plata,
                                ofrecemos lo mejor en zapatos en cuero y
                                variedad en carteras. Una empresa familiar con
                                más de 60 años de trayectoria, comercializando
                                calzado de cuero de la mejor calidad y a precios
                                competitivos.
                            </p>

                            <p>
                                La buena atención y la excelente calidad de las
                                materias primas utilizadas, nos posicionan como
                                una excelente empresa en el rubro. Elija
                                nuestros productos.
                            </p>
                        </div>
                    </section>
                </div>
                <Footer></Footer>
            </SideBar>
        </div>
    );
};

export default Home;
