import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid, esES } from "@mui/x-data-grid";

import axios from "axios";
import { useAuth } from "../../../../context/AuthContext";

import { Grid, IconButton, MenuItem, Stack, TextField } from "@mui/material";

import API from "../../../../utils/getDataFromServer";

import SkeletonGenerator from "../../components/SkeletonGenerator/SkeletonGenerator";
import FilterBar from "../../components/FilterBar/FilterBar";

import Alerts from "../../components/Alerts/Alerts";
import utils from "../../utils/utils";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MonitorIcon from "@mui/icons-material/Monitor";
import RestoreIcon from "@mui/icons-material/Restore";
import QrCode2Icon from "@mui/icons-material/QrCode2";

import "./productsList.scss";
import QRForm from "../QRForm/QRForm";

const ProductsList = ({ handleChangeToEdit }) => {
    const theme = createTheme(esES);

    const { auth } = useAuth();

    // const [selectedColor, setSelectedColor] = useState("");
    const [numerations, setNumerations] = useState("");
    // const [selectedNumeration, setSelectedNumeration] = useState("");

    // const [columns, setColumns] = useState([]);

    const [showSkeleton, setShowSkeleton] = useState(false);
    const [pageSize, setPageSize] = useState(10);

    const [products, setProducts] = useState([]);
    const [productsToShow, setProductsToShow] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);

    //Filters
    const [article, setArticle] = useState("");
    const [productName, setProductName] = useState("");

    const [showDeletedProducts, setShowDeletedProducts] = useState(false);

    const [categorySelected, setCategorySelected] = useState("");
    const [subcategorySelected, setSubcategoySelected] = useState("");

    const [open, setOpen] = useState(false);

    const [openQrForm, setOpenQrForm] = useState(false);
    const [productForQr, setProductForQr] = useState("");

    const [
        selectedProductToDeleteOrRestore,
        setSelectedProductToDeleteOrRestore,
    ] = useState("");
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogDescription, setDialogDescription] = useState("");

    const navigate = useNavigate();

    // Loader
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [showAlertOk, setShowAlertOk] = useState(false);
    const [showAlertFail, setShowAlertFail] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [alertMessage, setAlertMessage] = useState("");

    async function fetchData(reset = true) {
        setShowSkeleton(true);
        const prods = await API.getAllProducts();
        const cats = await API.getCategoriesOnly();
        const nums = await API.getNumerations();

        if (reset) {
            setProducts(prods);
            setProductsToShow(prods);
            setNumerations(nums);
        }

        // updateVisibleColumns();

        setCategories([
            { id: 0, name: "Todo", products: prods, subcategories: [] },
            ...cats,
        ]);
        setShowSkeleton(false);
    }

    useEffect(() => {
        fetchData();
    }, []);
    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = (id) => {
        setOpen(true);
        setSelectedProductToDeleteOrRestore(id);
    };

    const handleDelete = async (id) => {
        setOpen(false);
        setShowBackdrop(true);
        const action = showDeletedProducts === false ? "delete" : "restore";

        try {
            const res = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/products/${action}`,
                { id },
                {
                    headers: {
                        Authorization: auth,
                    },
                }
            );

            if (res.status === 200) {
                setAlertMessage(
                    showDeletedProducts === false ? "eliminó" : "restauró"
                );
                setShowAlertOk(true);
                setShowBackdrop(false);
                setTimeout(() => {
                    setShowAlertOk(false);
                    setAlertMessage("");
                }, 3000);
                if (showDeletedProducts === false) {
                    await fetchData();
                } else {
                    await fetchData(false);
                    const prods = await API.getDeletedProducts();
                    setProducts(prods);
                    setProductsToShow(prods);
                }
                setCategorySelected("");
                setSubcategoySelected("");
                setSubcategories([]);
            }
        } catch (error) {
            setErrorMessage("Ocurrió un error, intente nuevamente");
            setShowBackdrop(false);
            setShowAlertFail(true);
            setTimeout(() => {
                setShowAlertFail(false);
                setErrorMessage("");
            }, 3000);
        }
    };

    const navigateToDetail = (item) => {
        const category = item.subcategory.category.name.toLowerCase();
        navigate(`/${category}/detalle/${item.id}`);
    };

    const setProductsFromCategory = async (category) => {
        if (category.id !== 0) {
            let subcategories = [];
            if (category.id === 1) {
                subcategories = await API.getWomanSubcategories();
            } else {
                subcategories = await API.getManCategories();
            }

            const subs = [
                { id: 0, name: "Todo", products: category.products },
                ...subcategories,
            ];
            setSubcategories(subs);
            setSubcategoySelected(subs[0]);

            if (showDeletedProducts) {
                const deletedProductsCategory =
                    await API.getAllDeletedProductsFromCategory(category.id);
                setProductsToShow(deletedProductsCategory);
            } else {
                const activeProductsCategory =
                    await API.getAllProductsFromCategory(category.id);
                // console.log(activeProductsCategory);
                setProductsToShow(activeProductsCategory);
            }
        } else {
            setSubcategories([]);
            setSubcategoySelected("");

            setProductsToShow(products);
        }
    };
    const handleChangeCategory = async (event) => {
        const category = event.target.value;
        setCategorySelected(category);
        setArticle("");
        setProductsFromCategory(category);
    };

    const handleChangeSubcategory = async (event) => {
        const sub = event.target.value;
        setSubcategoySelected(sub);
        columns = [];
        if (sub.id === 0) {
            setProductsFromCategory(categorySelected);
            return;
        }
        if (showDeletedProducts) {
            const deletedProductsSubcategory =
                await API.getAllDeletedProductsFromSubcategory(sub.id);
            // console.log(deletedProductsSubcategory);
            // setProductsToShow(deletedProductsSubcategory);
        } else {
            const activeProductsSubcategory =
                await API.getAllProductsFromSubcategory(sub.id);
            // console.log(activeProductsSubcategory);
            setProductsToShow(activeProductsSubcategory);
        }
    };

    const handleChangeArticle = async (evt) => {
        setCategorySelected("");
        setSubcategoySelected("");
        setSubcategories([]);
        setArticle(evt.target.value);
        const prods = products.filter((product) =>
            product.article
                .toLowerCase()
                .includes(evt.target.value.toLowerCase())
        );

        if (prods !== undefined) {
            setProductsToShow(prods);
        } else {
            setProductsToShow([]);
        }

        if (evt.target.value === "") {
            setProductsToShow(products);
        }
    };

    const handleChangeSwitch = async (evt) => {
        setCategorySelected("");
        setSubcategoySelected("");
        setSubcategories([]);
        setArticle("");
        if (evt.target.checked) {
            setShowDeletedProducts(true);
            const prods = await API.getDeletedProducts();
            setProducts(prods);
            setProductsToShow(prods);
        } else {
            setShowDeletedProducts(false);
            const prods = await API.getAllProducts();
            setProducts(prods);
            setProductsToShow(prods);
        }
    };

    const editStock = (params, updatedProduct, field, productIndex, value) => {
        const row = params.row;
        const isSomeOfNoNumerationCategory =
            row.subcategoryId === 2 ||
            row.subcategoryId === 35 ||
            row.subcategoryId === 36;
        if (isSomeOfNoNumerationCategory && !updatedProduct.selectedColor) {
            setErrorMessage("Debe seleccionar un color");
            setShowAlertFail(true);
            setTimeout(() => {
                setShowAlertFail(false);
                setErrorMessage("");
            }, 3000);

            return;
        }
        if (
            !isSomeOfNoNumerationCategory &&
            (!updatedProduct.selectedColor ||
                !updatedProduct.selectedNumeration)
        ) {
            setErrorMessage("Debe seleccionar un color y una numeración");
            setShowAlertFail(true);
            setTimeout(() => {
                setShowAlertFail(false);
                setErrorMessage("");
            }, 3000);

            return;
        }

        const numId = isSomeOfNoNumerationCategory
            ? null
            : updatedProduct.selectedNumeration.id;

        let data = {
            productId: params.id,
            colorId: updatedProduct.selectedColor.id,
            numerationId: numId,
            getStocks: false,
        };
        data[field] = value;

        let stockIndex = updatedProduct.stocks.findIndex(
            (stock) =>
                stock.color.id === updatedProduct.selectedColor.id &&
                stock.numerationId === numId
        );
        if (stockIndex !== -1) {
            updatedProduct.stocks[stockIndex].stock = parseInt(value);
            updatedProduct.stock = parseInt(value);
            setProductsToShow((prevProducts) => {
                const newProducts = [...prevProducts];
                newProducts[productIndex] = updatedProduct;
                return newProducts;
            });
        } else {
            data.getStocks = true;
        }
        return data;
    };

    const editPrice = () => {
        console.log("Price");
    };

    const handleEditStockOrPrice = async (params, evt) => {
        if (params.reason === "cellFocusOut") {
            evt.defaultMuiPrevented = true;
            console.log("Debe presionar enter para guardar el nuevo stock");
            return;
        }
        const field = params.field;
        const productIndex = productsToShow.findIndex(
            (element) => element.id === params.id
        );
        const updatedProduct = { ...productsToShow[productIndex] };

        let data;
        if (field === "stock") {
            data = editStock(
                params,
                updatedProduct,
                field,
                productIndex,
                evt.target.value
            );
        } else if (field === "price") {
            data = editPrice(
                params,
                updatedProduct,
                field,
                productIndex,
                evt.target.value
            );
        }

        if (!data) {
            return;
        }

        let url =
            field === "stock"
                ? "/products/updateStock"
                : "/products/updatePrice";

        try {
            await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}${url}`,
                data,
                {
                    headers: {
                        Authorization: auth,
                    },
                }
            );
            setAlertMessage("actualizó");
            setShowAlertOk(true);
            setTimeout(() => {
                setShowAlertOk(false);
                setAlertMessage("");
            }, 3000);
            if (data.getStocks === true) {
                const res = await API.getStocksFromProduct(updatedProduct.id);
                const objetosNoRepetidos = res.filter(
                    (stock2) =>
                        !updatedProduct.stocks.some(
                            (stock1) => stock1.id === stock2.id
                        )
                );
                updatedProduct.stocks.push(...objetosNoRepetidos);
                updatedProduct.stock = evt.target.value;

                setProductsToShow((prevProducts) => {
                    const newProducts = [...prevProducts];
                    newProducts[productIndex] = updatedProduct;
                    return newProducts;
                });
            }
        } catch (error) {
            setErrorMessage("Ocurrió un error, intente nuevamente");
            setShowAlertFail(true);
            setTimeout(() => {
                setShowAlertFail(false);
                setErrorMessage("");
            }, 3000);
        }
    };

    const handleChangeColor = async (product, color) => {
        const productIndex = productsToShow.findIndex(
            (element) => element.id === product.id
        );

        const updatedProduct = { ...productsToShow[productIndex] };
        updatedProduct.selectedColor = color;
        const selectedNumeration = updatedProduct.selectedNumeration;

        if (
            product.subcategoryId !== 2 &&
            product.subcategoryId !== 35 &&
            product.subcategoryId !== 36
        ) {
            if (selectedNumeration !== undefined) {
                const stock = product.stocks.find(
                    (stock) =>
                        stock.colorId === color.id &&
                        stock.numerationId === selectedNumeration.id
                );
                updatedProduct.stock = stock !== undefined ? stock.stock : 0;
            }
        } else {
            const stock = product.stocks.find(
                (stock) => stock.colorId === color.id
            );
            updatedProduct.stock = stock !== undefined ? stock.stock : 0;
        }
        setProductsToShow((prevProducts) => {
            const newProducts = [...prevProducts];
            newProducts[productIndex] = updatedProduct;
            return newProducts;
        });
    };

    const handleChangeNumeration = async (product, numeration) => {
        const productIndex = productsToShow.findIndex(
            (element) => element.id === product.id
        );

        const updatedProduct = { ...productsToShow[productIndex] };

        updatedProduct.selectedNumeration = numeration;
        const selectedColor = updatedProduct.selectedColor;

        if (selectedColor !== undefined) {
            const stock = product.stocks.find(
                (stock) =>
                    stock.numerationId === numeration.id &&
                    stock.colorId === selectedColor.id
            );
            updatedProduct.stock = stock !== undefined ? stock.stock : 0;
        }
        setProductsToShow((prevProducts) => {
            const newProducts = [...prevProducts];
            newProducts[productIndex] = updatedProduct;
            return newProducts;
        });
    };

    const getColorsArray = (stocks) => {
        const colors = [];
        stocks.forEach((stock) => {
            const color = stock.color;

            // Verifica si el color ya existe en el nuevo array
            const colorExists = colors.some((c) => c.id === color.id);

            // Si no existe, agrégalo al nuevo array
            if (!colorExists) {
                colors.push(color);
            }
        });
        return colors;
    };

    const handleOpenQrDialog = (product) => {
        setProductForQr(product);
        setOpenQrForm(true);
    };

    let columns = [
        {
            field: "sex",
            headerName: "Sexo",
            flex: 1,
            disableColumnMenu: true,
            sortable: true,
            valueGetter: (params) => params.row.subcategory.category.name,
            sortModel: {
                sort: "asc", // 'asc' para ascendente, 'desc' para descendente
            },
        },
        {
            field: "category",
            headerName: "Categoría",
            flex: 1,
            disableColumnMenu: true,
            valueGetter: (params) => params.row.subcategory.name,
        },

        {
            field: "brand",
            headerName: "Marca",
            flex: 1,
            disableColumnMenu: true,
            valueGetter: (params) => params.row.brand.name,
        },
        {
            field: "article",
            headerName: "Artículo",
            flex: 1,
            disableColumnMenu: true,
            // valueGetter: (params) => params.row.subcategory.name,
        },
        {
            field: "price",
            headerName: "Precio",
            flex: 1,
            disableColumnMenu: true,
            // sortable: false,
            editable: true,
            valueGetter: (params) =>
                // "$ " +
                params.row.price
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            sortComparator: (a, b) => {
                const precio1 = parseFloat(a.replace(/[^0-9.]/g, ""));
                const precio2 = parseFloat(b.replace(/[^0-9.]/g, ""));

                return precio1 - precio2;
            },
        },
        {
            field: "color",
            headerName: "Color",
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
                const colors = getColorsArray(params.row.stocks);
                let selected =
                    params.row.selectedColor !== undefined
                        ? params.row.selectedColor
                        : "";
                return (
                    <TextField
                        fullWidth
                        id="color"
                        name="color"
                        select
                        label="Color"
                        defaultValue={""}
                        value={selected}
                        onChange={(evt) => {
                            handleChangeColor(params.row, evt.target.value);
                        }}
                        margin="normal"
                    >
                        {colors.map((color) => (
                            <MenuItem key={color.id} value={color}>
                                {color.name}
                            </MenuItem>
                        ))}
                    </TextField>
                );
            },
        },
        {
            field: "numeration",
            headerName: "Numeración",
            flex: 1,
            disableColumnMenu: true,

            // valueGetter: (params) => params.row.numeration,
            renderCell: (params) => {
                const row = params.row;
                let selected =
                    params.row.selectedNumeration !== undefined
                        ? params.row.selectedNumeration
                        : "";
                let disabled =
                    row.subcategoryId === 2 ||
                    row.subcategoryId === 35 ||
                    row.subcategoryId === 36;
                return (
                    <TextField
                        disabled={disabled}
                        className="numerationSelect"
                        fullWidth
                        id="numeration"
                        name="numeration"
                        select
                        label="Numeración"
                        defaultValue={""}
                        value={selected}
                        onChange={(evt) => {
                            handleChangeNumeration(
                                params.row,
                                evt.target.value
                            );
                            // selected = evt.target.value;
                        }}
                        margin="normal"
                    >
                        {numerations.map((numeration) => (
                            <MenuItem key={numeration.id} value={numeration}>
                                {numeration.numeration}
                            </MenuItem>
                        ))}
                    </TextField>
                );
            },
        },
        {
            field: "stock",
            headerName: "Stock",
            // flex: 1,
            editable: true,
            type: "number",
            align: "left",
            headerAlign: "left",
            disableColumnMenu: true,
        },

        {
            field: "actions",
            headerName: "Acciones",
            // flex: 1,
            width: 180,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <div>
                        <IconButton
                            aria-label="Ver detalle"
                            title="Ver detalle"
                            sx={{
                                "&:hover": {
                                    background: "none",
                                },
                            }}
                            onClick={() => navigateToDetail(params.row)}
                        >
                            <MonitorIcon
                                sx={{
                                    cursor: "pointer",
                                    "&:hover": {
                                        color: "#2f7d31ba",
                                    },
                                }}
                                variant="contained"
                                color="success"
                            ></MonitorIcon>
                        </IconButton>
                        <IconButton
                            title="Editar"
                            sx={{
                                "&:hover": {
                                    background: "none",
                                },
                            }}
                            onClick={() => {
                                navigate(`/admin?edit=true&id=${params.id}`);
                                handleChangeToEdit(params.id);
                            }}
                        >
                            <EditIcon
                                sx={{
                                    cursor: "pointer",
                                    "&:hover": {
                                        color: "#ed6d01a3",
                                    },
                                }}
                                variant="contained"
                                color="warning"
                            >
                                Editar
                            </EditIcon>
                        </IconButton>

                        {params.row.active === true ? (
                            <IconButton
                                title="Eliminar"
                                sx={{
                                    "&:hover": {
                                        background: "none",
                                    },
                                }}
                                onClick={() => {
                                    setDialogTitle(
                                        "Está seguro que desea eliminar este producto?"
                                    );
                                    setDialogDescription(
                                        "Se eliminará el producto de forma definitiva y no podrá recuperarlo"
                                    );
                                    handleClickOpen(params.id);
                                }}
                            >
                                <DeleteIcon
                                    sx={{
                                        cursor: "pointer",
                                        "&:hover": {
                                            color: "#d3302fc2",
                                        },
                                    }}
                                    variant="contained"
                                    color="error"
                                >
                                    Eliminar
                                </DeleteIcon>
                            </IconButton>
                        ) : (
                            <IconButton
                                title="Restaurar"
                                sx={{
                                    "&:hover": {
                                        background: "none",
                                    },
                                }}
                                onClick={() => {
                                    setDialogTitle(
                                        "Está seguro que desea restaurar este producto?"
                                    );
                                    setDialogDescription(
                                        "El producto pasará a estado visible"
                                    );
                                    handleClickOpen(params.id);
                                }}
                            >
                                <RestoreIcon
                                    sx={{
                                        cursor: "pointer",
                                        zIndex: 999,
                                        "&:hover": {
                                            color: "#ed6d01a3",
                                        },
                                    }}
                                    variant="contained"
                                    color="warning"
                                >
                                    Restaurar
                                </RestoreIcon>
                            </IconButton>
                        )}
                        <IconButton
                            title="Editar"
                            sx={{
                                "&:hover": {
                                    background: "none",
                                },
                            }}
                            onClick={() => handleOpenQrDialog(params.row)}
                        >
                            <QrCode2Icon
                                sx={{
                                    cursor: "pointer",
                                    "&:hover": {
                                        color: "#a4a0a0",
                                    },
                                }}
                                variant="contained"
                            >
                                Crear QR
                            </QrCode2Icon>
                        </IconButton>
                    </div>
                );
            },
        },
    ];

    // console.log(columns.filter((col) => col.field !== "numeration"));

    // const updateVisibleColumns = async () => {
    //     console.log("Entro");
    //     if (window.innerWidth <= 900) {
    //         // Ejemplo de ancho de pantalla para móviles
    //         setColumns(utils.getReducedColumns());
    //     } else {
    //         // const numerations = await API.getNumerations();
    //         const getColumns = utils.getDataGridColumns(
    //             navigateToDetail,
    //             handleChangeToEdit,
    //             setDialogTitle,
    //             setDialogDescription,
    //             handleClickOpen,
    //             selectedColor,
    //             handleChangeColor
    //         );
    //         setColumns(getColumns);
    //     }
    // };

    // useEffect(() => {
    //     updateVisibleColumns();
    //     window.addEventListener("resize", updateVisibleColumns);
    // }, []);

    return (
        <Grid
            container
            spacing={0}
            sx={{ display: "flex", flexDirection: "row" }}
        >
            <FilterBar
                categorySelected={categorySelected}
                handleChangeCategory={handleChangeCategory}
                categories={categories}
                subcategorySelected={subcategorySelected}
                handleChangeSubcategory={handleChangeSubcategory}
                subcategories={subcategories}
                article={article}
                handleChangeArticle={handleChangeArticle}
                setArticle={setArticle}
                setProductsToShow={setProductsToShow}
                products={products}
                handleChangeSwitch={handleChangeSwitch}
            ></FilterBar>

            <Grid item xs={12} margin="20px auto">
                <div
                    className="dataGrid"
                    style={{ marginBottom: "20px", minHeight: 480 }}
                >
                    {showSkeleton === false ? (
                        <ThemeProvider theme={theme}>
                            <DataGrid
                                className="colorSelect"
                                rowHeight={65}
                                sortingOrder={["desc", "asc"]}
                                autoHeight
                                rows={productsToShow}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: { pageSize: pageSize },
                                    },
                                }}
                                pageSizeOptions={[10, 25, 50]}
                                onPageSizeChange={(newPageSize) =>
                                    setPageSize(newPageSize)
                                }
                                disableSelectionOnClick
                                // isCellEditable={(params) =>
                                //     params.row.selectedColor !== undefined &&
                                //     params.row.selectedNumeration !== undefined
                                // }
                                onCellEditStop={(params, evt) =>
                                    handleEditStockOrPrice(params, evt)
                                }
                                // components={{
                                //     NoRowsOverlay: () => (
                                //         <Stack
                                //             height="100%"
                                //             alignItems="center"
                                //             justifyContent="center"
                                //         >
                                //             No hay resultados
                                //         </Stack>
                                //     ),
                                // }}
                            />
                        </ThemeProvider>
                    ) : (
                        <SkeletonGenerator
                            quantity={10}
                            height={60}
                        ></SkeletonGenerator>
                    )}
                </div>
                <Alerts
                    showAlertOk={showAlertOk}
                    alertMessage={alertMessage}
                    showAlertFail={showAlertFail}
                    errorMessage={errorMessage}
                    dialogTitle={dialogTitle}
                    dialogDescription={dialogDescription}
                    open={open}
                    handleClose={handleClose}
                    handleDelete={handleDelete}
                    selectedProductToDeleteOrRestore={
                        selectedProductToDeleteOrRestore
                    }
                    showBackdrop={showBackdrop}
                />
                <QRForm
                    product={productForQr}
                    open={openQrForm}
                    setOpen={setOpenQrForm}
                ></QRForm>
            </Grid>
        </Grid>
    );
};

export default ProductsList;
