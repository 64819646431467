import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import SideBar from "../../components/SideBar/SideBar";
import Footer from "../../components/Footer/Footer";
import { useLocation } from "react-router-dom";

const AppLayout = ({
    isHome = false,
    menuItems,
    menuTitle,
    handleClickMenuItem,
    title,
    selected,
    children,
}) => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (
        <SideBar
            isHome={isHome}
            menuItems={menuItems}
            menuTitle={menuTitle}
            selectedSection={selected}
            handleClickItem={handleClickMenuItem}
        >
            <div id="main">
                <section id="catalogo" className="two">
                    <div className="container">
                        <header>
                            <h2>{title}</h2>
                        </header>
                        {children}
                    </div>
                </section>
            </div>
            <Footer></Footer>
        </SideBar>
    );
};

export default AppLayout;
