import React, { useEffect, useState } from "react";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
} from "@mui/material";

import API from "../../../../../../../utils/getDataFromServer";

const PaymentOptions = ({
    selectedPaymentOptions,
    setSelectedPaymentOptions,
    interest,
    setInterest,
    finalAmount,
    paymentMethodsAvailable = false,
    setCreditAmount,
}) => {
    const [options, setOptions] = useState([]);
    const [creditOptions, setCreditOptions] = useState([]);

    const [showCreditOptions, setShowCreditOptions] = useState(false);
    const [selectedCreditPayment, setSelectedCreditPayment] = useState("");

    const [paymentMethodOne, setPaymentMethodOne] = useState(0);
    const [paymentMethodTwo, setPaymentMethodTwo] = useState(0);

    useEffect(() => {
        async function getData() {
            const data = await API.getPaymentOptions();
            const withoutCreditOpts = data.filter(
                (element) => !element.name.includes("Crédito")
            );
            const creditOpts = data.filter((element) =>
                element.name.includes("Crédito")
            );

            setOptions(withoutCreditOpts);
            setCreditOptions(creditOpts);
        }

        if (selectedPaymentOptions.length === 1) {
            selectedPaymentOptions[0].amount = finalAmount;
        }

        if (selectedPaymentOptions.length === 0) {
            setShowCreditOptions(false);
            setSelectedCreditPayment("");
        }

        setPaymentMethodOne(0);
        setPaymentMethodTwo(0);
        setInterest(0);

        getData();
    }, [selectedPaymentOptions.length]);

    const handleChangeOption = (evt, option) => {
        setSelectedPaymentOptions((prevItems) => {
            if (prevItems.find((prevItem) => prevItem.id === option.id)) {
                return prevItems.filter(
                    (prevItem) => prevItem.id !== option.id
                );
            }
            return [...prevItems, option];
        });
        setPaymentMethodOne(0);
        setPaymentMethodTwo(0);
    };
    const handleChangeCreditOption = (evt, option) => {
        const exists = selectedPaymentOptions.find((prevItem) =>
            prevItem.name.includes("Crédito")
        );

        if (exists) {
            const newArr = selectedPaymentOptions.filter(
                (opts) => !opts.name.includes("Crédito")
            );
            newArr.push(option);
            setSelectedPaymentOptions(newArr);
            return;
        }

        setSelectedPaymentOptions((prevItems) => [...prevItems, option]);
    };

    const handleChangePaymentMethodAmount = (
        evt,
        setFirstInput,
        setSecondInput,
        optionName
    ) => {
        setFirstInput(evt.target.value);
        setSecondInput(finalAmount - evt.target.value);
        if (optionName.includes("Crédito")) {
            setCreditAmount(evt.target.value);
        } else if (
            selectedPaymentOptions.find((prevItem) =>
                prevItem.name.includes("Crédito")
            )
        ) {
            setCreditAmount(finalAmount - evt.target.value);
        }
        setSelectedPaymentOptions((prevItems) =>
            prevItems.map((item) =>
                item.name === optionName
                    ? { ...item, amount: parseFloat(evt.target.value) }
                    : {
                          ...item,
                          amount: parseFloat(finalAmount - evt.target.value),
                      }
            )
        );
    };

    return (
        <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
                Método de pago
            </FormLabel>
            <Grid>
                <FormControlLabel
                    onChange={(evt) => {
                        setShowCreditOptions(!showCreditOptions);
                        if (!evt.target.checked) {
                            setSelectedPaymentOptions((prevItems) =>
                                prevItems.filter(
                                    (item) => !item.name.includes("Crédito")
                                )
                            );
                            setSelectedCreditPayment("");
                            setInterest(0);
                        }
                    }}
                    checked={
                        selectedPaymentOptions.find((opt) =>
                            opt.name.includes("Crédito")
                        ) !== undefined
                    }
                    disabled={!paymentMethodsAvailable}
                    value="credit"
                    control={<Checkbox />}
                    label="Crédito"
                />

                {options &&
                    options.map((opt) => (
                        <FormControlLabel
                            key={opt.id}
                            disabled={!paymentMethodsAvailable}
                            onChange={(evt) => {
                                handleChangeOption(evt, opt);
                            }}
                            checked={
                                selectedPaymentOptions.find(
                                    (option) => option.id === opt.id
                                ) !== undefined
                            }
                            value={opt}
                            control={<Checkbox />}
                            label={opt.name}
                        />
                    ))}

                <Grid container>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={selectedCreditPayment}
                    >
                        {showCreditOptions &&
                            creditOptions.map((credOpt) => (
                                <Grid item xs={12} key={credOpt.id}>
                                    <FormControlLabel
                                        checked={
                                            selectedPaymentOptions.find(
                                                (opt) => opt.id === credOpt.id
                                            ) !== undefined
                                        }
                                        value={credOpt.id}
                                        control={<Radio />}
                                        label={credOpt.name.split("-")[1]}
                                        onChange={(evt) => {
                                            handleChangeCreditOption(
                                                evt,
                                                credOpt
                                            );
                                            setSelectedCreditPayment(
                                                evt.target.value
                                            );
                                        }}
                                    />
                                </Grid>
                            ))}
                    </RadioGroup>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                {selectedPaymentOptions.length === 2 && (
                    <>
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth>
                                <TextField
                                    id="paymentMethodOne"
                                    name="paymentMethodOne"
                                    label={selectedPaymentOptions[0].name}
                                    value={paymentMethodOne}
                                    onChange={(evt) =>
                                        handleChangePaymentMethodAmount(
                                            evt,
                                            setPaymentMethodOne,
                                            setPaymentMethodTwo,
                                            selectedPaymentOptions[0].name
                                        )
                                    }
                                    margin="normal"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth>
                                <TextField
                                    id="paymentMethodOne"
                                    name="paymentMethodOne"
                                    label={selectedPaymentOptions[1].name}
                                    // defaultValue=""
                                    value={paymentMethodTwo}
                                    onChange={(evt) =>
                                        handleChangePaymentMethodAmount(
                                            evt,
                                            setPaymentMethodTwo,
                                            setPaymentMethodOne,
                                            selectedPaymentOptions[1].name
                                        )
                                    }
                                    margin="normal"
                                />
                            </FormControl>
                        </Grid>
                    </>
                )}
                {selectedCreditPayment !== "" &&
                    selectedPaymentOptions.length <= 2 && (
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth>
                                <TextField
                                    id="interest"
                                    name="interest"
                                    label="Interés"
                                    type="number"
                                    value={interest}
                                    onChange={(evt) => {
                                        setInterest(evt.target.value);
                                        setSelectedPaymentOptions((prevItems) =>
                                            prevItems.map((item) =>
                                                item.name.includes("Crédito")
                                                    ? {
                                                          ...item,
                                                          interest:
                                                              evt.target.value,
                                                      }
                                                    : item
                                            )
                                        );
                                    }}
                                    onWheel={(e) => e.target.blur()}
                                    onTouchStart={(e) => e.target.blur} // Para dispositivos táctiles
                                    onTouchMove={(e) => e.target.blur} // Para dispositivos táctiles
                                    margin="normal"
                                />
                            </FormControl>
                        </Grid>
                    )}
            </Grid>
        </FormControl>
    );
};

export default PaymentOptions;
