import React, { useState, useEffect, useRef } from "react";

import {
    Typography,
    Box,
    MenuItem,
    FormControl,
    Grid,
    Button,
    Alert,
    InputLabel,
    Select,
    Checkbox,
    ListItemText,
    OutlinedInput,
    Stack,
    FormControlLabel,
    Switch,
    FormLabel,
    FormHelperText,
} from "@mui/material";
import TextField from "@mui/material/TextField";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid, esES } from "@mui/x-data-grid";

import CircularProgress from "@mui/material/CircularProgress";
import CheckIcon from "@mui/icons-material/Check";

import API from "../../../../utils/getDataFromServer";

import axios from "axios";
import ProductImage from "../../components/ProductImage/ProductImage";
import DialogModal from "../../components/DialogModal/DialogModal";
import { v4 as uuidv4 } from "uuid";
import utils from "./utils";
import ImagesPreview from "../../../../components/ImagesPreview/ImagesPreview";

const ProductForm = ({ editMode = false, productId = "" }) => {
    const theme = createTheme(esES);
    const [pageSize, setPageSize] = useState(5);

    const [columns, setColumns] = useState([]);

    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [womanSubcategories, setWomanSubcategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [numerations, setNumerations] = useState([]);
    const [colors, setColors] = useState([]);

    // Loader
    const [showLoader, setShowLoader] = useState(true);
    const [showAlertOk, setShowAlertOk] = useState(false);
    const [showAlertFail, setShowAlertFail] = useState(false);
    const [alertErrorMsg, setAlertErrorMsg] = useState(
        "Ocurrió un error, intente nuevamente!"
    );

    // States for delete images
    const [open, setOpen] = React.useState(false);
    const [imageToDeleteId, setImageToDeleteId] = useState("");
    const [showAlertDeletedImageOk, setShowAlertDeletedImageOk] =
        useState(false);

    // Form States

    const [article, setArticle] = useState("");
    const [sexSelected, setSexSelected] = useState("");
    const [subcategorySelected, setSubcategorySelected] = useState("");
    const [womanSubcategorySelected, setWomanSubcategorySelected] =
        useState("");
    const [colorSelected, setColorSelected] = useState("");
    const [brandSelected, setBrandSelected] = useState("");
    const [numerationSelected, setNumerationSelected] = useState("");

    const [visible, setVisible] = useState(false);

    const [imageUpload, setImageUpload] = useState("");
    const [imagesSelected, setImagesSelected] = useState(0);

    const [description, setDescription] = useState("");
    const [price, setPrice] = useState("");
    const [stock, setStock] = useState("");

    // Form errors
    const [articleError, setArticleError] = useState(false);
    const [descriptionError, setDescriptionError] = useState(false);
    const [priceError, setPriceError] = useState(false);
    const [stockError, setStockError] = useState(false);
    const [sexError, setSexError] = useState(false);
    const [brandError, setBrandError] = useState(false);
    const [subcategoryError, setSubcategoryError] = useState(false);
    const [womanSubcategoryError, setWomanSubcategoryError] = useState(false);

    const [priceHelperText, setPriceHelperText] = useState("");
    const [stockHelperText, setStockHelperText] = useState("");

    const [productToEdit, setProductToEdit] = useState(null);
    const [refreshImages, setRefreshImages] = useState(false);

    const [colorNumerationStock, setColorNumerationStock] = useState([]);
    const [colorNumerationStockToDelete, setColorNumerationStockToDelete] =
        useState("");
    const [selectedColorList, setSelectedColorList] = useState([]);
    const [colorImageSelected, setColorImageSelected] = useState("");

    const resetForm = () => {
        setArticle("");
        setSexSelected("");
        setSubcategorySelected("");
        setBrandSelected("");
        setColorNumerationStock([]);
        setWomanSubcategorySelected("");
        setDescription("");
        setPrice("");
        setStock("");
        setImagesSelected(0);
        setColorSelected("");
        setNumerationSelected("");
        if (imageUpload) {
            imageUpload.value = ""; // Limpia los archivos seleccionados
        }
    };

    const getAllBrands = async () => {
        const brands = await API.getAllBrands();
        setBrands(brands);
    };

    useEffect(() => {
        if (editMode === false) {
            resetForm();
        }
        async function fetchAllOptions() {
            const categories = await API.getCategoriesOnly();
            setCategories(categories);
            getAllBrands();

            const colors = await API.getColors();
            setColors(colors);

            const numerations = await API.getNumerations();
            setNumerations(numerations);

            // setColumns(
            //     utils.getShoesColumns(
            //         setColorNumerationStockToDelete,
            //         colors,
            //         numerations
            //     )
            // );

            if (productId !== "") {
                const prodToEdit = await API.getProductDetail(productId);

                prodToEdit.subcategoryId === 2 ||
                prodToEdit.subcategoryId === 35 ||
                prodToEdit.subcategoryId === 36
                    ? setColumns(
                          utils.getBagsColumns(
                              colors,
                              setColorNumerationStockToDelete
                          )
                      )
                    : setColumns(
                          utils.getShoesColumns(
                              setColorNumerationStockToDelete,
                              colors,
                              numerations
                          )
                      );
                let subcategories = [];
                if (prodToEdit.subcategory.category.id === 1) {
                    subcategories = await API.getWomanCategories();
                } else {
                    subcategories = await API.getManCategories();
                }
                setSubcategories(subcategories);
                setProductToEdit(prodToEdit);

                setSexSelected(prodToEdit.subcategory.category.id);

                if (prodToEdit.subcategory.category.id === 1) {
                    const subcategory = prodToEdit.subcategory.mujer.id;

                    if (subcategory === 2) {
                        const brands = await API.getCarterasBrands();
                        setBrands(brands);
                    } else {
                        getAllBrands();
                    }
                    if (subcategory === 1 || subcategory === 3) {
                        const woman =
                            await API.getWomanSubcategoriesFromWomanCategory(
                                subcategory
                            );
                        setWomanSubcategories(woman);
                    }
                    setSubcategorySelected(prodToEdit.subcategory.mujer.id);
                    setWomanSubcategorySelected(prodToEdit.subcategory.id);
                } else {
                    setSubcategorySelected(prodToEdit.subcategory.id);
                }

                setArticle(prodToEdit.article);
                setBrandSelected(prodToEdit.brand.id);
                setDescription(prodToEdit.description);
                setPrice(prodToEdit.price);
                setVisible(prodToEdit.visible);

                const stockArr = [];
                prodToEdit.stocks.forEach((stock) => {
                    stockArr.push({
                        id: stock.id,
                        color: stock.colorId,
                        numeration: stock.numerationId,
                        stock: stock.stock,
                    });
                });
                setColorNumerationStock(stockArr);
            }
            setShowLoader(false);
        }

        fetchAllOptions();
    }, [productId, editMode, refreshImages]);

    const handleChangeCategory = async (event) => {
        const categoryId = event.target.value;

        setSexSelected(categoryId);
        setSexError(false);
        getAllBrands();
        setSubcategorySelected("");

        let subcategories = [];
        if (categoryId === 1) {
            subcategories = await API.getWomanCategories();
        } else {
            subcategories = await API.getManCategories();
            setColumns(
                utils.getShoesColumns(
                    setColorNumerationStockToDelete,
                    colors,
                    numerations
                )
            );
        }
        setSubcategories(subcategories);
        return;
    };

    const handleChangeSubcategory = async (event) => {
        const subcategory = event.target.value;
        console.log(subcategory);
        if (sexSelected === 1) {
            if (subcategory === 2) {
                const brands = await API.getCarterasBrands();
                setBrands(brands);
                setColumns(
                    utils.getBagsColumns(
                        colors,
                        setColorNumerationStockToDelete
                    )
                );
            } else {
                getAllBrands();
                setColumns(
                    utils.getShoesColumns(
                        setColorNumerationStockToDelete,
                        colors,
                        numerations
                    )
                );
            }
            if (subcategory === 1 || subcategory === 3) {
                const woman = await API.getWomanSubcategoriesFromWomanCategory(
                    subcategory
                );
                setWomanSubcategories(woman);
            }
            if (subcategory === 9) {
                const brands = await API.getAccesoriosBrands();
                setBrands(brands);
                setColumns(
                    utils.getBagsColumns(
                        colors,
                        setColorNumerationStockToDelete
                    )
                );
            }
        } else {
            if (subcategory === 35) {
                console.log("entro");
                const brands = await API.getAccesoriosBrands();
                setBrands(brands);
                setColumns(
                    utils.getBagsColumns(
                        colors,
                        setColorNumerationStockToDelete
                    )
                );
            }
        }
        setSubcategorySelected(subcategory);
        setSubcategoryError(false);
        return;
    };
    const handleWomanChangeSubcategory = async (event) => {
        const womanSubcategory = event.target.value;

        setWomanSubcategorySelected(womanSubcategory);
        setWomanSubcategoryError(false);
        return;
    };

    // Delete one image from book AND modal
    const handleCloseNo = () => {
        setOpen(false);
    };
    const handleCloseYes = async () => {
        setOpen(false);

        const res = await axios.delete(
            `${process.env.REACT_APP_BACKEND_URL}/products/deleteImage/${imageToDeleteId}`,
            {
                headers: {
                    Authorization: localStorage.getItem("jwt-token"),
                },
            }
        );

        if (res.status === 200) {
            setShowAlertDeletedImageOk(true);
            setTimeout(function () {
                setShowAlertDeletedImageOk(false);
            }, 1500);
        } else {
            setShowAlertFail(true);
            setTimeout(function () {
                setShowAlertFail(false);
            }, 1500);
        }
        setRefreshImages(!refreshImages);
        // const productImagesArray = productToEdit.productImages;
        // const imgPosition = productImagesArray.findIndex((image) => {
        //     return image.id === imageToDeleteId;
        // });

        // productImagesArray.splice(imgPosition, 1);
    };

    const handleDeleteImage = async (evt) => {
        setOpen(true);
        setImageToDeleteId(evt);
    };

    // Saving the data
    const checkFormData = (evt) => {
        evt.preventDefault();

        if (sexSelected === "") {
            setSexError(true);
            return;
        }
        if (subcategorySelected === "") {
            setSubcategoryError(true);
            return;
        }
        if (!article.trim()) {
            setArticleError(true);
            return;
        } else {
            setArticleError(false);
        }
        if (!brandSelected === "") {
            setBrandError(true);
            return;
        } else {
            setBrandError(false);
        }

        if (!description.trim()) {
            setDescriptionError(true);
            return;
        } else {
            setDescriptionError(false);
        }
        // if (price === null || !/^\d+$/.test(price)) {
        if (price === null || !price.toString().match(/^-?\d*(\.\d+)?$/)) {
            setPriceError(true);
            setPriceHelperText("Ingrese solo números");
            return;
        } else {
            setPriceError(false);
        }

        saveData(evt.currentTarget);
    };

    const saveData = async (formData) => {
        setShowLoader(true);
        let operation = editMode === false ? "create" : "update";
        // operation =
        //     subcategorySelected === 2 ? (operation += "Cartera") : operation;

        const product = new FormData(formData);
        product.append(
            "colorNumerationStock",
            JSON.stringify(colorNumerationStock)
        );

        if (editMode === true) {
            product.append("id", productToEdit.id);
            product.append("imagesArray", JSON.stringify(productToEdit.images));
        }

        try {
            const res = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/products/${operation}`,
                product,
                {
                    headers: {
                        Authorization: localStorage.getItem("jwt-token"),
                    },
                }
            );

            setShowLoader(false);

            if (res.data.status === 200) {
                setShowAlertOk(true);
                setRefreshImages(!refreshImages);
                setTimeout(function () {
                    if (editMode === false) {
                        resetForm();
                    } else {
                        if (imageUpload) {
                            imageUpload.value = ""; // Limpia los archivos seleccionados
                        }
                        setImagesSelected(0);
                    }
                    setShowAlertOk(false);
                }, 1000);
            } else {
                setShowAlertFail(true);
                setAlertErrorMsg(res.data.message);
                setTimeout(function () {
                    setShowAlertFail(false);
                }, 2000);
            }
        } catch (error) {
            setShowLoader(false);
            setShowAlertFail(true);
            setTimeout(function () {
                setShowAlertFail(false);
            }, 2000);
        }
    };

    const handleAddProductStock = () => {
        console.log(subcategorySelected);

        if (stock === null || !/^\d+$/.test(parseInt(stock))) {
            setStockError(true);
            setStockHelperText("Ingrese un valor");
            return;
        } else {
            setStockError(false);
        }
        if (colorSelected.id === "") {
            console.log("falta color");
            return;
        }
        if (
            subcategorySelected !== 2 &&
            subcategorySelected !== 35 &&
            subcategorySelected !== 9 &&
            numerationSelected === ""
        ) {
            console.log("falta num");
            return;
        }
        const exists = colorNumerationStock.find(
            (item) =>
                item.color === colorSelected.id &&
                item.numeration === numerationSelected
        );

        if (exists) {
            console.log("Ya hay stock para ese color y talle");
            return;
        }
        console.log(numerationSelected);
        setColorNumerationStock([
            ...colorNumerationStock,
            {
                id: uuidv4(),
                color: colorSelected.id,
                numeration: numerationSelected,
                stock: stock,
            },
        ]);

        const colorExists = selectedColorList.findIndex(
            (item) => item.id === colorSelected.id
        );
        if (colorExists !== 0) {
            setSelectedColorList((prevState) => [...prevState, colorSelected]);
        }
    };

    const deleteColorStockNumeration = async (id) => {
        const stockIndex = colorNumerationStock.findIndex(
            (item) => item.id === id
        );
        if (stockIndex !== -1) {
            const newArr = [...colorNumerationStock];
            newArr.splice(stockIndex, 1);
            setColorNumerationStock(newArr);
            await API.deleteStock({ stockId: id });
        }
    };

    useEffect(() => {
        deleteColorStockNumeration(colorNumerationStockToDelete);
    }, [colorNumerationStockToDelete]);

    // const columns = [
    //     {
    //         field: "color",
    //         headerName: "Color",
    //         flex: 1,
    //         disableColumnMenu: true,
    //         sortable: true,
    //         valueGetter: (params) =>
    //             colors.length > 0
    //                 ? colors.find((color) => color.id === params.row.color).name
    //                 : "",
    //     },
    //     {
    //         field: "numeration",
    //         headerName: "Numeración",
    //         flex: 1,
    //         disableColumnMenu: true,
    //         valueGetter: (params) =>
    //             numerations.length > 0
    //                 ? numerations.find(
    //                       (numeration) =>
    //                           numeration.id === params.row.numeration
    //                   ).numeration
    //                 : "",
    //     },

    //     {
    //         field: "stock",
    //         headerName: "Stock",
    //         flex: 1,
    //         disableColumnMenu: true,
    //         // valueGetter: (params) => params.row.brand.name,
    //     },
    //     {
    //         field: "actions",
    //         headerName: "Acciones",
    //         flex: 1,
    //         disableColumnMenu: true,
    //         renderCell: (params) => {
    //             return (
    //                 <div>
    //                     <Button
    //                         sx={{ marginLeft: "15px" }}
    //                         variant="contained"
    //                         color="error"
    //                         onClick={() =>
    //                             deleteColorStockNumeration(params.id)
    //                         }
    //                     >
    //                         Eliminar
    //                     </Button>
    //                 </div>
    //             );
    //         },
    //         // valueGetter: (params) => params.row.brand.name,
    //     },
    // ];

    const handleEditStock = (params, evt) => {
        evt.preventDefault();
        if (params.reason === "cellFocusOut") {
            evt.defaultMuiPrevented = true;
            console.log("Debe presionar enter para guardar el nuevo stock");
            return;
        }
        const stockIndex = colorNumerationStock.findIndex(
            (element) => element.id === params.id
        );

        const updatedStock = { ...colorNumerationStock[stockIndex] };
        if (stockIndex !== -1) {
            updatedStock.stock = parseInt(evt.target.value);
            setColorNumerationStock((prevStocks) => {
                const newStocks = [...prevStocks];
                newStocks[stockIndex] = updatedStock;
                return newStocks;
            });
        }
    };

    const dragImage = useRef(0);
    const draggedOverImage = useRef(0);

    const handleSort = async () => {
        const imagesArrayClone = [...productToEdit.images];
        const temp = imagesArrayClone[dragImage.current];
        imagesArrayClone[dragImage.current] =
            imagesArrayClone[draggedOverImage.current];
        imagesArrayClone[draggedOverImage.current] = temp;
        setProductToEdit({ ...productToEdit, images: imagesArrayClone });
        await API.sortImages({
            productId: productToEdit.id,
            images: imagesArrayClone,
        });
    };

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            sx={{ minHeight: "100vh" }}
        >
            <Box className="title">
                <Typography
                    variant="h5"
                    sx={{
                        textAlign: "center",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                    }}
                >
                    {editMode === false ? "Crear" : "Actualizar"} producto
                </Typography>
            </Box>

            <Box
                sx={{
                    width: { md: "60%" },
                    maxWidth: "100%",
                }}
            >
                <form onSubmit={checkFormData} encType="multipart/form-data">
                    <FormControl fullWidth>
                        <TextField
                            error={sexError}
                            id="sex"
                            name="sex"
                            select
                            label="Sexo"
                            // defaultValue=""
                            value={sexSelected}
                            onChange={handleChangeCategory}
                            margin="normal"
                            disabled={showLoader}
                        >
                            {categories.map((category) => (
                                <MenuItem key={category.id} value={category.id}>
                                    {category.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="subcategory">Categoría</InputLabel>
                        <Select
                            labelId="subcategory"
                            label="Categoría"
                            name="category"
                            error={subcategoryError}
                            value={subcategorySelected}
                            onChange={handleChangeSubcategory}
                            disabled={showLoader}
                        >
                            {subcategories.length === 0 && (
                                <MenuItem disabled>
                                    No hay categorías para mostrar
                                </MenuItem>
                            )}
                            {subcategories.map((subcategory) => (
                                <MenuItem
                                    key={subcategory.id}
                                    value={subcategory.id}
                                >
                                    <ListItemText primary={subcategory.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {subcategorySelected === 1 || subcategorySelected === 3 ? (
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="womanSubcategories">
                                Subcategoría
                            </InputLabel>
                            <Select
                                labelId="womanSubcategories"
                                label="Subcategoria"
                                name="womanSubcategory"
                                error={womanSubcategoryError}
                                value={womanSubcategorySelected}
                                onChange={handleWomanChangeSubcategory}
                                disabled={showLoader}
                            >
                                {womanSubcategories.map((womanSubcategory) => (
                                    <MenuItem
                                        key={womanSubcategory.id}
                                        value={womanSubcategory.id}
                                    >
                                        <ListItemText
                                            primary={womanSubcategory.name}
                                        />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : (
                        ""
                    )}

                    <FormControl fullWidth margin="normal">
                        <InputLabel id="brand">Marca</InputLabel>
                        <Select
                            labelId="brand"
                            label="Marca"
                            name="brand"
                            error={brandError}
                            value={brandSelected}
                            onChange={(evt) =>
                                setBrandSelected(evt.target.value)
                            }
                            disabled={showLoader}
                        >
                            {brands.map((brand) => (
                                <MenuItem key={brand.id} value={brand.id}>
                                    <ListItemText primary={brand.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            error={articleError}
                            value={article}
                            fullWidth
                            label="Artículo"
                            name="article"
                            id="article"
                            margin="normal"
                            onChange={(e) => {
                                setArticle(e.target.value);
                                setArticleError(false);
                            }}
                            disabled={showLoader}
                        />
                    </FormControl>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="color">Color</InputLabel>
                                <Select
                                    labelId="color"
                                    label="Color"
                                    error={subcategoryError}
                                    value={colorSelected}
                                    onChange={(evt) => {
                                        setColorSelected(evt.target.value);
                                    }}
                                    disabled={showLoader}
                                >
                                    {colors.map((color) => (
                                        <MenuItem key={color.id} value={color}>
                                            <ListItemText
                                                primary={color.name}
                                            />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="numeration">
                                    Numeración
                                </InputLabel>
                                <Select
                                    labelId="numeration"
                                    label="Numeracion"
                                    error={subcategoryError}
                                    value={numerationSelected}
                                    onChange={(evt) =>
                                        setNumerationSelected(evt.target.value)
                                    }
                                    disabled={
                                        subcategorySelected === 2 ||
                                        subcategorySelected === 35 ||
                                        (sexSelected === 1 &&
                                            subcategorySelected === 9)
                                    }
                                >
                                    {numerations.map((numeration) => (
                                        <MenuItem
                                            key={numeration.id}
                                            value={numeration.id}
                                        >
                                            <ListItemText
                                                primary={numeration.numeration}
                                            />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth>
                                <TextField
                                    error={stockError}
                                    label="Stock"
                                    id="stock"
                                    value={stock}
                                    margin="normal"
                                    onChange={(e) => {
                                        setStock(e.target.value);
                                        setStockError(false);
                                        setStockHelperText("");
                                    }}
                                    inputProps={{
                                        inputMode: "numeric",
                                        pattern: "[0-9]*",
                                    }}
                                    helperText={stockHelperText}
                                    disabled={showLoader}
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={3}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Button
                                onClick={handleAddProductStock}
                                variant="contained"
                                className="pink-btn"
                                disabled={showLoader}
                            >
                                Agregar
                            </Button>
                        </Grid>
                    </Grid>

                    <ThemeProvider theme={theme}>
                        <DataGrid
                            sortingOrder={["desc", "asc"]}
                            autoHeight
                            rows={colorNumerationStock}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: pageSize },
                                },
                            }}
                            pageSizeOptions={[5, 10, 15, 20]}
                            onPageSizeChange={(newPageSize) =>
                                setPageSize(newPageSize)
                            }
                            disableSelectionOnClick
                            // onRowClick={(evt) => navigateToDetail(evt.id)}
                            onCellEditStop={(params, evt) =>
                                handleEditStock(params, evt)
                            }
                            components={{
                                NoRowsOverlay: () => (
                                    <Stack
                                        height="100%"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        No hay resultados
                                    </Stack>
                                ),
                            }}
                        />
                    </ThemeProvider>

                    <FormControl fullWidth margin="normal">
                        {/* <InputLabel htmlFor="price">Precio</InputLabel> */}
                        <TextField
                            error={priceError}
                            id="price"
                            name="price"
                            value={price}
                            onChange={(e) => {
                                setPrice(e.target.value);
                                setPriceError(false);
                                setPriceHelperText("");
                            }}
                            label="Precio"
                            helperText={priceHelperText}
                            disabled={showLoader}
                        />
                    </FormControl>

                    <FormControl fullWidth margin="normal">
                        <TextField
                            error={descriptionError}
                            id="outlined-multiline-static"
                            label="Descripción"
                            name="description"
                            multiline
                            minRows={4}
                            maxRows={9}
                            value={description}
                            onChange={(e) => {
                                setDescription(e.target.value);
                                setDescriptionError(false);
                            }}
                            disabled={showLoader}
                        />
                    </FormControl>
                    <FormControl>
                        <FormControlLabel
                            sx={{ margin: "5px 0px 10px 0px" }}
                            id="visible"
                            name="visible"
                            value={visible}
                            checked={visible}
                            control={<Switch color="primary" />}
                            onChange={(evt) => setVisible(evt.target.checked)}
                            label="Mostrar en catálogo"
                            labelPlacement="start"
                        />
                    </FormControl>
                    <div>
                        {/* <FormControl fullWidth margin="normal">
                            <InputLabel id="subcategory">Colores</InputLabel>
                            <Select
                                labelId="colors"
                                label="Colores"
                                name="colors"
                                value={colorImageSelected}
                                onChange={(evt) =>
                                    setColorImageSelected(evt.target.value)
                                }
                                disabled={showLoader}
                            >
                                {selectedColorList.length === 0 && (
                                    <MenuItem disabled>
                                        No hay colores para mostrar
                                    </MenuItem>
                                )}
                                {selectedColorList.map((colorList) => (
                                    <MenuItem
                                        key={colorList.id}
                                        value={colorList.id}
                                    >
                                        <ListItemText
                                            primary={colorList.name}
                                        />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl> */}
                        <Button
                            variant="contained"
                            component="label"
                            className="pink-btn"
                            disabled={showLoader}
                        >
                            Subir imagenes
                            <input
                                type="file"
                                name="images"
                                multiple
                                hidden
                                ref={(ref) => setImageUpload(ref)}
                                onChange={() =>
                                    setImagesSelected(imageUpload.files.length)
                                }
                            />
                        </Button>
                        <small style={{ marginLeft: "10px" }}>
                            {imagesSelected} imágenes seleccionadas
                        </small>
                    </div>
                    <div
                        style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            textAlign: "center",
                        }}
                    >
                        {editMode === false ? (
                            <Button
                                // onClick={checkFormData}
                                variant="contained"
                                className="pink-btn"
                                disabled={showLoader}
                                type="submit"
                            >
                                Crear
                            </Button>
                        ) : (
                            <Button
                                // onClick={checkFormData}
                                variant="contained"
                                className="pink-btn"
                                disabled={showLoader}
                                type="submit"
                            >
                                Guardar
                            </Button>
                        )}
                    </div>
                </form>
                <Grid container>
                    {productToEdit !== null ? (
                        editMode === true ? (
                            <>
                                <Grid item xs={12}>
                                    <h4>Portada</h4>
                                </Grid>
                                {productToEdit.images.length === 0 ? (
                                    <div style={{ width: "25%" }}>
                                        <ProductImage
                                            image={{
                                                path: productToEdit.imageppal,
                                                id: productToEdit.id,
                                            }}
                                            handleDeleteImage={
                                                handleDeleteImage
                                            }
                                        />
                                    </div>
                                ) : (
                                    ""
                                )}

                                <ImagesPreview
                                    images={productToEdit.images}
                                    dragImage={dragImage}
                                    draggedOverImage={draggedOverImage}
                                    handleSort={handleSort}
                                    handleDeleteImage={handleDeleteImage}
                                ></ImagesPreview>

                                {/* {productToEdit.images.map((image, index) => (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={4}
                                        md={3}
                                        padding={"3px"}
                                        key={image.id}
                                        draggable
                                        onDragStart={() =>
                                            (dragImage.current = index)
                                        }
                                        onDragEnter={() =>
                                            (draggedOverImage.current = index)
                                        }
                                        onDragEnd={handleSort}
                                        onDragOver={(e) => e.preventDefault()}
                                    >
                                        <ProductImage
                                            image={image}
                                            handleDeleteImage={
                                                handleDeleteImage
                                            }
                                        />
                                    </Grid>
                                ))} */}
                            </>
                        ) : (
                            ""
                        )
                    ) : (
                        ""
                    )}
                </Grid>
                {/* <Grid container sx={{ display: "flex", flexDirection: "row" }}>
                    {productToEdit !== null
                        ? editMode === true
                            ? productToEdit.images.map((image) => (
                                  <Grid
                                      item
                                      xs={12}
                                      sm={4}
                                      md={3}
                                      padding={"3px"}
                                      key={image.id}
                                  >
                                      <ProductImage
                                          image={image}
                                          handleDeleteImage={handleDeleteImage}
                                      />
                                  </Grid>
                              ))
                            : ""
                        : ""}
                </Grid> */}
            </Box>

            {showAlertOk === true ? (
                <Alert
                    icon={<CheckIcon fontSize="inherit" />}
                    severity="success"
                    sx={{ position: "fixed", top: "50%", zIndex: 99 }}
                >
                    El producto se {editMode === false ? "creó" : "actualizó"}{" "}
                    con éxito!
                </Alert>
            ) : null}

            {showAlertFail === true ? (
                <Alert
                    icon={<CheckIcon fontSize="inherit" />}
                    severity="error"
                    sx={{ position: "fixed", top: "50%", zIndex: 99 }}
                >
                    {alertErrorMsg}
                </Alert>
            ) : null}

            {showLoader === true ? (
                <Box sx={{ position: "fixed", top: "50%" }}>
                    <CircularProgress />
                </Box>
            ) : null}

            <DialogModal
                title="Eliminar imagen"
                description="Está seguro que quiere eliminar la imagen?"
                open={open}
                handleCloseNo={handleCloseNo}
                handleCloseYes={handleCloseYes}
            ></DialogModal>

            {showAlertDeletedImageOk === true ? (
                <Alert
                    icon={<CheckIcon fontSize="inherit" />}
                    severity="success"
                    sx={{ position: "fixed", top: "50%" }}
                >
                    La imagen se eliminó con éxito!
                </Alert>
            ) : null}
        </Grid>
    );
};

export default ProductForm;
