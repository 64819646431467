import { daDK } from "@mui/x-data-grid";
import axios from "axios";

const headersJson = {
    headers: {
        "Content-Type": "application/json",
    },
};

const headersMultipart = {
    headers: {
        "Content-Type": "multipart/form-data",
    },
};

const headersJsonAuth = () => {
    const token = `Bearer ${localStorage.getItem("access")}`;
    return {
        headers: {
            "Content-Type": "application/json",
            Authorization: token,
        },
    };
};

const api = process.env.REACT_APP_BACKEND_URL;

const API = {
    async getWomanCategories() {
        const result = await axios.get(`${api}/categories/mujer`);
        return result.data;
    },
    async getWomanSubcategories() {
        const result = await axios.get(`${api}/subcategories/woman/all`);
        return result.data;
    },
    async getManCategories() {
        const result = await axios.get(`${api}/categories/hombre`);
        // const manCategories = await getResult.json();
        return result.data;
    },
    async getCategoriesOnly() {
        const result = await axios.get(`${api}/categories/all`);
        return result.data;
    },
    async getCategoriesWithAllData() {
        const result = await axios.get(`${api}/categories/all`);
        return result.data;
    },

    async getAllBrands() {
        const result = await axios.get(`${api}/brands/all`);
        return result.data;
    },
    async getCarterasBrands() {
        const result = await axios.get(`${api}/brands/carteras`);
        return result.data;
    },
    async getAccesoriosBrands() {
        const result = await axios.get(`${api}/brands/accesorios`);
        return result.data;
    },
    async getColors() {
        const result = await axios.get(`${api}/colors/all`);
        return result.data;
    },
    async getNumerations() {
        const result = await axios.get(`${api}/numerations/all`);
        return result.data;
    },
    async getSubcategoriesFromCategory(id) {
        const result = await axios.get(`${api}/subcategories/${id}`);
        return result.data;
    },

    async getWomanSubcategoriesFromWomanCategory(id) {
        const result = await axios.get(`${api}/subcategories/woman/${id}`);
        return result.data;
    },

    async getAllProducts() {
        const result = await axios.get(`${api}/products/all`);
        return result.data;
    },
    async getAllProductsFromSubcategory(id, isAdmin = true) {
        const result = await axios.get(
            `${api}/products/subcategory/${id}?isAdmin=${isAdmin}`
        );
        return result.data;
    },
    async getAllProductsFromCategory(id) {
        const result = await axios.get(`${api}/products/category/${id}`);
        return result.data;
    },
    async getAllDeletedProductsFromCategory(id) {
        const result = await axios.get(
            `${api}/products/category/deleted/${id}`
        );
        return result.data;
    },

    async getAllDeletedProductsFromSubcategory(id) {
        const result = await axios.get(
            `${api}/products/subcategory/deleted/${id}`
        );
        return result.data;
    },

    async getDeletedProducts() {
        const result = await axios.get(`${api}/products/deleted`);
        return result.data;
    },

    async getProductDetail(productId) {
        const result = await axios.get(`${api}/products/detail/${productId}`);
        return result.data;
    },

    async getProductFromBarcode(barcode) {
        const result = await axios.get(`${api}/products/barcode/${barcode}`);
        return result.data;
    },

    async checkIfBarcodeExists(number) {
        const result = await axios.get(
            `${api}/products/checkBarcode/${number}`
        );
        return result.data;
    },

    async getPaymentOptions() {
        const result = await axios.get(`${api}/sales/paymentOptions`);
        return result.data;
    },

    async getStocksFromProduct(id) {
        const result = await axios.get(`${api}/products/getStock/${id}`);
        return result.data;
    },

    async getBuyerData(dni) {
        const result = await axios.get(`${api}/buyers/getBuyer/${dni}`);
        return result.data;
    },

    async getAllSales() {
        const result = await axios.get(`${api}/sales/all`);
        return result.data;
    },
    async getProductSales(id) {
        const result = await axios.get(`${api}/sales/product/${id}`);
        return result.data;
    },

    async getSalesBetweenDates(data) {
        const result = await axios.post(
            `${api}/sales/getSalesBetweenDates`,
            data
        );
        return result.data;
    },
    async getPaymentOptionsSales(data) {
        const result = await axios.post(
            `${api}/sales/paymentOptionsSales`,
            data
        );
        return result.data;
    },
    async deleteStock(id) {
        const result = await axios.post(`${api}/products/deleteStock`, id);
        return result.data;
    },

    async sortImages(data) {
        const result = await axios.post(`${api}/products/sortImages`, data);
        return result.data;
    },

    async updateCategoryImage(data) {
        const result = await axios.post(`${api}/categories/updateImage`, data);
        return result.data;
    },
};

export default API;
