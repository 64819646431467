import React, { useContext, useState, useEffect } from "react";
import { verifyToken } from "../utils/verifyToken";

export const AuthContext = React.createContext();

export const AuthProvider = (props) => {
    const [auth, setAuth] = useState(localStorage.getItem("jwt-token") || "");

    useEffect(() => {
        async function checkToken() {
            const isAvailable = await verifyToken();

            if (isAvailable) {
                setAuth(localStorage.getItem("jwt-token"));
            } else {
                setAuth(false);
            }
        }

        checkToken();
    }, [auth]);

    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {props.children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
