import React from "react";

const Footer = () => {
    return (
        <div id="footer">
            <ul className="copyright">
                <li>
                    &copy; Zapatería Avenida. Todos los derechos reservados.
                </li>
                <li>13 n°. 174, entre 35 y 36, Buenos Aires, La Plata.</li>
            </ul>
        </div>
    );
};

export default Footer;
