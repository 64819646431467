import React from "react";

import { Box, Tab, Tabs } from "@mui/material";
import TabPanel from "./components/TabPanel";
import Cart from "./components/Cart/Cart";
import History from "./components/History/History";
import ChangeProduct from "./components/Cart/ChangeProduct";
import Sign from "./components/Cart/Sign";

const Sales = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    return (
        <>
            <Box sx={{ width: "100%" }}>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="Nueva venta" {...a11yProps(0)} />
                    <Tab label="Cambio" {...a11yProps(1)} />
                    <Tab label="Seña" {...a11yProps(2)} />
                    <Tab label="Historial de ventas" {...a11yProps(3)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <Cart></Cart>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ChangeProduct></ChangeProduct>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Sign></Sign>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <History></History>
            </TabPanel>
        </>
    );
};

export default Sales;
