import { Button } from "@mui/material";

const getShoesColumns = (
    setColorNumerationStockToDelete,
    colors,
    numerations
) => {
    return [
        {
            field: "color",
            headerName: "Color",
            flex: 1,
            disableColumnMenu: true,
            sortable: true,
            valueGetter: (params) =>
                colors.length > 0
                    ? colors.find((color) => color.id === params.row.color).name
                    : "",
        },
        {
            field: "numeration",
            headerName: "Numeración",
            flex: 1,
            disableColumnMenu: true,
            valueGetter: (params) =>
                numerations.length > 0
                    ? numerations.find(
                          (numeration) =>
                              numeration.id === params.row.numeration
                      ).numeration
                    : "",
        },

        {
            field: "stock",
            headerName: "Stock",
            flex: 1,
            disableColumnMenu: true,
            editable: true,
            // valueGetter: (params) => params.row.brand.name,
        },
        {
            field: "actions",
            headerName: "Acciones",
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <div>
                        <Button
                            sx={{ marginLeft: "15px" }}
                            variant="contained"
                            color="error"
                            onClick={() => {
                                setColorNumerationStockToDelete(params.id);
                                // deleteColorStockNumeration(params.id);
                            }}
                        >
                            Eliminar
                        </Button>
                    </div>
                );
            },
            // valueGetter: (params) => params.row.brand.name,
        },
    ];
};
const getBagsColumns = (colors, setColorNumerationStockToDelete) => {
    return [
        {
            field: "color",
            headerName: "Color",
            flex: 1,
            disableColumnMenu: true,
            sortable: true,
            valueGetter: (params) =>
                colors.length > 0
                    ? colors.find((color) => color.id === params.row.color).name
                    : "",
        },

        {
            field: "stock",
            headerName: "Stock",
            flex: 1,
            disableColumnMenu: true,
            editable: true,
            // valueGetter: (params) => params.row.brand.name,
        },
        {
            field: "actions",
            headerName: "Acciones",
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <div>
                        <Button
                            sx={{ marginLeft: "15px" }}
                            variant="contained"
                            color="error"
                            onClick={() =>
                                setColorNumerationStockToDelete(params.id)
                            }
                        >
                            Eliminar
                        </Button>
                    </div>
                );
            },
            // valueGetter: (params) => params.row.brand.name,
        },
    ];
};

const utils = {
    getShoesColumns,
    getBagsColumns,
};

export default utils;
