import { React } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function PasswordDialog({ open, setOpen, setPassword }) {
    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Ingrese contraseña</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Para poder acceder al historial de ventas es necesario
                    ingresar su contraseña
                </DialogContentText>
                <TextField
                    sx={{ input: { color: "white" } }}
                    onChange={(evt) => setPassword(evt.target.value)}
                    autoFocus
                    margin="dense"
                    label="Contraseña"
                    type="text"
                    fullWidth
                    variant="standard"
                />
            </DialogContent>
        </Dialog>
    );
}
