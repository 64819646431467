import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
    AppBar,
    Box,
    CssBaseline,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    useTheme,
    useMediaQuery,
    Grid,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import utils from "./utils";
import ProductForm from "../ProductForm/ProductForm";
import { useAuth } from "../../../../context/AuthContext";

const drawerWidth = 240;

const AdminLayout = (props) => {
    const { setAuth } = useAuth();
    const [searchParams] = useSearchParams();

    const { window } = props;
    const [mobileOpen, setMobileOpen] = useState(false);
    const [selectedSection, setSelectedSection] = useState(
        JSON.parse(localStorage.getItem("sectionSelected")) || 1
    );
    const [selectedComponent, setSelectedComponent] = useState();

    // const [editModeState, setEditModeState] = useState(false);
    // const [productToEdit, setProductToEdit] = useState("");

    const [resetUrl, setResetUrl] = useState(false);

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const navigate = useNavigate();

    const handleChangeToEdit = (id) => {
        // setEditModeState(true);
        setSelectedSection(2);
        setSelectedComponent(
            <ProductForm editMode={true} productId={id}></ProductForm>
        );
    };

    const menuItems = utils.getMenuItems(handleChangeToEdit);

    useEffect(() => {
        let editMode = searchParams.get("edit");
        if (editMode === "true") {
            let prodId = searchParams.get("id");
            handleChangeToEdit(prodId);
        } else {
            setSelectedComponent(
                menuItems.find((item) => item.id === selectedSection).component
            );
        }
        // let id = searchParams.get("edit");
        // console.log(id);
        // if (id !== null) {
        //     console.log("entro");
        //     // setEditModeState(true);
        //     setResetUrl(true);
        //     setSelectedSection(2);
        // }
    }, [searchParams]);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleClick = (item) => {
        navigate("/admin");
        if (item.id !== 9) {
            setSelectedSection(item.id);
            setSelectedComponent(item.component);
            localStorage.setItem("sectionSelected", item.id);
            if (resetUrl) {
                navigate("/admin");
                setResetUrl(false);
            }
        } else {
            handleClickOpen();
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCloseNo = () => {
        setOpen(false);
    };
    const handleCloseYes = () => {
        setOpen(false);
        setAuth(false);
        // setUserName("");
        localStorage.clear();
        navigate("/");
    };

    const drawer = (
        <div>
            <Toolbar>
                <Grid
                    container
                    spacing={2}
                    sx={{ padding: "10% 0%", cursor: "pointer" }}
                    onClick={() => navigate("/")}
                >
                    <Grid item xs={4}>
                        <img
                            src={`${process.env.REACT_APP_AWS_IMAGES_ENDPOINT}/avatar.jpg`}
                            alt=""
                        />
                    </Grid>
                    <Grid item xs={8} className="sideBar-title">
                        <h3>Zapatería Avenida</h3>
                    </Grid>
                </Grid>
            </Toolbar>
            <Divider />
            <List>
                {menuItems.map((item) => (
                    <ListItem
                        button
                        selected={item.id === selectedSection}
                        key={item.name}
                        onClick={() => {
                            handleClick(item);
                        }}
                    >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.name} />
                    </ListItem>
                ))}
            </List>
        </div>
    );

    const container =
        window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                <AppBar position="fixed">
                    <Toolbar sx={{ backgroundColor: "#222629" }}>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerToggle}
                            sx={{
                                mr: 2,
                                display: { sm: "none" },
                            }}
                        >
                            <MenuIcon />
                        </IconButton>

                        <Box sx={{ flexGrow: 1 }} />
                        {/* <Box sx={{ display: { md: "flex" } }}>
                            {auth !== false ? (
                                <IconButton
                                    size="large"
                                    aria-label="Shopping Cart"
                                    color="inherit"
                                    title="Panel administrador"
                                    onClick={() => navigate("/admin")}
                                >
                                    <PersonIcon />
                                </IconButton>
                            ) : (
                                ""
                            )}
                        </Box> */}
                    </Toolbar>
                </AppBar>
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    onClick={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: "none", sm: "block" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                }}
            >
                <Toolbar />
                {selectedComponent}
            </Box>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleCloseNo}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Cerrar Sesión"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Está seguro que quiere cerrar la sesión?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCloseNo}>
                        No
                    </Button>
                    <Button onClick={handleCloseYes} autoFocus>
                        Si
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default AdminLayout;
