import { Box } from "@mui/system";
import React from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import OpenWithIcon from "@mui/icons-material/OpenWith";

import "./productImage.scss";

const ProductImage = ({ image, handleDeleteImage }) => {
    return (
        <Box className="container-box">
            {!image.path.includes("imagen-no-disponible") ? (
                <Box className="delete-icon-container">
                    <OpenWithIcon
                        className="drag"
                        // onClick={() => handleDeleteImage(image.id)}
                    ></OpenWithIcon>
                    <RemoveCircleOutlineIcon
                        className="icon"
                        onClick={() => handleDeleteImage(image.id)}
                    ></RemoveCircleOutlineIcon>
                </Box>
            ) : (
                ""
            )}

            <img
                className="img"
                alt={image.id}
                key={image.id}
                src={`${process.env.REACT_APP_AWS_IMAGES_ENDPOINT}/${image.path}`}
            ></img>
        </Box>
    );
};

export default ProductImage;
