import React, { useState } from "react";

import {
    Backdrop,
    Button,
    CircularProgress,
    Grid,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";

import downloadjs from "downloadjs";
import html2canvas from "html2canvas";
import API from "../../../../utils/getDataFromServer";

var Barcode = require("react-barcode");

const BarcodeForm = () => {
    const [showLoader, setShowLoader] = useState(false);
    const [barcodeNumber, setBarcodeNumber] = useState(null);

    const generateBarcodeValue = async () => {
        const randomNumber = Math.floor(
            1000000000 + Math.random() * 9000000000
        );
        setShowLoader(true);
        // SI SE LLEGARA A USAR HABRIA QUE ASIGNARLE UN BARCODE A CADA PRODUCTO
        // const res = await API.checkIfBarcodeExists(randomNumber);
        const res = {
            barcodeExists: false,
        };
        if (res.barcodeExists === false) {
            setBarcodeNumber(randomNumber.toString());
        } else {
            generateBarcodeValue();
        }

        setShowLoader(false);
    };

    const downloadBarcode = async () => {
        // const canvas = document.getElementById("barcode");
        const canvas = await html2canvas(document.getElementById("barcode"));
        const dataURL = canvas.toDataURL("image/png");
        downloadjs(dataURL, "download.png", "image/png");
    };

    return (
        <Grid container spacing={0} direction="column" alignItems="center">
            <Box className="title">
                <Typography
                    variant="h5"
                    sx={{
                        textAlign: "center",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                    }}
                >
                    Crear código de barras
                </Typography>
            </Box>

            <Box
                sx={{
                    marginTop: "25px",
                    marginBottom: "20px",
                }}
            >
                <Button
                    onClick={generateBarcodeValue}
                    variant="contained"
                    className="pink-btn"
                    type="submit"
                >
                    Crear
                </Button>
            </Box>
            {barcodeNumber !== null ? (
                <Box>
                    <div id="barcode">
                        <Barcode value={barcodeNumber} width={3.5}></Barcode>
                    </div>
                    <Box
                        sx={{
                            margin: "auto",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Button
                            sx={{ marginTop: "25px" }}
                            onClick={downloadBarcode}
                            variant="contained"
                            className="pink-btn"
                            type="submit"
                        >
                            Descargar
                        </Button>
                    </Box>
                </Box>
            ) : (
                ""
            )}

            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={showLoader}
                // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Grid>
    );
};

export default BarcodeForm;
