import {
    faHouseChimney,
    faTh,
    faUser,
} from "@fortawesome/free-solid-svg-icons";

const getHomeMenu = (isAdmin) => {
    const menu = [
        {
            label: "Inicio",
            icon: faHouseChimney,
            url: "#inicio",
            id: "top-link",
        },
        {
            label: "Catálogo",
            icon: faTh,
            url: "#catalogo",
            id: "portfolio-link",
        },
        {
            label: "¿Quiénes Somos?",
            icon: faUser,
            url: "#quienes-somos",
            id: "about-link",
        },
    ];

    if (isAdmin) {
        menu.push({
            label: "Admin",
            icon: faUser,
            url: "/admin",
            // id: "about-link",
        });
    }

    return menu;
};

const getCategoriesMenu = (isAdmin) => {
    const menu = [
        {
            label: "Inicio",
            icon: faHouseChimney,
            url: "/",
            // id: "top-link",
        },
        {
            label: "Mujer",
            icon: faTh,
            url: "/mujer",
            // id: "portfolio-link",
        },
        {
            label: "Hombre",
            icon: faTh,
            url: "/hombre",
            // id: "about-link",
        },
    ];
    if (isAdmin) {
        menu.push({
            label: "Admin",
            icon: faUser,
            url: "/admin",
            // id: "about-link",
        });
    }
    return menu;
};

const getMenu = (data, isAdmin) => {
    const menu = data.map((item) => ({
        label: item.name,
        icon: faTh,
        url: item.id,
    }));
    if (isAdmin) {
        menu.push({
            label: "Admin",
            icon: faUser,
            url: "/admin",
            // id: "about-link",
        });
    }
    return menu;
};

const utils = {
    getHomeMenu,
    getCategoriesMenu,
    getMenu,
};

export default utils;
